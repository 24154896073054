.jobs {
  font-family: var(--fontFamily);
}

.dashboardScreen__content__hiring {
  border-radius: 8px;
  background-color: var(--main);
}

.dashboardScreen__content__hiring__heading {
  color: var(--white);
  letter-spacing: -0.88px;
}

.dashboardScreen__content__hiring__para {
  color: var(--white);
  letter-spacing: -0.33px;
}

.dashboardScreen__content__hiring__btn {
  max-width: 300px;
  width: 100%;
}

.dashboardScreen__content__hiring__btn--button {
  border-radius: 8px;
  height: 40px;
  width: 100%;
  background-color: var(--main);
  color: var(--white);
  border: 1px solid var(--white);

}

.dashboardScreen__content__hiring__btn--button:hover {
  background-color: #436C03;
  border-color: #436C03;
}

.active__roles {
  color: var(--darkBlue) !important;
}

.progressBars {
  max-width: 271px;
  margin: 0 auto;
  display: block;
  margin-bottom: 25px;
}

.dashboardScreen__chart__box {
  background-color: var(--white);
  border: 0.5px solid #70707040;
  height: 338px;
  border-radius: 8px;
  margin-top: 10px;
  padding: 15px;
}

h4.dashboardScreen__chart__count {
  font-size: 60px;
  font-weight: 600;
  color: var(--darkBlue);
  letter-spacing: -1.3px;
}

.dash__para__text {
  font-size: 16px;
  color: var(--darkBlue);
  letter-spacing: -0.36px;
}

.dashboardScreen__chart__history {
  font-size: 16px;
  color: var(--midGrey);
}

.dashboardScreen_circular__box {
  background-color: var(--white);
  border: 0.5px solid #70707040;
  min-height: 430px;
  border-radius: 8px;
  margin-top: 12px;
  padding: 15px;
}

.nopadd__left {
  padding-left: 0px !important;
}

.outlined__box {
  border: 0.5px solid #70707040;
  padding: 12px;
  border-radius: 8px;
  margin-top: 12px;
}

.stats__boxes {
  position: relative;
  min-height: 80px;
  height: 100%;
}

.outlined__box span {
  color: var(--darkBlue);
}

.outlined__box p {
  color: var(--darkBlue);
}

.stats__boxes span {
  font-size: 40px;

  font-weight: 300;
  letter-spacing: -0.88px;
  margin-bottom: 10px;
  line-height: 1;
}

.stats__boxes p {
  font-size: 16px;
  letter-spacing: -0.36px;
  font-weight: 600;
}

.stats__boxes .lightBlueBar__content__icon__image {
  transform: rotate(-45deg);
  max-width: 30px;
  position: absolute;
  top: 12px;
  right: 8px;
}

.Indexcore__text {
  font-size: 18px;
  color: #000
}

.dashboard__profile__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashboard__profile__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #0D2159;
  font-weight: 600;

}

.dashboard__profile_share a {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 2px #00000008;
  border: 1px solid #ccc;
  font-size: 10px;
  width: 130px;
  height: 34px;
  justify-content: center;
  border-radius: 8px;
  color: #4A4A49;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
}

.dashboard__profile_share a svg {
  margin-right: 5px;
}

.dashboard__profile__info img {
  width: 70px;
  margin-right: 10px;
  border-radius: 50%;
  height: 70px;
  object-fit: cover;
}

.dashboard__profile {
  border: 1px solid #4A4A4941;
  border-radius: 8px;
  padding: 15px;
}

.dashboard__profile__progress .progressBar__candidates {
  height: 10px !important;
}

.dashboard__profile__progress .progress-bar {
  height: 10px;
}



.dashboard__interview__box {
  margin-top: 30px;
  padding: 15px;
  background: rgb(222 222 222 / 8%);
  border: 1px solid rgb(112 112 112 / 0.2);
  border-radius: 8px;
  min-height: 220px;
}

.dashboard__interview__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashboard__interview__list.col3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.dashboard__interviews {
  display: flex;
  border: 0.5px solid #4a49492e;
  padding: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 10px);
  border-radius: 8px;
  background: #fff;
  min-height: 150px;
}

.dashboard__interviews:hover {
  background-color: #eeeeee24;
}

.dashboard__interviews.col3 {
  display: flex;
  border: 0.5px solid #4a49492e;
  padding: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.33% - 15px);
  border-radius: 8px;
  background: #fff;
  margin-right: 15px;
  margin-bottom: 15px;
}

.dashboard__interviews img {
  height: 30px;
  margin-right: 10px;
}

.dashboard__interviews__detail p {
  margin: 0px;
  font-size: 12px;
}

.dashboard__interviews__detail h6 {
  margin: 0px;
}

.dashboard__interviews__detail_bottom {
  display: flex;
}


.dashboard__interviews__detail_bottom span {
  font-size: 10px;
  color: #4A4A49;
  opacity: 0.7;
}

.dashboard__interviews__detail_bottom p {
  color: #4A4A49;
  font-size: 11px;
  margin: 0px 10px 0 0;
}

.dashboard__interviews__detail_bottom a {
  color: #4A4A49;
  font-size: 12px;
  text-decoration: none;
}

.dashboard__interviews__detail_bottom>div {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(48% - 10px);
  flex: 1;
}

.dashboard__interviews__menu .dropdown-item {
  font-size: 10px;
  padding: 2px 10px;
}

.dashboard__interviews__detail {
  padding-top: 3px;
}

.dashboard__interviews__menu .dropdown-item svg {
  margin-right: 10px;
}

.dashboard__interviews__menu .dropdown-menu {
  padding: 0px;
}

.dashboard__interviews__menu .dropdown-menu li.last-menu {
  background: #eee;
}

.dashboard__profile__progress__text strong {
  font-size: 12px;
  font-weight: 600;
  margin-right: 5px;
}

.dashboard__profile__progress__steps span,
.dashboard__profile__progress__steps svg {
  font-size: 12px;
}

.dashboard__profile__progress__text span {
  font-size: 12px;
}

.indexScore__progressBar__dashboard {
  margin-top: 15px;
  background: #E6E6E626 0% 0% no-repeat padding-box;
  border: 0.5px solid #70707080;
  opacity: 1;
}

.dashboard__heading {
  font-size: 16px;
  color: #0d2159;
}

.dashboard__heading__dark {
  font-size: 18px;

}

.dashboard__job__search {
  background: #f8f8f8;
}

.dashboard__job__search .job__top__search.job__top__search1 {
  background: #f8f8f8;
}

.dashboard__job__search .job__top__search.job__top__search1 input {
  background: #f8f8f8;
}

.dashboard__job__search .job__top__search.job__top__search2 {
  background: #f8f8f8;
}

.dashboard__job__search .job__top__search.job__top__search2 input {
  background: #f8f8f8;
}

.dashboard__job__search button.job__top__search__clear {
  background: #f8f8f8;
}


.indexScore__progressBar__inner {
  position: relative;
  display: flex;
  padding: 20px;
}

.indexScore__progressBar__inner>div {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 10px);
}

.indexScore__progressBar__inner>div>div>div {
  margin: 0px !important;
  top: 0px;
  left: 0;
}

.dashboard__indexscore__box {
  margin: auto !important;
  text-align: center;
}

.indexScore__progressBar__inner>div>div>svg {
  margin: 0px;
  max-width: 100%;
}


.dashboard__offers {
  margin-top: 30px;
  padding: 15px;
  background: rgb(222 222 222 / 8%);
  border: 1px solid rgb(112 112 112 / 0.2);
  border-radius: 8px;
  min-height: 230px;
}

.dashboard__offers__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard__offers__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashboard__offers__box {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 10px);
  border-radius: 8px;
}

.dashboard__offers__box__parent {
  width: 100%;
}

.dashboard__offers__box__top {
  display: flex;
  margin-bottom: 10px;
}

.dashboard__offers__top p {
  font-size: 12px;
}

.dashboard__offers__box__top img {
  height: 40px;
  margin-right: 10px;
}

.dashboard__offers__box__top h6 {
  margin: 0px;
}

.dashboard__offers__box__top p {
  margin: 0px;
  font-size: 12px;
}

.dashboard__offers__box__bottom {
  background: #F4F4F4;
  padding: 10px;
}

.dashboard__offers__box__bottom p {
  margin: 0px 0 5px 0;
  font-size: 12px;
}

.dashboard__offers__box__bottom .attached__box span {
  font-size: 12px;
}

.dashboard__offers__box__bottom .attached__box svg {
  margin-right: 10px;
}

.notify-animation {
  max-width: 60px;
  margin-right: 5px;
}

.radius-box {
  border-radius: 8px !important;
}

.search__jobs__list {
  list-style: none;
  padding: 0px;
  background: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0px 0px 8px 8px;
  max-height: 220px;
  overflow-y: scroll;

}

.search__jobs__list p {
  margin: 0px;
}

.search__jobs__list li {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.text-black {
  color: #000;
}

.search__jobs__list li:hover {
  background: #fcfcfc;
}

.search__jobs__list li:last-child {
  border-bottom: none;
}

.search__jobs__list h6 {
  margin: 0px;
}

.search__jobs__list p {
  margin-left: 30px;
  font-size: 12px;
}

.search__jobs__list p strong,
.search__jobs__list p span {
  margin-right: 10px;
}



.contractsBox {
  height: 100%;
  /* border-top: 1px solid #ccc; */
  /* border-radius: 8px; */
  padding: 10px 10px 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.contractsBox .dash__singl__contract {
  display: flex;
  align-items: flex-start;
}

.contractsBox .dash__singl__contract .contracts__symbol {
  width: 32px;
  height: 32px;
  background: #8741e6;
  border-radius: 50%;
  margin-right: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.contractsBox .dash__singl__contract div {
  width: calc(100% - 44px);
}

.contractsBox .dash__singl__contract h5 {
  font-weight: 600 !important;
  line-height: 14px;
  margin-bottom: 5px;
}

.contractsBox .dash__singl__contract p {
  margin-bottom: 10px;
}

.contractsBox .dash__singl__contract button,
.contractsBox a {
  text-decoration: none;
  color: grey;
  padding: 3px 8px;
  border-radius: 16px;
  float: right;
  width: 120px;
  font-size: 20px;
  text-align: right;
  margin-top: 20px;
}

.contractsBox .dash__singl__contract a img {
  transform: rotate(-45deg);
  max-width: 26px;
}

/* .contractsBox .dash__singl__contract img {
  width: 30px;
  margin-right: 10px;
} */

.sideBar__boxes {
  background-color: rgb(247, 250, 247);
  border-radius: 8px;
  margin-bottom: 20px;
  padding-top: 15px;
  min-height: 170px;
}


.offers__company__dashboard {
  width: 36px;
  height: 36px;
  background: #0d2159;
  border-radius: 50%;
  margin-right: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.offers__company__dashboard svg {
  font-size: 26px;
}

.dashboardScreen__content .progress-bar {
  background-color: #A6DD4F !important;
}

.left__data {
  width: calc(100% - 30px);
}

.right__data.lightBlueBar__content__icon__image {
  transform: rotate(-45deg);
  max-width: 26px;
}

.filteredJobs__show {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0px;
}

.dashboard__profile__progress .progress.progressBar__candidates .progress-bar {
  border-radius: 0px;
}

@media only screen and (max-width: 1500px) {
  .Indexcore__text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1400px) {
  .Indexcore__text {
    font-size: 14px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .Indexcore__text {
    width: 130px;
    text-align: center;
    display: block;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 1100px) {
  .Indexcore__text {
    width: 130px;
    text-align: center;
    display: block;
    padding-top: 20px;
  }
}

.filled__box {
  border: 0.5px solid #70707040;
  padding: 12px;
  border-radius: 12px;
  margin-top: 12px;
  background-color: var(--darkBlue);
}

.filled__box.pro__dashboard__cards {
  min-height: 125px;
}

.filled__box.pro__dashboard__cards p {
  margin: 0px;
}

.filled__box span {
  color: var(--white);
}

.filled__box p {
  color: var(--white);
}

.dashboardScreen__plan__upgrade__button {
  color: var(--lightBlue2);
  box-shadow: none;
  background: none;
  border: 1px solid var(--lightBlue2);
  line-height: 40px;
  width: 230px;
  border-radius: 8px;
  text-align: center;
  margin-top: 12px;
}

.dashboardScreen__plan__upgrade__button:hover {
  background: var(--lightBlue2);
  color: #fff;
}


.dashboardScreen__plan__info {
  min-height: 222px;
  border: 0.5px solid #70707040;
  padding: 12px;
  border-radius: 12px;
  margin-top: 12px;
}

.dashboardScreen__plan_stat {
  font-size: 50px;
  color: var(--darkBlue);
}

.dashboardScreen__plan_stat span {
  font-weight: 400;
  letter-spacing: -1.3px;
}

.dashboardScreen__plan_stat .highlight__val {
  font-weight: 600;
}

.dashboardScreen__plan_title {
  font-size: 18px;
  color: var(--darkBlue);
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}

.dashboardScreen__plan_expiry {
  font-size: 18px;
  color: var(--midGrey);
  letter-spacing: -0.36px;
}

.dashboardScreen__chart img {
  max-width: 100%;
}

.pro__dash__jobs__para {
  margin-left: 0px !important;
}

.pro__dash__jobs__icon {
  max-width: 30px;
  margin-top: -4px;
  margin-left: 5px;
}

.pro__jobs__dash__container .filter__content__tag__content--small {
  font-size: 16px;
}

.filter__data .filter__content__tag__content {
  margin-bottom: 10px;
}

.pro__dash__score__box {
  background-color: #E6E6E626;
  padding: 12px;
  border-radius: 8px;
  margin-top: 12px;
  border: 1px solid #ccc;
}

.CircularProgressbar .CircularProgressbar-text {
  position: relative;
}

.CircularProgressbar .CircularProgressbar-text:before {
  content: "Your IndexScore Score";
  position: absolute;
  color: #fff;
  font-size: 14px;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 200px;
  z-index: 2;
}

.pro__dash__score__box .CircularProgressbar .CircularProgressbar-text {
  display: none;
}

.pro__index_score {
  color: #fff;
  font-weight: 300;
}

.pro_brekdown_value {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 12px;
  min-height: 70px;
  border-radius: 8px;
  /* filter: blur(0px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ccc;
}

.pro_brekdown_value h4 {
  margin: 0px;
  color: #000;
}

.pro_brekdown_value p {
  margin: 0px;
  /* color: #fff; */
  color: #80ae52;

}

.pro__dash__score__boxes .col-md-6 {
  padding: 0px 6px;
}

.pro__dash__score__boxes {
  padding: 0px 6px;
}

.pro__dash__score__box_title {
  color: #80ae52;
  text-align: center;
  margin-top: -10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.pro__dashboard__jobs__list {
  background: #E6E6E680;
  padding: 15px;
  border-radius: 8px;
}

.score-value.CircularProgressbar-text {
  display: block;
  color: #CFFD5E;
  text-align: center;
  line-height: 1.8;
}

.score-value.CircularProgressbar-text span {
  color: grey;
  font-size: 14px;
}

.indexscore__toggle {
  display: flex;
  justify-content: end;
  padding-top: 10px;
  align-items: center;
  min-height: 36px;
}

.indexscore__toggle>span {
  margin-right: 10px;
}

.indexscore__toggle .ant-switch-checked {
  background-color: var(--main) !important;
}

.pro__index_score {
  top: 135px;
  left: 0px;
}


.pro__dash__score__box svg+div {
  max-width: 85%;
  margin: auto;
}
.pro__index_score__para{
  font-size: 45px;
}
@media only screen and (max-width: 1500px) {
  .pro__dash__score__box svg+div {
    max-width: 85%;

  }

  .dashboardScreen__chart__box {
    height: 100%;
  }

  .dashboardScreen__chart {
    margin-top: 50px;
  }

  .pro__index_score {
    top: 135px;
    left: 0px;
  }
}

@media only screen and (max-width: 1400px) {
  .pro__index_score {
    top: 135px;
    left: 0px;
  }

  .dashboardScreen__content__hiring__heading {
    font-size: 35px;
  }

  .dashboardScreen__content__hiring__btn {
    max-width: 250px;
  }

  .pro__jobs__dash__container .filter__content__tag__content--small {
    font-size: 14px;
  }

  .pro__jobs__dash__container .stats__boxes p {
    font-size: 13px;
  }

  .filled__box p {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .pro__index_score {
    top: 50%;
    left: 0px;
    transform: translateY(-28%);
  }

  .dashboardScreen__content__hiring__heading {
    font-size: 30px;
  }

  .dashboardScreen__content__hiring__btn {
    max-width: 200px;
  }

  .dashboardScreen__content__hiring__btn--button {
    font-size: 14px;
  }

  .CircularProgressbar-text {
    margin-top: -15px;
  }
}

@media only screen and (max-width: 992px) {
  .dashboardScreen__content__hiring__heading {
    font-size: 25px;
  }

  .dashboardScreen__content__hiring__btn {
    max-width: 150px;
  }

  .dashboardScreen__content__hiring__btn--button {
    font-size: 12px;
  }

  .pro__index_score {
    top: 160px;
    left: 0px;
  }

  .CircularProgressbar-text {
    margin-top: -7px;
  }
}

@media only screen and (max-width: 768px) {
  .filteredJobs__show{
    top:102px;
  }
  .pro_brekdown_value {
    min-height: 80px;
  }

  .pro__index_score {
    top: 160px;
    left: 0px;
  }

  .Indexcore__text {
    font-size: 16px;
    line-height: 22px;
  }

  .Indexcore__text {
    width: 200px;
  }
}

.filter__content__link__heading__grey {
  color: var(--lightGrey) !important;
  letter-spacing: -0.48px !important;
}

.filter__content__link__heading__grey__icon {
  color: var(--lightGrey);
}

.jobs__filter__header {
  font-size: 16px;
}

.jobs__filter__li {
  font-size: 14px;
}

.text-grey {
  color: #999999 !important;
}

@media only screen and (max-width: 576px) {
  .pro__dash__score__box svg+div {
    max-width: 100%;
  }
  .dashboard__profile__info img{
    width: 50px;
    height: 50px;
  }
}