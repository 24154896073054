.UNav {
    position: fixed;
    top: 20px;
    z-index: 5;
    
}

.UNav__container {
    background: linear-gradient(54deg, rgb(2, 16, 54) 0%, rgba(13,33,89,1) 77%, rgb(35, 65, 148) 100%);
    /* box-shadow: rgba(48, 48, 48, 0.3) 0px 15px 43px; */
    padding: 10px;
    margin: 0px 80px;
}

.UNav__logo {
    width: 200px;
}
.UNav__item{
    color: white;
    margin-left: 30px;
}
.UNav__item:hover{
    color: var(--main);
}
.UNav__item-btn{
    background-color: #486FD9;
    color: white;
    margin-left: 30px;
    padding: 12px 15px;
}
.UNav__item-btn:hover{
    color: var(--darkBlue);
}
@media only screen and (max-width: 1400px){
    .UNav__container{
        margin: 0px 30px;
    }
}
@media only screen and (max-width: 1200px){
    .UNav__container{
        margin: 0px 0px;
    }
}