.create__indexCore__chart {
    display: flex;
    margin-bottom: 40px;
}

.create__indexCore__chart>div {
    max-width: 370px;
    margin-right: 20px;
    width: 100%;
}

.create__indexCore__chart img {
    width: 100%;
}

.create__indexCore__chart .CircularProgressbar .CircularProgressbar-path {
    stroke: var(--main);
}

.Indexcore__progress {
    width: 290px;
    height: 290px;
}

.create__indexCore__chart>div {
    max-width: 308px;
    margin-right: 10px;
    width: 100%;
}

.circular__text {
    position: absolute;
    top: 40%;
    left: 30px;
    width: 256px;
    line-height: 20px;
    font-size: 18px;
    font-family: var(--fontFamily);
}

.form-box .ant-select.css-1e3x2xa.ant-select-single.ant-select-show-arrow {
    width: 100%;
}

.form-box .ant-select-arrow {
    display: block !important;
    color: #fff;
    font-size: 16px;
}

@media only screen and (max-width: 1500px) {
    .Indexcore__progress {
        width: 300px;
        height: 300px;
    }

    .create__indexCore__chart>div {
        max-width: 310px;
        margin-right: 10px;
        width: 100%;
    }

    .circular__text {
        position: absolute;
        top: 38%;
        left: 35px;
        width: 245px;
        line-height: 20px;
        font-size: 19px;
    }
}

@media only screen and (max-width: 1400px) {
    .Indexcore__progress {
        width: 250px;
        height: 250px;
    }

    .create__indexCore__chart>div {
        max-width: 250px;
        margin-right: 20px;
        width: 100%;
    }

    .circular__text {
        position: absolute;
        top: 35%;
        left: 32px;
        width: 184px;
        line-height: 20px;
        font-size: 14px;
    }
}
.ant-modal-title{
    text-transform: uppercase !important;
    font-size: 14px !important;
}


