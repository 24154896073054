.error{
    min-height: 100vh;
    padding-top: 100px;
    background: linear-gradient(21deg, rgba(178,218,102,1) -30%, rgba(61,80,32,1) -40%, rgba(0,0,0,1) 57%, rgba(13,33,89,1) 120%);
}
.error .footer{
    background: #00000000;
}
.error__code{
    font-size: 174px;
}
.error__message{
    font-size: 52px;
    line-height: 62px;
    max-width: 400px;
}
.error__subtext{
    max-width: 830px;
}
.error__box{
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.247);
    min-height: 270px;
    height: 100%;
    background: #FFFFFF0D;
}
.error__box:hover{
    box-shadow: rgb(56 56 57 / 30%) 0px 15px 43px;
}
.error__arrow{
    width: 40px;
    fill: var(--main) !important;
    color: var(--main) !important;
}
@media only screen and (max-width: 1400px) {
    .error__code{
        font-size: 164px;
    }
    .error__message{
        font-size: 45px;
        line-height: 57px;
        max-width: 400px;
    }
}
@media only screen and (max-width: 1200px) {
    .error__code{
        font-size: 154px;
    }
    .error__message{
        font-size: 40px;
        line-height: 55px;
        max-width: 350px;
    }
}
@media only screen and (max-width: 992px) {
    .error__code{
        font-size: 144px;
    }
    .error__message{
        font-size: 35px;
        line-height: 45px;
        max-width: 300px;
    }
}
@media only screen and (max-width: 768px) {
    .error__code{
        font-size: 140px;
        line-height: 140px;
        margin-bottom: 0px;
    }
    .error__message{
        font-size: 30px;
        line-height: 40px;
        max-width: 100%;
    }
}
@media only screen and (max-width: 576px) {
    .error__code{
        font-size: 130px;
        line-height: 130px;
        margin-bottom: 0px;
    }
    .error__message{
        font-size: 25px;
        line-height: 35px;
    }
    .error__box{
        min-height: auto;
    }
}
@media only screen and (max-width: 390px) {
    .error__code{
        font-size: 120px;
        line-height: 130px;
        margin-bottom: 0px;
    }
    .error__message{
        font-size: 20px;
        line-height: 30px;
    }
}