.chat__page {
    padding-top: 130px !important;
}

.chat__wrapper {
    display: flex;
}

.inter_logo {
    max-width: 200px;
    width: 100%;
    margin: 20px;
}

.chat__sidebar {
    width: 320px;
    background: var(--aside-bg);
    height: calc(100dvh - 73);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    flex-shrink: 0;
    overflow: hidden;
    border-right: 1px solid #dbeafe;
    background: #0d2159;
    padding-top: 0px;
}

.departments .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
    background: var(--darkGrey) !important;
    color: #fff !important;
}

.departments .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after {
    color: #fff !important;
}

.chat__sidebar__tabs {
    display: flex;
    padding: 0px;
    text-align: center;
    font-size: 20px;
    align-items: center;
}

.chat__sidebar__tabs>li {
    width: 50%;
    font-size: 22px;
    color: #fff;
    border-radius: 0px;
    line-height: 22px;
}

.chat__sidebar__tabs>li.react-tabs__tab--selected {
    color: #0d2159;
}

.chat__sidebar__tabs>li.react-tabs__tab--selected:after {
    display: none;
}

.interview__buttons__wrapper {
    display: flex;
    justify-content: space-between;
}

.interview__buttons__wrapper .interview__buttons.accept {
    background: #A6DD50;
    color: #fff;
    margin-right: 10px;
    font-size: 12px;
    padding: 4px 11px;
    border-radius: 8px;
}

.interview__buttons__wrapper .interview__buttons.reject {
    background: grey;
    color: #fff;
    font-size: 12px;
    padding: 4px 11px;
    border-radius: 8px;
}

.candidate__photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.candidate__photo img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    object-fit: cover;
}

@media (min-width: 768px) {
    .chat__sidebar {
        /* position: fixed; */
        left: 0;
        top: 73px;
        z-index: 1;
    }

}


.chat__convo__list__group {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.chat__convo {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.chat__convo__image {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 38px;
    width: 38px;
    border-radius: var(--tyn-shape);
    background: var(--media-bg);
}

.chat__convo__image img {
    height: 100% !important;;
    width: 100% !important;
    border-radius: 50%;
    object-fit: cover !important;
}

.chat__convo__detail .chat__convo__detail_row h6,
.chat__convo__detail .chat__convo__detail_row p {
    margin: 0px;
}

.chat__convo__list {
    padding: 12px 24px;
    /* border-bottom: 1px solid #dddfff; */
}


.chat__convo__detail .chat__convo__detail_row {
    display: flex;
    /* align-items: center; */
    /* gap: 1rem; */
    color: #fff;
    flex-direction: column;
    align-items: baseline;
}

.chat__convo__detail .chat__convo__detail_row h6 {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
}

.chat__convo__detail .chat__convo__detail_row p {
    font-size: 0.75rem;
    color: #fff;
    margin-bottom: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat__convo__detail .chat__convo__detail_row .meta {
    font-size: 0.625rem;
}

.chat-aside-list {
    /* overflow: hidden scroll; */
    direction: inherit;
    height: calc(100vh - 130px);
    padding-bottom: 30px;
}

.chat__convo__list:hover {
    background: #04237a;
    cursor: pointer;
}



.chat__sidebar .tyn-aside-search .form-control-wrap {
    position: relative;
}

.chat__sidebar .tyn-aside-search .form-control-wrap .form-control-icon.start {
    position: absolute;
    top: 5px;
    left: 13px;
    color: #fff;
}

.chat__sidebar .tyn-aside-search .form-control-wrap input {
    border-radius: 30px;
    font-size: 12px;
    padding-left: 35px;
    height: 40px;
    padding-top: 10px;
}

.chat__sidebar .tyn-aside-search {
    padding: 0px 20px 10px;
}

.chat__sidebar h3 {
    padding-left: 20px;
}





/* chat detail */


.chat-container {
    /* max-width: 400px; */
    margin: 20px auto;


}

.message {
    margin-bottom: 10px;
    overflow: hidden;
}

.my-message {
    float: right;
    clear: both;
}

.message__time {
    font-size: 10px;
}

.my-message .message-content {
    background-color: var(--main);
    color: white;
    border-radius: 10px 10px 0 10px;

}

.my-message .message__time {
    text-align: right;
}

.incoming-message {
    float: left;
    clear: both;
}

.incoming-message .message-content {
    background-color: #eaeaea;
    color: black;
    border-radius: 10px 10px 10px 0;

}

.incoming-message .message__time {
    text-align: left;
}

.message-content {
    padding: 10px;
}








.chat__center__box {
    width: calc(100% - 550px);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    max-width: 100%;
    flex-grow: 1;
}

.chat__header {
    padding: 10px 20px;
    background: #eaeaea;
    border-bottom: 1px solid #ddd;
    box-shadow: -11px 10px 50px rgba(0, 0, 0, 0.2);

}

.chat__header .chat__header__info {
    display: flex;
    align-items: center;
}

.chat__header .chat__header__info img {
    width: 38px;
    height: 38px;
    /* margin-right: 20px; */
}

.chat__content__scrollable {
    background: #f8f9fb;
    height: calc(100vh - 270px);
    padding: 20px;
    overflow-y: scroll;
}

.chat__sending__form .chat__sending__form__inner {
    display: flex;
    padding: 20px 25px;
    background: #ebebeb;
}

.chat__sending__form .chat__sending__form__inner input {
    width: 100%;
    height: 40px;
    box-shadow: none;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 30px;
    font-size: 12px;
}

.chat__sending__form .chat__sending__form__inner button {
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: #0d2159;
    border-radius: 50%;
    color: #fff;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.chat__info__box {
    width: 300px;
}

.customHeight {
    line-height: 12px;
}

.chat__info__box .chat__info__box__top {
    text-align: center;
    padding: 20px;
}

/* .chat__info__box .chat__info__box__top img{
    max-width: 90px;
} */

.chat__info__box .chat__info__box__team ul {
    list-style: none;
    padding: 0px;

}

.chat__info__box .chat__info__box__team ul li {
    display: inline-block;
    margin-right: 5px;
}

.chat__info__box .chat__info__box__team ul li img {
    max-width: 24px;
}

.chat__info__box .chat__info__box__team h3 {
    font-size: 20px;
    margin-bottom: 15px;
}

.chat__info__box .chat__info__box__team {
    padding: 20px;
}

.chat__info__box .chat__info__box__team ul li p {
    font-size: 12px;
}

.chat__info__details {
    margin-top: 20px;
}

.chat__info__details .chat__info__details__info {
    display: flex;
    margin-left: 20px;
}

.chat__info__details .chat__info__details__info img {
    margin-right: 10px;
    height: 20px;
    margin-bottom: 5px;
}

.chat__info__details {
    margin-top: 20px;
}

.chat__info__details .chat__info__details__info {
    display: flex;
    margin-left: 20px;
}

.chat__info__details .chat__info__details__info img {
    margin-right: 10px;
    height: 20px;
    margin-bottom: 5px;
}

.chats__header__icons ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}

.chats__header__icons ul li {
    color: #fff;
    text-align: center;
    cursor: pointer;
}

.chats__header__icons ul li:hover {
    color: var(--main);
}

.chats__header__icons ul li svg {
    font-size: 26px;
}

.chats__header__icons ul li.single-chat svg {
    font-size: 22px;
}

.chats__header__icons ul li.files-icon svg {
    font-size: 20px;
}

.chats__header__icons {
    margin-bottom: 10px;
    border-bottom: 1px solid #cccccc45;
    padding-bottom: 15px;
}

.header-dropdown>.ant-space-item:last-child {
    display: flex;
    align-items: center;
}

.interviewer-main-section {
    padding-top: 100px;
}

.inter__message {
    max-width: 765px !important;
    width: 100%;
}

@media only screen and (max-width: 1400px) {
    .inter__message {
        max-width: 650px !important;
        width: 100%;
    }

    .chat__center__box {
        width: calc(100% - 330px);
    }
}

@media only screen and (max-width: 1200px) {
    .interviewerHeader .imageBox {
        width: 58px !important;
        height: 58px !important;
        min-width: 58px !important;
        min-height: 58px !important;
    }

    .inter__message {
        max-width: 565px !important;
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    .chat__page {
        padding-top: 80px !important;
    }

    .inter__message {
        max-width: 600px !important;
        width: 100%;
    }

    .interviewerHeader .imageBox {
        width: 48px !important;
        height: 48px !important;
        min-width: 48px !important;
        min-height: 48px !important;
    }

    .interviewer-main-section {
        padding-top: 87px;
    }

    .chat-aside-list {
        height: calc(100vh - 80px);
    }

    .chat__content__scrollable {
        height: calc(100vh - 219px);
    }
    .interviewportal .chat__sidebar, 
    .interviewportal .chat__center__box{
        padding-top: 0px !important;
    }
}

@media only screen and (max-width: 768px) {
    .int_tabs .react-tabs__tab-list {
        overflow-x: scroll !important;
        display: flex !important;
        flex-wrap: nowrap !important;
        overflow-y: hidden !important;
    }

    .interviewer-main-section {
        padding-top: 87px;
    }

    .chat-aside-list {
        height: calc(100vh - 80px);
        width: 100%;
    }

    .chat__content__scrollable {
        height: calc(100vh - 198px);
    }

    .chat__sidebar {
        width: 100%;
    }

    .chat__info__box {
        width: 100%;
        height: 100%;
    }

    .chat__info__box .chat__info__box__top .chat__info__box__top__image {
        width: 140px;
        height: 140px;
    }

    .chat__content__scrollable,
    .chat__sending__form .chat__sending__form__inner {
        padding: 20px 30px;
    }
}

@media only screen and (max-width: 576px) {
    .inter_name {
        font-size: 13px !important;
    }

    .inter_logo {
        max-width: 170px !important;
    }

    .header-dropdown>.ant-space-item:nth-child(2) {
        display: none !important;
    }

    .interviewer-main-section {
        padding-top: 80px;
    }

    .chat__content__scrollable,
    .chat__sending__form .chat__sending__form__inner {
        padding: 15px 15px;
    }

    .chat__page {
        padding-top: 0px !important;
    }

    .chat-aside-list {
        height: 100vh;
        width: 100%;
    }

    .chat__sidebar {
        padding-top: 70px;
    }

    .chat__content__scrollable {
        height: calc(100vh - 196px);
    }

    .chat__center__box {
        padding-top: 70px;
    }

    .chat__info__box {
        padding-top: 70px;
    }
}

.interview__drop .ant-avatar-image {
    width: 25px !important;
    height: 25px !important;
    margin-bottom: 2px !important;
}

.react-tabs__tab {
    text-wrap: nowrap !important;
}

.int_tabs .react-tabs__tab--selected::before {
    display: none !important;
}

.table__total {
    position: absolute;
    bottom: 11px;
    left: 10px;
}

.prof .ant-input-outlined {
    border: 0px solid !important;
    border-right: 0.5px solid #40414180 !important;
}

.prof .ant-input-outlined:hover {
    border: 0px solid !important;
    border-right: 0.5px solid #40414180 !important;
    box-shadow: none !important;
}

.prof .ant-input-outlined:focus {
    border-right: 0.5px solid #40414180 !important;
    box-shadow: none !important;
}