.Susers__right {
  background: rgba(230, 230, 230, 0.368627451);
}

.two__factor__buttons {
  border: 1px solid var(--darkGrey);
  background: #fff;
  border-radius: 8px;
  color:var(--darkGrey);
}

.two__factor__buttons:hover {
  background-color: var(--darkGrey);
  color: #fff;
}

.two__factor__auth__form:last-child {
  border-bottom: none;
}


.remove__devices {
  background-color: var(--darkGrey);
  color: #fff;
  border-radius: 8px;
  margin: 10px 0 20px 0;
  border:none;
  height: 32px;
}

.device__sample {
  border-radius: 0px;
}

.security__password__fields input {
  height: 37px !important;
  min-height: auto;
  font-size: 16px;
}
.qr-code-image{
  width:100%;
}
.desc__applynow.generate__button{
  max-width: 100%;
}