.public__indexscore__box svg.CircularProgressbar {
    max-width: 270px;
    display: block;
    margin: auto;
}

.public__indexscore__box .pro__index_score {
    left: 0;
    top: 20px;
    width: auto;
    margin: auto;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.public__indexscore__box {
    position: relative;
}

.public__indexscore__box>div>div {
    width: auto !important;
    height: auto;
    max-width: 350px;
}

.public__profile__box {
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.public__left__box .public__profile img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.public__left__box .public__profile {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-bottom: 1px solid #E6E6E6;
}

.public__left__box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-right: 1px solid #E6E6E6;
}

.public__left__box .public__profile__company {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
    text-align: center;
    padding: 20px;
}

.public__left__box .public__profile__company .colored__box {
    width: 70px;
    height: 60px;
    background: #0d2159;
    color: #fff;
    font-size: 34px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 10px;
}

.public__left__box .public__profile__company p {
    font-size: 12px;
    margin: 0px;
}

.public__left__box .public__profile__company h5 {
    font-size: 14px;
}

.public__left__box .public__profile h4 {
    font-size: 14px;
    margin: 10px 0 0px 0px;
}

.public__left__box .public__profile p {
    margin: 0px;
    font-size: 12px;
}

.public__image__info__box {
    border-bottom: 1px solid #E6E6E6;
    background: #FDFDFD;
    flex: 1;
}

.public__right__box {
    display: flex;
    align-items: center;
}


.public__profile__box__footer {
    background: #F2F2F2;
    padding: 10px;
    font-size: 12px;
}

.public__profile__box__footer p{
    color:#4A4A49;
    margin:0px;
}