.forgot {
    min-height: 70vh;
}

.forgot__box {
    background-color: #F8F8F8;
    border-radius: 14px;
}

.forgot__input {
    max-width: 600px;
    width: 100%;
    background-color: #F8F8F8;
}
.forgot__input:focus{
    box-shadow: none !important;
}
.forgot__input>.ant-input {
    background-color: #F8F8F8;
}

.forgot__btn {
    background-color: #5F89FF;
    border-radius: 8px;
    border: 0px solid;
    color: white;
}