.Bar {
  background: var(--lightGrey2);
  width: 100%;
  margin-bottom: 100px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.Bar__container {
  width: 440px;
}
.Bar__container__title {
  color: var(--midGrey);
}
.Bar__container__heading {
  font-family: var(--fontFamily);
  font-weight: 300 !important;
  font-size: 30px;
}
.Bar__container__box__top__icon-radio {
  color: var(--main);
  font-size: 30px;
}
.Bar__container__box__top__icon-circle {
  font-size: 33px;
  color: var(--borderGrey);
  margin-left: -1px;
}
.Bar__container__box__top__title {
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: 600;
}
.Bar__container__box__top__title-main {
  color: var(--main);
}
.Bar__container__box__top__title-grey {
  color: var(--darkGrey);
}
.Bar__container__box__bottom {
  position: relative;
  border-left: 3px solid var(--borderGrey);
  height: 100px;
  margin-left: 14px;
}
.Bar__container__box__bottom__para {
  font-family: var(--fontFamily);
  font-size: 14px;
  color: var(--darkGrey);
  line-height: 20px;
}

.Mobile__bar {
  max-width: 155px;
  width: 100%;
  border-top: 3px solid var(--borderGrey);
  margin-top: 14px;
}
.Mobile__box {
  min-width: 27px;
}

@media only screen and (max-width: 1400px) {
  .Bar__container {
    width: 380px;
  }
  .Bar__container__box__top__title, .Bar__container__box__bottom__para {
    font-size: 14px;
  }
  .Bar__container__box__top__icon {
    font-size: 27px !important;
  }
  .Bar__container__box__bottom {
    margin-left: 12px;
  }
}
@media only screen and (max-width: 1200px) {
  .Bar__container {
    width: 318px;
  }
}
@media only screen and (max-width: 992px) {
  .Bar {
    min-height: auto;
    padding-top: 100px !important;
    padding-bottom: 30px !important;
    margin-bottom: 20px;
  }
  .Bar__container {
    width: 100%;
  }
  .Bar__container__box__top__title {
    font-weight: 600;
    white-space: nowrap;
    margin-top: 10px !important;
  }
  .Bar__container__box__top__title-2 {
    margin-left: -20px !important;
  }
  .Bar__container__box__top__title-3 {
    margin-left: -35px !important;
  }
  .Bar__container__box__top__title-4 {
    margin-left: -40px !important;
  }
  .Bar__container__box__top__title-5 {
    margin-left: -50px !important;
  }
  .Bar__container__box__top__title-6 {
    margin-left: -50px !important;
  }
  .Bar__container__box__top__title-7 {
    margin-left: -35px !important;
  }
}
@media only screen and (max-width: 400px) {
  .Bar__container__box__top__title-6 {
    margin-left: -70px !important;
  }
}