.status__live{
  background-color: #F6FCEE !important;
  color: #A6DD50 !important;
}
.status__hold{
  background-color: #FBD47C !important;
  color: #804F00 !important;
}
.status__Pending{
  background-color: #FBD47C !important;
  color: #804F00 !important;
}
.status__closed{
  background-color: #ffecec !important;
  color: #CE0002 !important;
}
.dash__table .ant-table-tbody  {
  border-radius: 10px !important;
  border: 1px solid #70707040 !important;
}
@media only screen and (min-width: 1400px){
  .job-col-4{
    width: 450px;
  }
  .job-col-1{
    width: 95px;
  }
  .job-col-2{
    width: 120px;
  }
  .job-col-last{
    width: 50px;
    margin-right: 10px;
  }
  .newjob__btn{
    max-width: 300px;
  }
  .jobs__pill{
    background-color: #BFD0FF;
    padding: 2px 10px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1600px){
  .job-col-1{
    width: 120px;
  }
  .job-col-2{
    width: 140px;
  }
  .job-col-last{
    width: 50px;
    margin-right: 10px;
  }
}
.newjob__btn{
  max-width: none;
  width: 100%;
}
.jobs__pill{
  background-color: #BFD0FF;
  padding: 2px 10px;
  font-size: 12px;
}
.jobs__box{
  background-color: #618dfc25;
}

.job__dropdown--li:hover {
  background-color: rgb(95 137 255 / 10%);
}
.jobs {
  font-family: var(--fontFamily);
}
.editJob .ant-select .ant-select-clear{
  display: none !important;
}
.jobs__content__hiring {
  box-shadow: -25px 15px 70px rgba(0, 0, 0, 0.0705882353);
  border-radius: 12px;
}

.jobs__content__hiring__heading {
  color: var(--main);
  letter-spacing: -0.88px;
}



.editButton,.editButton:hover {
  background-color: transparent;
  color: rgb(33, 37, 41);
  padding-left: 5px;
  display: block;
}

.jobs__content__hiring__para {
  color: var(--darkBlue);
  letter-spacing: -0.33px;
  font-size: 14px !important;
}

.jobs__content__hiring__btn {
  max-width: 300px;
  width: 100%;
}

.jobs__content__hiring__btn--button {
  border-radius: 8px;
  min-height: 35px;
  height: 100%;
  width: 100%;
  max-width: 200px !important;
  background-color: var(--main);
  color: var(--white);
  border: 1px solid var(--main) !important;
}


.active__roles {
  color: var(--darkBlue) !important;
}


.jobs__filters {
  padding: 0px;
  list-style: none;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 50px;
}

.jobs__filters li {
  min-width: 12%;
  padding-right: 5px;
}

.jobs__filters li button {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border: 0.5px solid #41414080;
  border-radius: 8px;
  line-height: 40px;
  color: var(--darkGrey);
  font-size: 15px;
  letter-spacing: -0.32px;
  margin: 0px;
  display: block;
  width: 100%;
  padding: 0 8px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jobs__filters li button span {
  margin-right: 5px;
  font-size: 14px;
}

.jobs__filters li button svg {
  font-size: 20px;
}

.searchBox {
  background: var(--lightGrey2);
  border-radius: 8px;
  position: relative;
  max-width: 250px;
  width: 100%;
}
.searchBox input:focus{
  outline: none !important;
  border: 0px solid !important;
}




.jobs-header {
  background: #EFEFEF;
  padding: 5px;
  margin-bottom: 10px;
}

.job__status {
  background: #D3EEA8;
  display: inline-flex;
  padding: 2px 10px;
  color: #008000;
  font-size: 12px !important;
  font-family: var(--fontFamily) !important;
}

.job__type {
  display: inline-flex;
  background: #BFD0FF;
  padding: 2px 10px;
  color: #0d2159;
  font-size: 12px !important;
  font-family: var(--fontFamily) !important;
}

.job__category {
  display: inline-flex;
  background: #BFD0FF;
  padding: 2px 10px;
  color: #0d2159;
  font-size: 12px !important;
  font-family: var(--fontFamily) !important;
}
@media only screen and (max-width: 1300px){
  .searchBox{
    max-width: 170px;
  }
}
@media only screen and (max-width: 1200px){
  .searchBox{
    max-width: 100%;
  }
  .newjob__btn{
    max-width: 170px;
  }
}
.searchBox svg {
  position: absolute;
  z-index: 2;
  font-size: 20px;
  top: 9px;
  left: 10px;
}

.searchBox input {
  padding-left: 40px;
  width: 100%;
  height: 40px;
  border: none;
  background-color: var(--lightGrey2);
  border-radius: 8px;
}

.searchBox input::placeholder {
  color: var(--darkGrey);
}


.filter__data .filter__image {
  float: left;
  width: 80px;
  margin-right: 20px;
  background-color: var(--white);
  border-radius: 8px !important;
}

.filter__data .filter__image img {
  min-width: 80px;
  padding: 5px;
  border-radius: 8px;
}
.img--border{
  border: 0.5px solid var(--lightGrey) !important;
}
.filter__content {
  float: left;
  width: calc(100% - 100px);
}
.jobs__image{
  width: 55px;
  height: 55px;
  min-width: 55px;
  min-height: 55px;
  margin-right: 10px !important;
}
.bg-purple{
  background-color: #8741E6 !important;
}
.bg-brown{
  background-color: #4A4A49 !important;
}
.bg-blue{
  background-color: #5F89FF !important;
}
.jobs__image img{
  width: 25px !important;
}
.jobs__filter__btn{
  padding: 5.5px 7px !important;
}
.filter__data .filter__content__para {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
}

.filter__data .filter__content__para .filter__content__label {
  font-weight: 600;
  margin-right: 10px;
}

.filter__data .filter__content__para>p:last-child {
  margin-left: 20px;
}

.filter__data .filter__content__tag__content {
  background-color: var(--pastelBlue);
  border: none;
  height: 23px;
  line-height: 30px;
}

.filter__data .filter__content__tag__content small.filter__content__tag__content--small {
  color: var(--darkBlue);
}

.filter__border:first-child {
  border-top: 1px solid #4a4a4971;
}

.enterprise__items .filter__border {
  transition: all 0.3s ease-in-out;
}

.filter__border:hover {
  background-color: rgb(95 137 255 / 10%);
}

.filter__border:hover .filter__content__link__heading {
  color: var(--darkBlue) !important;
  transition: all 0.3s ease-in-out;
}

.filter__border:hover .filter__content__link__heading__grey, .filter__content__link__heading__grey__icon {
  color: var(--darkGrey) !important;
  transition: all 0.3s ease-in-out;
}

.jobs__tabs {
  margin-top: 60px;
}

.jobs__tabs .react-tabs__tab-list {
  padding-left: 0px;
}
.Ejobs__tabs .react-tabs__tab-list {
  padding-left: 50px;
}
.jobs__tabs .react-tabs__tab {
  color: var(--midGrey);
}

.jobs__tabs .react-tabs__tab-panel {
  padding-top: 30px;
}

.jobs__tabs .react-tabs__tab::after {
  display: none;
}

.jobs__tabs .react-tabs__tab--selected::after {
  display: none;
}


@media only screen and (max-width: 576px) {
  .react-tabs__tab {
    padding: 6px 7px !important;
  }
  .newjob__btn{
    max-width: none;
  }
  .jobs__image{
    width: 54px;
    height: 54px;
    min-width: 54px;
    min-height: 54px;
    margin-right: 10px !important;
  }
  .jobs__image img{
    width: 26px !important;
  }
  .jobspage .ant-radio-button-wrapper{
    padding-inline: 10px !important;
  }
  .text-22{
    font-size: 20px !important;
  }
  .search__button{
    width: 100%;
  }
}

@media only screen and (max-width: 350px) {
  .react-tabs__tab {
    padding: 6px 6px !important;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1500px) {
  .jobs__filters li button span {
    margin-right: 5px;
    font-size: 12px;
  }
  .jobs__filters li button svg{
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .Ejobs__tabs .react-tabs__tab-list {
    padding-left: 26px;
  }
  .jobs__content__hiring__heading {
    font-size: 35px;
  }
  .jobs__content__hiring__btn {
    max-width: 200px !important;
  }

  .jobs__content__hiring__btn--button {
    max-width: 200px;
    width: 100%;
  }

  .jobs__filters li button {
    padding: 0px 5px;
    font-size: 12px;
  }

  .jobs__filters li button span {
    font-size: 12px;
  }

  .jobs__filters li button svg {
    font-size: 14px;
  }
  .job-col-4{
    width: 400px;
  }
  .job-col-1{
    width: 90px;
  }
  .job-col-2{
    width: 110px;
  }
  .job-col-last{
    width: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  .jobs__content__hiring__heading {
    font-size: 30px;
  }

  .jobs__content__hiring__btn {
    max-width: 200px;
  }

  .jobs__content__hiring__btn--button {
    font-size: 14px;
  }
  .job-col-1{
    width: 110px;
  }
  .job-col-last{
    width: 40px;
  }
  
}

@media only screen and (max-width: 992px) {
  .jobs__content__hiring__heading {
    font-size: 25px;
  }

  .jobs__content__hiring__btn {
    max-width: 150px;
  }

  .jobs__content__hiring__btn--button {
    font-size: 12px;
  }
  .Ejobs__tabs .react-tabs__tab-list {
    padding-left: 18px;
  }
  .job-col-1{
    width: 92px;
  }
  .job-col-last{
    width: 30px;
  }
}
.w-sm-50{
  width: 50%;
}
@media only screen and (max-width: 576px){
  .job__type{
    font-size: 12px !important;
  }
  .w-sm-50{
    width: 100%;
  }
  .job-title-sm{
    font-size: 14px;
  }
}
.filter__content__link__heading__grey {
  color: #000000c1 !important;
  letter-spacing: -0.48px !important;
}

.filter__content__link__heading__grey__icon {
  color: var(--lightGrey) !important;
}

.jobs__filter__header {
  font-size: 16px;
}

.jobs__filter__li {
  font-size: 14px;
}
.text-grey{
  color: #999999 !important;
}
.jobs--img{
  border: 0px solid !important;
}