.DSidebar {
  width: 300px;
  height: 100vh;
  border-right: 2px solid rgba(112, 112, 112, 0.2274509804);
  overflow-x: hidden;
  z-index: 11000 !important;
  background: var(--white);
  transition: all 0.3s ease-in-out;
}

.DSidebar::-webkit-scrollbar {
  width: 9px !important;
}

.DSidebar::-webkit-scrollbar-track {
  background: rgb(255, 255, 255) !important;
}

.DSidebar::-webkit-scrollbar-thumb {
  background-color: var(--darkBlue) !important;
  border-radius: 20px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}

.logo-sm {
  margin-top: -15px;
}

.DSidebar__item {
  font-family: var(--fontFamily);
  font-size: 18px;
  color: var(--darkBlue);
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.1s ease-in-out;
  text-decoration: none !important;
  user-select: none !important;
}

.DSidebar__item:hover {
  color: var(--darkBlue);
}

.burger__icon {
  color: var(--darkBlue);
  margin-top: -8px;
}

.DSidebar__item--light {
  color: rgba(74, 74, 73, 0.6823529412) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DSidebar__item__icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.DSidebar__item__icon-inbox {
  width: 22px;
}

.DSidebar__item__Sicon {
  width: 28px;
  cursor: pointer;
}

.DSidebar__item__Nicon {
  width: 25px;
  cursor: pointer;
}

.DNavbar__right {
  margin-top: 10px;
}

.DSidebar__container {
  width: 270px;
}

.DSidebar__container__logo {
  width: 180px;
  z-index: 999999;
  margin: 10px 0px;
}

.DSidebar__container__top__profile__avatar {
  min-width: 68px !important;
  min-height: 68px !important;
  cursor: pointer;
}

.DSidebar__container__top__profile__avatar img {
  min-width: 68px !important;
  min-height: 68px !important;
  cursor: pointer;
}

.DContainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 80px !important;
}

.DPage {
  overflow-x: hidden !important;
}



@media only screen and (max-width: 1500px) {
  .DSidebar__item__Sicon {
    width: 25px;
  }

  .DSidebar__item__Nicon {
    width: 22px;
  }

  .DNavbar__right {
    margin-top: 0px;
  }

  .DSidebar__container__logo {
    width: 160px;
  }

  .DSidebar__container__top__profile__avatar {
    min-width: 55px !important;
    min-height: 55px !important;
  }

  .DSidebar__container__top__profile__avatar img {
    min-width: 55px !important;
    min-height: 55px !important;
  }

  .DSidebar__item {
    font-size: 15px;
  }

  .DSidebar__item--small {
    font-size: 14px;
  }

  .DSidebar {
    width: 270px;
  }

  .DSidebar__container {
    width: 230px;
  }

  .DContainer {
    padding-top: 80px !important;
  }

  .DSidebar__item__icon {
    width: 20px;
  }

  .DSidebar__item__icon-inbox {
    width: 21px;
  }
}

@media only screen and (max-width: 992px) {
  .DContainer {
    padding-top: 60px !important;
  }

  .DHeader__padding {
    padding: 0px !important;
  }

  .DSidebar {
    width: 300px;
  }

  .DSidebar__item__icon11 {
    margin-top: -23px !important;
  }

  .DSidebar__item__icon7 {
    font-size: 25px;
  }

  .DNavbar__right {
    margin-top: 20px;
  }

  .DSidebar__container__top__profile__avatar {
    min-width: 55px !important;
    min-height: 55px !important;
  }

  .DSidebar__item {
    font-size: 16px;
  }

  .DSidebar__item--small {
    font-size: 14px;
  }

  .DSidebar__container {
    max-width: 295px;
    width: 100%;
  }

  .DSidebar__item__icon {
    font-size: 23px !important;
  }
}

.DHeader {
  width: 100%;
  background: var(--white) !important;
  z-index: 1000;
}

.DHeader__container {
  position: relative;
}

.DHeader__container__menu {
  height: 45px;
  width: 45px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 220px;
  z-index: 99;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 576px) {
  .DSidebar__item__Sicon {
    width: 22px;
  }

  .DSidebar__item__Nicon {
    width: 20px;
  }

  .burger__icon {
    color: var(--darkBlue);
    margin-top: -15px;
  }

  .DNavbar__right {
    margin-top: 30px;
  }

  .DSidebar__item__icon11 {
    margin-top: -32px !important;
  }

  .DHeader__container__menu {
    left: 235px;
  }

  .DSidebar {
    width: 85%;
  }

  .DSidebar__container {
    max-width: 100%;
    width: 100%;
  }
}

.DNavbar__right {
  transition: all 0.3s ease-in;
}

.DSidebar--margin {
  margin-right: 38px !important;
}

.PDSidebar--margin {
  margin-right: 38px !important;
}

@media only screen and (max-width: 1400px) {
  .PDSidebar--margin {
    margin-right: 43px !important;
  }
}

@media only screen and (max-width: 992px) {
  .DSidebar--margin {
    margin-right: 0px !important;
  }

  .PDSidebar--margin {
    margin-right: 0px !important;
  }
}