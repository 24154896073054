.ues1__para{
    max-width: 740px;
    width: 100%;
}
.ues1__image{
    background-color: #4C7AE8;
}
.ues2__card1__top{
    background-color: #486fd9bc;
}
.ues2__card2__top{
    background-color: rgba(72, 111, 217, 0.463)
}
.ues2__card3__top{
    background-color: #bfd0ff77
}
.ucs2__card1__top{
    background-color: #a7dd5079;
}
.ucs2__card2__top{
    background-color: #e6ffbfa5;
}
.ucs2__card3__top{
    background-color: #d0fd5e27;
}
.ues2__card__top img{
    height: 200px;
    padding: 20px 0px;
}
.ues2__card h3,
.ues2__card p{
    max-width: 260px;
    width: 100%;
}
.ues2__card p{
    max-width: 300px;
    width: 100%;
}
.ues2__card3 h3{
    max-width: 200px;
}
.ues3{
    background-color: #F9F8F6;
}
.ues3__card1{
    background-color: #486FD9;
}
.ues3__card2{
    background-color: #DEDEDE;
}
.ucs3__card2{
    background-color: #A6DD50;
}
.ues3__card1 p {
    max-width: 480px;
    width: 100%;
}
.ues3__card2 p {
    max-width: 479px;
    width: 100%;
}
.ues3__card3{
    background-color: #BFD0FF;
}
.ues3__card3 p {
    max-width: 412px;
    width: 100%;
}
.ues3__card4{
    background-color: #CFFD5E;
}
.ues3__card4 p {
    max-width: 350px;
    width: 100%;
}
.ues3__card5 p {
    max-width: 370px;
    width: 100%;
}
.ues3__card4__btn{
    color: #436C03;
    background: white;
}
.ues3__card4__btn:hover{
    color: white;
    background: #436C03;
}
.ues5 img{
    max-width: 450px;
    width: 100%;
}
.ues5 p{
    max-width: 666px;
    width: 100%;
}
.ucs1__image{
    background-color: #CBFE73;
}
.ucs3__card2__h1{
    font-size: 250px;
}
.ucs3__card3{
    background-color: #E6E6E6;
}
.text-xxl-16{
    font-size: 16px !important;
    line-height: 22px !important;
}
@media only screen and (max-width: 1400px){
    .ues1__para{
        max-width: 640px;
        width: 100%;
    }
    .ues2__card p{
        max-width: 260px;
        width: 100%;
    }
    .text-xxl-16{
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .ues3 p{
        font-size: 14px !important;
    }
    .ues3__card1 p {
        max-width: 400px;
        width: 100%;
    }
    .ues3__card2 p {
        max-width: 419px;
        width: 100%;
    }
    .ues3__card3 p {
        max-width: 380px;
        width: 100%;
    }
    .ues3__card4 p {
        max-width: 310px;
        width: 100%;
    }
    .ues3__card6 p{
        max-width: 590px;
        width: 100%;
    }
}
@media only screen and (max-width: 1200px){
    .ucs3__card2__h1{
        font-size: 180px;
    }
}
@media only screen and (max-width: 992px){
    .ues2__card p{
        max-width: 360px;
        width: 100%;
    }
    .ucs3__card2__h1{
        font-size: 160px;
    }
}
@media only screen and (max-width: 576px){
    .ucs3__card2__h1{
        font-size: 120px;
    }
}