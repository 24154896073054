.Experts {
  background: var(--lightBlue3);
}

.candidates__button {
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  height: 40px !important;
  box-shadow: 0px 0px 15px #0000000D;
  background: #F8F8F8;
}

.candidates__button__schedule {
  background: #12D3DB;
}

.candidates__button__icon {
  font-size: 20px !important;
}

.candidates__button--img {
  width: 20px;
}

.Experts__name {
  font-size: 16px;
  color: var(--darkblue);
}

.Experts__arrow {
  color: var(--darkGrey);
  opacity: 0.4;
  font-size: 22px;
  cursor: pointer;
  transition: all 0.1s linear;
}

.Experts__arrow:hover {
  transform: scale(1.3, 1.3);
}

.Experts__title {
  color: var(--darkGrey);
  opacity: 0.4;
  font-size: 14px;
  line-height: 16px;
}

.Experts__row {
  border: 1px solid var(--bordergrey);
  background: rgba(16, 187, 193, 0.0941176471);
  border-radius: 18px;
}

.Experts__search {
  border: 1px solid var(--bordergrey);
  height: 30px;
  background: var(--lightblue3);
  border-radius: 4px;
}

.Experts__search--input {
  border: 0px solid !important;
  background: rgba(0, 0, 0, 0) !important;
}

.Experts__search--input:focus {
  outline: none !important;
  border: 0px solid !important;
}

.Experts__search__icon {
  color: var(--darkTeal);
  cursor: pointer;
  font-size: 16px;
}

.pro__profile__icons__top ul {
  list-style: none;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
}

.pro__profile__icons__top ul li {
  margin: 0px 5px;
}

.pro__profile__icons__top ul li .pro__profile__back {
  width: 100px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 37px;
  border: 1px solid var(--darkGrey);
  color: var(--darkGrey);
  text-decoration: none;
  padding: 0px;
  border-radius: 8px;
  font-size: 16px;
}

.pro__profile__icons__top ul li .pro__profile__back:hover {
  background-color: var(--darkGrey);
  color: #fff;
}

.pro__profile__icons__top ul li .pro__profile__edit {
  width: 100px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 37px;
  border: 1px solid var(--lightBlue2);
  color: var(--lightBlue2);
  text-decoration: none;
  padding: 0px;
  border-radius: 8px;
  font-size: 16px;
}

.pro__profile__icons__top ul li .pro__profile__edit:hover {
  background-color: var(--lightBlue2);
  color: #fff;
}

.pro__profile__icons__top ul li .pro__profile__save {
  width: 100px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 37px;
  border: 1px solid #10BAC1;
  color: var(--white);
  background-color: #10BAC1;
  text-decoration: none;
  padding: 0px;
  border-radius: 8px;
  font-size: 16px;
}

.pro__profile__icons__top ul li .pro__profile__save:hover {
  background-color: var(--white);
  color: #10BAC1;
}

.profile__left {
  border: 0.5px solid #E6E6E6;
}

.pro__profile__header__inner {
  background-color: #CBCBCB40;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #70707040;
}

.pro__profile__editBox {
  display: flex;
  background-color: #4a4a491f;
  cursor: pointer;
  width: 60px;
  flex-direction: row;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.pro__profile__editBox svg {
  font-size: 14px;
  margin-right: 5px;
}

.pro__profile__header__inner__top {
  display: flex;
  justify-content: flex-end;
}

.pro__profile__userInfo {
  display: flex;
  align-items: center;
  margin-top: -15px;
}

.pro__profile__userInfo .pro__user__imageBox {
  font-size: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 15px;
  margin-top: -10px;
}

.pro__profile__userInfo .pro__user__name h4 {
  font-size: 36px;
  color: var(--darkBlue);
  letter-spacing: -0.72px;
}

.pro__profile__coverInfo p {
  color: var(--darkGrey);
  letter-spacing: -0.32px;
  font-size: 16px;
}



.pro__profil__addMore {
  border: 1px solid var(--darkGrey);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
}

.pro__yourProfile__progressBar {
  border: 0.5px solid var(--midGrey);
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  border-radius: 12px;
}

.pro__yourProfile__progressBar__inner {
  display: block;
}

.pro__yourProfile__progressBar__inner .CircularProgressbar.progressBars {
  width: 300px;
  max-width: 100%;
  margin: 0px;
}

.pro__yourProfile__progressBar__inner .CircularProgressbar-text {
  display: none;
}

.pro__yourProfile__progressBar__inner .yourIndex__pro__index_score {
  margin: 0px !important;
}

.work__exp__box__header {
  margin-top: 40px;
}


.work__exp__box__item {
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid #70707070;
  padding-top: 30px;
  padding-bottom: 30px;
}

.work__exp__box__item:first-child {
  border-top: none;
}

.work__exp__box__item__content {
  display: flex;
  width: 100%;
}

.work__exp__box__para {
  width: 90%;
}

.work__exp__box__company__info {
  padding: 0px 20px;
}

.work__exp__box__company__info h5 {
  font-size: 18px;
  letter-spacing: -0.36px;
  color: var(--darkGrey);
}

.work__exp__box__company__info p {
  letter-spacing: -0.32px;
  font-size: 16px;
}

.lightBlueBar__content__icon__image2 {
  border: 1px solid #70707080;
  border-radius: 8px;
  padding: 0px;
  max-width: 70px;
}

.work__exp__box__list {
  padding-bottom: 40px;
}


.reference__item .work__exp__box__company__info {
  padding-left: 0px;
}

.reference__item .work__exp__box__item__duration {
  display: flex;
  width: 40%;
  justify-content: flex-end;
}

.reference__item .work__exp__box__item__content {
  width: 60%;
}

.reference__verification {
  margin-right: 20px;
}

.reference__verification--main {
  color: var(--main) !important;
}

.reference__verification {
  color: #FF8D1C !important;
}

.reference__verification--red {
  color: #FF0202 !important;
}


.work__exp__box__item.edu__and__qualif__item .work__exp__box__company__info {
  padding-left: 0px;
}

.work__exp__box__item.edu__and__qualif__item .work__exp__box__item__duration p {
  margin-bottom: 5px;
}

.work__exp__box__item.edu__and__qualif__item .work__exp__box__item__duration p.edu__classInfo {
  font-size: 18px;
  color: var(--darkGrey);
  font-weight: 300;
}

.work__exp__box__item.edu__and__qualif__item .work__exp__box__item__duration {
  text-align: right;
}

.pro__profile__editBox.reupload {
  width: 100px;
}


.yourProfile__videoUpload {
  height: 400px;
  background: #f8f8f8;
  margin: 30px 0px 20px 0px;
}


.pro__profile__editBox.reupload {
  width: 100px;
}

.work__exp__percent {
  height: 90%;
  background: #a7dd501d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  font-size: 60px;
  font-weight: 600;
  margin-left: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 90%;
}

.work__exp__percent.achieved {
  color: var(--main);
}

.work__exp__percent.pending {
  color: #FF8955;
  background-color: #fff3ee;
}

.work__exp__box__header.work__exp__online__presence {
  margin-bottom: 20px;
}

.work__exp__box__header.work__exp__open__questions {
  margin-bottom: 20px;
}

.work__exp__box.open__questions__box {
  margin-bottom: 40px;
}

.work__exp__box__header.work__exp__online__presence {
  margin-bottom: 20px;
}

.work__exp__box__header.work__exp__open__questions {
  margin-bottom: 20px;
}

.yourprofile__form__buttons {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.yourprofile__form__buttons .back__button {
  height: 50px;
  display: block;
  width: 260px;
  text-align: center;
  text-decoration: none;
  border: 0.5px solid var(--darkGrey);
  line-height: 45px;
  color: var(--darkGrey);
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px;
  margin-right: 20px;
}

.yourprofile__form__buttons .back__button:hover {
  color: var(--white);
  background-color: var(--darkGrey);
}

.yourprofile__form__buttons .save__button {
  height: 50px;
  display: block;
  width: 260px;
  text-align: center;
  text-decoration: none;
  border: 0.5px solid #10BAC1;
  line-height: 45px;
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px;
  margin-right: 20px;
  background-color: #10BAC1;
}

.save__button,
.back__button {
  max-width: 180px;
}

.yourprofile__form__buttons .save__button:hover {
  color: #10BAC1;
  background-color: var(--white);
}



.design__revamp {
  background: #f8f8f8;
}

.design__revamp .favoriteCandidates.py-3 {
  background: #fff;
}

.design__revamp .row.enterprise.Experts__row.candidates__all {
  background: #fff !important;
  margin: 2px 5px !important;
  border: none !important;
}

.design__revamp .row.enterprise.Experts__row.candidates__all:hover {
  background: #f8f8f8 !important;
  border: none !important;
}

.design__revamp span.status-accepted {
  display: block;
  padding: 5px 10px;
  border-radius: 8px;
  color: #436C03;
  background: #d3edaa;
  
}

.design__revamp span.status-offered {
  display: block;
  padding: 5px 10px;
  border-radius: 8px;
  color: #AC5600;
  background:#ffa50070;
}

.email__id {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tags-list {
  display: inline-block;
  padding: 5px 10px;
  background: #5F89FF;
  margin-right: 10px;
  color: #fff;
  border-radius: 4px;
}
.tags{
  padding: 0px;
  list-style: none;
}

.job__id__trim {
  display: block;
  width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 30px;
}


.profile__left__circular.role__relevance {
  border: 0.5px solid #E6E6E6;
  padding: 0 20px 20px 20px;
  text-align: center;
  position: relative !important;
  max-width: 230px;
}
.profile__left__circular.role__relevance .indexcore_score {
  right: 0px;
}
.profile__left__circular.role__relevance .score-value {
  font-size: 40px !important;
  line-height: 1;
}

.role__relevance__bar.progressBar__candidates .progress-bar {
  height: 20px;
}

.role__relevance__bar.progressBar__candidates {
  height: 20px !important;
  margin-bottom: 30px;
}

.ats__job__data__role__relevance {
  position: relative;
}

.ats__job__data__role__relevance .relevance__show {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clickATS{
  cursor: pointer;
}
.slider-parent input[type="range"] {
  background: #91caff;
  border-radius: 20px;
  width: 100%;
  height: 4px;
  position: relative;
  z-index: 2;
}
.ATS__box .ats__inputBox .ant-input-affix-wrapper{
  padding: 10px 20px !important;
}
.get__candidates{
  min-width:200px;
}
.connect__ats{
  cursor: pointer;
}

.provider-logo{
  width:40px;margin-right:10px;
}
.profile-image.candidate__photo__ats {
  width: 150px;
  height: 150px;
}

.profile-image.candidate__photo__ats .candidate__photo__ats__image {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

@media only screen and (max-width: 1400px) {
  .pro__yourProfile__progressBar__inner .CircularProgressbar.progressBars {
    width: 240px;
    margin: auto;
  }

  .pro__yourProfile__progressBar__inner .yourIndex__pro__index_score {
    margin-bottom: -100px !important;
  }

  .pro__profile__coverInfo p {
    font-size: 14px;
  }

  .pro__profile__userInfo .pro__user__name h4 {
    font-size: 28px;
  }

  .lightBlueBar__content__icon__image2 {
    max-width: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .Experts__name {
    font-size: 16px;
    font-weight: 600;
  }

  .lightBlueBar__content__icon__image2 {
    max-width: 50px;
  }

  .Experts__title {
    font-size: 12px;
  }
}

@media only screen and (max-width: 992px) {

  .Experts__title {
    font-size: 10px;
    line-height: 12px;
  }
}

@media only screen and (max-width: 768px) {

  .get__candidates{
    width:100%;
    margin-top: 10px;
  }

  .Experts__title {
    font-size: 8px;
    line-height: 10px;
  }

  .Experts__search {
    height: 25px;
  }

  .Experts__search--input {
    font-size: 14px;
  }

  .work__exp__box__list {
    padding-bottom: 0px;
  }

  .candidates__button {
    height: 40px !important;
  }

  .candidates__button__schedule {
    font-size: 14px;
  }

  .candidates__button__icon {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 576px) {

  .Experts__search {
    height: 25px;
  }

  .Experts__search--input {
    font-size: 12px;
  }

  .Experts__top {
    display: flex !important;
  }

  .pro__profile__icons__top ul {
    flex-wrap: wrap;
  }

  .pro__profile__icons__top ul li .pro__profile__save,
  .pro__profile__icons__top ul li .pro__profile__edit,
  .pro__profile__icons__top ul li .pro__profile__back {
    margin-bottom: 10px;
    min-width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .pro__profile__userInfo .pro__user__name h4 {
    font-size: 25px;
    line-height: 25px;
  }

  .work__exp__box__para {
    width: 100%;
  }
}

@media only screen and (max-width: 340px) {
  .Experts__top {
    display: block !important;
  }

  .Experts__search {
    margin-bottom: 20px;
  }
}

.candidate__content {
  background: var(--lightGrey2);
  border-radius: 18px;
}

.candidate__content__left {
  max-width: 800px;
  width: 100%;
}

.candidate__content__left__heading {
  color: var(--black) !important;
  font-size: 40px !important;
  font-weight: bold !important;
  letter-spacing: -1.88px !important;
}

.candidate__content__left__para {
  line-height: 24px !important;
}

.candidate__content__right {
  max-width: 250px;
  width: 100%;
}

.candidate__content__right__iconContainer__icon {
  font-size: 24px;
}

.indexCore__score__heading {
  color: var(--black) !important;
  font-weight: normal !important;
  font-size: 20px !important;
}

.indexCore__score__progressBar__container {
  height: 12px !important;
}

.favoriteCandidates__heading {
  font-size: 22px;
  color: var(--darkTeal);
  letter-spacing: -0.38px;
}

.favoriteCandidates__heading--span {
  color: var(--lightGrey);
}

.favoriteCandidates__border {
  border-bottom: 1px solid rgba(97, 207, 217, 0.3607843137);
}

.candidates__button--img1 {
  width: 15px;
}

.profile__icon {
  background: #E6E6E6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.profile__icon--img {
  width: 20px;
}

.profile__icon--img1 {
  width: 17px;
}

.profile__add {
  width: 23px;
}

.profile__left__top,
.profile__left__circular,
.profile__left__purpose,
.border-05 {
  border-bottom: 0.5px solid #E6E6E6;
}

.profile__right {
  border: 0.5px solid #E6E6E6;
}

.indexcore_score {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
}

.profile__right .ant-tabs-nav-wrap {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.profile__right .ant-tabs-tab-active::before {
  background: none !important;
}

.profile__right .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--darkBlue) !important;
}

.profile__right .ant-tabs-tab:hover {
  color: var(--darkBlue) !important;
}

.profile__right .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 22px !important;
}

.filter__content__tag__content-verified {
  color: #436C03 !important;
  background: #B7FACE !important;
}

.profile__social {
  max-width: 150px;
  width: 15%;
  height: 100px;
  background: #e6e6e644 0% 0% no-repeat padding-box;
  border: 0.5px solid #70707040;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
}

.profile__social--img {
  width: 40px;
}

.profile__social--img2 {
  width: 60px;
}

.profile__social__overlay {
  background: #E6E6E6;
  opacity: 0;
  border-radius: 6px;
}

.profile__social:hover .profile__social__overlay {
  opacity: 1;
}

@media only screen and (max-width: 1500px) {
  .profile__social {
    width: 18%;
    height: 110px;
  }
}


@media only screen and (max-width: 1200px) {
  .profile__social {
    width: 23%;
    height: 110px;
  }
}

@media only screen and (max-width: 576px) {
  .profile__social--img {
    max-width: 35px;
    width: 100%;
  }

  .profile__social {
    width: 31%;
    height: 110px;
  }
}

@media only screen and (max-width: 410px) {
  .profile__social--img {
    max-width: 35px;
    width: 100%;
  }

  .profile__social {
    width: 46%;
    height: 110px;
    justify-content: center;
  }
}

.bluegradient__btn {
  background: transparent linear-gradient(0deg, #3E5FBB 0%, #2568FF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #0000000D;
  border: 1px solid #486FD9;
  border-radius: 8px;
}

.profile__comment {
  border: 0.5px solid #70707040;
  border-radius: 6px;
}

.lightblue__btn {
  background: #bfd0ff79 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

.profile__comment .ant-avatar-group>*:not(:first-child) {
  margin-inline-start: 2px !important;
}

.bg-pastel {
  background: #bfd0ff79 0% 0% no-repeat padding-box;
  border-radius: 6px;
}

.profile__comment--input {
  width: 100%;
  border: 0.5px solid #70707040;
  border-radius: 6px;
}

.profile__comment--input:focus {
  border: 1px solid #486FD9 !important;
}

.profile__more {
  width: 33px;
  height: 33px;
  border: 0.5px solid #70707040;
  border-radius: 8px;
  background: white;
}

.profile__btn {
  border: 0.5px solid #70707040;
  border-radius: 8px;
  background: white;
  height: 33px;
}

.message_index {
  background: #E6E6E6 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 20px;
  line-height: 18px;
  height: 19px;
}

.profile__avatar {
  max-width: 150px;
  width: 100%;
  height: 150px;
}

.profile__avatar--img {
  max-width: 90px;
  width: 100%;
}

.pro-profile-container {
  min-height: 100vh;
  padding-top: 80px;
  margin-top: -80px;
}

@media only screen and (max-width: 1400px) {
  .profile__right .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 15px !important;
  }
}

.profile__right .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
  overflow-x: hidden !important;
  padding-right: 20px !important;
}

#rc-tabs-1-tab-tab #3 {
  padding-right: 20px !important;
}

.ATS .chart-container canvas {
  max-width: 100% !important;
  width: 100% !important;
  height: auto !important;
}

.candidates__filter:hover {
  border: 0.5px solid rgb(95, 137, 255);
  color: rgb(95, 137, 255);
}

.ATS .Experts__row {
  background-color: white !important;
  border: 1px solid var(--midGrey) !important;
}

.ATS__box {
  border: 1px solid var(--midGrey) !important;
  border-radius: 8px;
  max-width: 350px;
  width: 100%;
  background-color: white;
}

.ATS__box__dp {
  width: 30px;
  height: 30px;
  border: 1px solid var(--midGrey) !important;
}

.ATS .ant-select-selector {
  border: 0px solid !important;
  padding: 0px !important;
  padding-left: 5px !important;
  padding-top: 3px !important;
}

.ATS .ant-select-single.ant-select-sm {
  width: 200px !important;

}

.ATS .ant-select-open .ant-select-selection-item {
  font-size: 14px !important;
  color: rgb(95, 137, 255) !important;
}
.ATS .ant-select-selection-item {
  font-size: 14px !important;
}

.ATS__select .ant-select-item-option-content {
  font-size: 12px !important;
}

.ATS .ant-select-selection-placeholder {
  font-size: 14px !important;
}

.ATS .ant-select-selection-search {
  width: fit-content !important;
}

.ATS__box__bottom {
  background-color: #e6e6e6;
  height: 60px;
}

.ATS .ant-input-affix-wrapper {
  padding: 0 !important;
  background-color: #e6e6e6 !important;
  border: 0px solid !important;
}

.ATS .ant-input-affix-wrapper:hover {
  box-shadow: none !important;
  border-color: #0000000D !important;
}

.ATS .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  border-color: #0000000D !important;
}

.ATS .ant-input-affix-wrapper>input.ant-input {
  background-color: #e6e6e6 !important;
}

.ATS__warning {
  border: 1px solid #FF8955;
  background: #FFD0BC80;
  color: #FF8955;
  font-size: 11px;
}

.ATS__add {
  background: #4A4A490D;
  border-radius: 8px;
  max-width: 350px;
  width: 100%;
  min-height: 100px;
}
.ATS__box .dropdown-item:focus{
  background-color: #0000000D !important;
  color: var(--darkGrey);
}

.custom-selectbox {
  position: relative;
}
.custom-selectbox p{
  margin-top: -3px;
}
.ATS__select {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.selected-value {
  background: transparent;
  padding: 8px;
  display: inline-block;
  cursor: pointer;
}

.custom-selectbox-icon {
  margin-left: 5px;
}

@media only screen and (min-width: 1200px) {
  .ATS .chart-container canvas {
    max-width: 300px !important;
    width: 100% !important;
    height: auto !important;
  }
  .ATS__box, .ATS__add{
    max-width: 28%;
  }
}

@media only screen and (min-width: 1400px) {
  .ATS .chart-container canvas {
    max-width: 180px !important;
    width: 100% !important;
    height: auto !important;
  }
  .ATS__box, .ATS__add{
    max-width: 32%;
  }
}

@media only screen and (min-width: 1600px) {
  .ATS .chart-container canvas {
    max-width: 225px !important;
    width: 100% !important;
    height: auto !important;
  }
  .ATS__box, .ATS__add{
    max-width: 400px;
  }
}

@media only screen and (min-width: 1800px) {
  .ATS .chart-container canvas {
    max-width: 280px !important;
    width: 100% !important;
    height: auto !important;
  }
}
@media only screen and (max-width: 1200px){
  .ATS__box, .ATS__add{
    max-width: 49%;
  }
}
@media only screen and (max-width: 600px){
  .ATS__box, .ATS__add{
    max-width: 100%;
  }
}