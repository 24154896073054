.share :where(.css-dev-only-do-not-override-1ae8k9u).ant-btn {
    border: 0.5px solid #40414180 !important;
}

.share .ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus-within {
    box-shadow: none !important;
    border: 0.5px solid #5F89FF !important;
}

.share .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    box-shadow: none !important;
    border: 0.5px solid #5F89FF !important;
}

.shareDropdown {
    border: 0.5px solid #5F89FF !important;
    border-radius: 6px !important;
}

.share .ant-dropdown-open {
    border: 0.5px solid #5F89FF !important;
    color: #5F89FF !important;
}

.shareDropdown .ant-dropdown-menu-item {
    padding: 5px 10px !important;
    font-size: 14px !important;
}

.shareDropdown .ant-dropdown-menu li svg {
    margin-right: 5px !important;
    width: 25px !important;
}

.share .ant-progress-text {
    font-weight: 600;
    font-family: var(--fontFamily);
}

.share .ant-progress-outer {
    height: 10px !important;
    margin-inline-end: calc(-2em - 10px) !important;
    padding-inline-end: calc(2em + 10px) !important;
}

.share .ant-progress-inner,
.share .ant-progress-bg {
    height: 10px !important;
}

.share .ant-radio-inner {
    background-color: transparent !important;
    border: 1px solid #40414180 !important;
}
.share__greenbox{
    background-color: #F7FCEF;
}
.tag-completed{
    background-color: #F6FCEE !important;
    padding: 5px 10px;
}
.plus__icon{
    width: 20px !important;
}
.doc__img{
    width: 43px;
}

.dashprofile__avatar.resume-avatar {
    width: 100px;
    height: 100px;
}
@media only screen and (min-width: 768px) {
    .w-md-90{
        width: 90%;
    }
    .w-md-80{
        width: 70%;
    }
}
@media only screen and (min-width: 992px) {
    .edashprofile {
        padding-top: 130px !important;
    }
}