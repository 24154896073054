.jobs {
  font-family: var(--fontFamily);
}
.dash__graph__bar__name{
  bottom: -20px !important;
  left: 0;
}
.dashboardScreen__content__hiring {
  border-radius: 12px;
  background-color: var(--main);
  min-height: 150px;
}

.dashboardScreen__content__hiring__heading {
  color: var(--white);
  letter-spacing: -0.88px;
}

.dashboardScreen__content__hiring__para {
  color: var(--white);
  font-size: 15px;
  letter-spacing: -0.33px;
}

.dashboardScreen__content__hiring__btn {
  max-width: 250px;
  width: 100%;
  font-size: 15px;
}

.dashboardScreen__content__hiring__btn--button {
  border-radius: 8px;
  min-height: 35px;
  height: 100%;
  width: 100%;
  background-color: var(--main);
  color: var(--white);
  border: 1px solid var(--white);
  transition: all 0.3s ease-in-out;

}

.dashboar__expiring__jobs__box .expiring__body_detail .expiring__body_detail__info__tags ul li {
  display: none;
}

.dashboar__expiring__jobs__box .expiring__body_detail .expiring__body_detail__info__tags ul li:first-child,
.dashboar__expiring__jobs__box .expiring__body_detail .expiring__body_detail__info__tags ul li:nth-child(2) {
  display: block;
}

.dashboardScreen__content__hiring__btn--button,
.dashboardScreen__content__hiring__btn {
  max-width: 150px !important;
}

.dash__account_image {
  width: 100px;
  height: 68px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  padding: 0px 10px;
}

.dash__avatar {
  width: 125px;
  height: 125px;
  object-fit: cover !important;
  overflow: hidden;
}

.dashprofile__avatar {
  width: 155px;
  height: 155px;
  object-fit: cover !important;
  overflow: hidden;
}

.no-jobs {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
  font-weight: 600;
}


/* plans css */

.dashboard-plans-enterprise .plan__monthly__pricing h2 {
  font-size: 12px;
}

.dashboard-plans-enterprise .plan__cards .plan__cards__heading {
  font-size: 18px;
}

.dashboard-plans-enterprise .plan__monthly__features li {
  font-size: 8px;
  margin-bottom: 0px;
}

.dashboard-plans-enterprise .plan__cards {
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.dashboard-plans-enterprise .plan__monthly__features {
  margin-top: 10px;
  margin-bottom: 30px;
}

.dashboard-plans-enterprise .plan__monthly__features li svg {
  font-size: 10px;
}

.dashboard-plans-enterprise .plan__cards.current__package:before {
  font-size: 12px;
}

.gif-image {
  max-width: 80px;
}
.expiring__body_detail_image.comany__image {
  background: #fff;
}

@media only screen and (max-width:1400px) {

  .dashboardScreen__content__hiring__btn--button,
  .dashboardScreen__content__hiring__btn {
    max-width: 180px !important;
  }

  .dash__avatar {
    width: 80px;
    height: 80px;
  }
}

.enterprise.dashboardScreen_circular__box .dash__para__text.text-center {
  max-width: 80%;
  margin: auto;
}

.enterprise.dashboardScreen_circular__box .CircularProgressbar .CircularProgressbar-text {
  font-size: 16px;
  font-weight: 300;
}

.dashboardScreen__content__hiring__btn--button:hover {
  background-color: #436C03;
  border-color: #24BAC0;
}

.active__roles {
  color: var(--darkBlue) !important;
}

.progressBars {
  max-width: 271px;
  margin: 0 auto;
  display: block;
  margin-bottom: 25px;
}

.dashboardScreen__chart__box {
  background-color: var(--white);
  border: 0.5px solid #70707040;
  min-height: auto !important;
  height: 100%;
  border-radius: 12px;
  margin-top: 10px;
  padding: 15px;
}

h4.dashboardScreen__chart__count {
  font-size: 50px;
  font-weight: 600;
  color: var(--darkBlue);
  letter-spacing: -1.3px;
}

.dashboardScreen__chart__box .dashboardScreen__chart {
  border-left: 1px solid var(--midGrey);
  border-bottom: 1px solid var(--midGrey);
}

.dash__para__text {
  font-size: 15px;
  color: var(--darkBlue);
  letter-spacing: -0.36px;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.dashboardScreen__chart__history {
  font-size: 18px;
  color: var(--midGrey);
}

.enterprise__progress {
  max-width: 200px;
}

.dashboardScreen_circular__box {
  background-color: var(--white);
  border: 0.5px solid #70707040;
  border-radius: 12px;
  margin-top: 12px;
  padding: 15px;
  min-height: auto !important;
  height: 100%;
}

.nopadd__left {
  padding-left: 0px !important;
}

.outlined__box {
  border: 0.5px solid #70707040;
  padding: 12px;
  border-radius: 12px;
  margin-top: 12px;
}

.outlined__box span {
  color: var(--darkBlue);
}

.outlined__box p {
  color: var(--darkBlue);
}

.edashboard__box span {
  font-size: 40px;
  font-weight: 300;
  letter-spacing: -0.88px;
  margin-bottom: 10px;
  line-height: 1;
}

.edashboard__box p {
  font-size: 18px;
  letter-spacing: -0.36px;
  font-weight: 600;
}

.edashboard__box .lightBlueBar__content__icon__image {
  transform: rotate(-45deg);
  max-width: 30px;
  position: absolute;
  top: 12px;
  right: 8px;
}

.filled__box {
  border: 0.5px solid #70707040;
  padding: 12px;
  border-radius: 12px;
  margin-top: 12px;
  background-color: var(--darkBlue);
  margin-bottom: 50px;
}

.filled__box span {
  color: var(--white);
}

.filled__box p {
  color: var(--white);
  font-size: 15px !important;
}

.dashboardScreen__plan__upgrade__button {
  color: var(--lightBlue2);
  box-shadow: none;
  background: none;
  border: 1px solid var(--lightBlue2);
  line-height: 40px;
  width: 230px;
  border-radius: 8px;
  text-align: center;
  margin-top: 25px;
}

.dashboardScreen__plan__upgrade__button:hover {
  background: var(--lightBlue2);
  color: #fff;
}


.dashboardScreen__plan__info {
  min-height: 232px;
  border: 0.5px solid #70707040;
  padding: 12px;
  border-radius: 12px;
  margin-top: 12px;
}

.dashboardScreen__plan_stat {
  font-size: 50px;
  color: var(--darkBlue);
}

.dashboardScreen__plan_stat span {
  font-weight: 400;
  letter-spacing: -1.3px;
}

.dashboardScreen__plan_stat .highlight__val {
  font-weight: 600;
}

.dashboardScreen__plan_title {
  font-size: 18px;
  color: var(--darkBlue);
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}

.dashboardScreen__plan_expiry {
  font-size: 18px;
  color: var(--midGrey);
  letter-spacing: -0.36px;
}

.dashboardScreen__chart img {
  max-width: 100%;
}

.dashboardScreen__chart__stats.dash__para__text {
  margin: 0px 0 -50px 10px;
  z-index: 2;
  position: relative;
  font-size: 15px !important;
}

.jobs__content__hiring__btn--button:hover {
  background: transparent;
  border: 1px solid var(--main);
  color: var(--main);
}

.dashboar__expiring__jobs__box .expiring__body {
  display: flex;
}

.dashboar__expiring__jobs__box .expiring__body_detail {
  background: #E3EBFF;
  display: flex;
  padding: 25px 15px 10px 15px;
  border-radius: 0px;
  justify-content: space-between;
  width: 100%;
}

.expiring__body_detail_image {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  background-color: #8741E6;
  margin-right: 13px;
}

.expiring__body_detail_image img {
  width: 25px;
}
.interview__box{
  background-color: #FDFDFD !important;
  border: 0.5px solid #ccc !important;
}
.offer__attached{
  background-color: #F4F4F4;
  padding: 12px;
}
.offer__attached .contract-icon{
  width: 30px;
}
.dashboar__expiring__jobs__box .expiring__body_detail img {
  max-width: 100%;
  margin-right: 10px;
}

.dashboar__expiring__jobs__box .expiring__body_detail .expiring__body_detail__info h4 {
  font-size: 17px;
}

.dashboar__expiring__jobs__box .expiring__body_detail .expiring__body_detail__info__cat {
  display: flex;
  font-size: 10px;
  justify-content: space-between;
  font-weight: 500;
  margin-bottom: 10px;
}

.dashboar__expiring__jobs__box .expiring__body_detail .expiring__body_detail__info__tags ul {
  list-style: none;
  padding: 0px;
  display: flex;
  font-size: 10px;
  color: #0d2159;
  margin: 0px;
}

.dashboar__expiring__jobs__box .expiring__body_detail .expiring__body_detail__info__tags ul li {
  margin-right: 10px;
  padding: 2px 5px;
  background: #bfd0ff;
  border-radius: 8px;
}


.dashboar__expiring__jobs__box .expiring__header h5 {
  /* font-size: 14px; */
}

.dashboar__expiring__jobs__box .expiring__header p {
  /* font-size: 11px; */
}

.dashboar__expiring__jobs__box {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 10px;
}

.dashboar__expiring__jobs__box .expiring__header {
  padding: 10px 0px 0px 10px;
}

.dashboar__expiring__jobs__box .expiring__body_detail .expiring__body_detail__info__tags {
  margin-bottom: 5px;
}


/* enterprise dashboard chart css */

.enterprise__chart {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 12px;
}

.enterprise__chart .enterprise__chart__header {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0px 15px;
}

.enterprise__chart__body button:first-child {
  margin-left: 15px;
}

.enterprise__chart .enterprise__chart__body__tabs {
  border-top: 1px solid #ccc;
  padding: 15px;
  display: flex;
}

.enterprise__chart__body button {
  color: #CBCBCB;
  background: none;
  font-size: 14px;
}

.enterprise__chart__body button.active {
  color: #5F89FF;
  position: relative;

}

.enterprise__chart__body__tabs__heading {
  font-size: 65px !important;
}

.enterprise__chart__body button.active:before {
  position: absolute;
  width: 80%;
  height: 4px;
  content: "";
  background: #486FD9;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.enterprise__chart__body__tabs__para {
  max-width: 160px;
  width: 100%;
}

.dash__graph {
  height: 230px;
}

.dash__graph__inner {
  height: 100%;
  border-left: 0.5px solid #E6E6E6;
  border-bottom: 0.5px solid #E6E6E6;
  
}

.dash__graph__bar {
  width: 64px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.chart__filter__box {
  display: flex;
}

.chart__filter__box select {
  margin-right: 10px;
  border-radius: 8px;
  min-width: 100px;
  font-size: 12px;
  padding: 4px 5px;
  outline: none;
  appearance: auto;
  border: 1px solid #70707080;
}



.enterprise__chart__body__tabs .enterprise__chart__body__info {
  width: 20%;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.enterprise__chart__body__tabs .enterprise__chart__body__info__box {
  width: 80%;
}

.enterprise__chart__body__tabs .enterprise__chart__body__info h3 {
  color: #0d2159;
}

.enterprise__chart__body__tabs .enterprise__chart__body__info p {
  font-size: 12px;
}

.dash__del__text {
  max-width: 650px;
}

@media only screen and (max-width: 1400px) {
  .dashboardScreen__content__hiring__heading {
    font-size: 35px;
  }

  .dashboardScreen__content__hiring__btn {
    max-width: 250px;
  }

  .enterprise.dashboardScreen_circular__box .dash__para__text.text-center {
    max-width: 90%;
    margin: auto;
  }

  .dashboardScreen__content__hiring {
    min-height: auto;
  }

  .dash__graph__bar {
    width: 54px;
  }

}

@media only screen and (max-width: 1200px) {
  .dashboardScreen__content__hiring__heading {
    font-size: 30px;
  }

  .dashboardScreen__content__hiring__btn {
    max-width: 200px;
  }

  .dashboardScreen__content__hiring__btn--button {
    font-size: 14px;
  }

  .dash__graph__bar {
    width: 44px;
  }
  .dash__graph{
    overflow-x: auto;
  }
  .dash__graph__inner {
    min-width: 860px;
  }
}

@media only screen and (max-width: 992px) {
  .dashboardScreen__content__hiring__heading {
    font-size: 25px;
  }

  .dashboardScreen__content__hiring__btn {
    max-width: 150px;
  }

  .dashboardScreen__content__hiring__btn--button {
    font-size: 12px;
  }

  .dash__graph__bar {
    width: 44px;
  }
}

.filter__content__link__heading__grey {
  color: var(--lightGrey) !important;
  letter-spacing: -0.48px !important;
}

.filter__content__link__heading__grey__icon {
  color: var(--lightGrey) !important;
}

.jobs__filter__header {
  font-size: 16px;
}

.jobs__filter__li {
  font-size: 14px;
}

.dashboardScreen_circular__box .CircularProgressbar .CircularProgressbar-path {
  stroke: var(--main);
}

@media only screen and (max-width: 768px) {
  .dashboardScreen__chart img {
    max-width: 300px !important;
  }

  .filled__box {
    margin-bottom: 0px;
  }

  .enterprise__chart .enterprise__chart__body__tabs {
    display: block;
  }

  .enterprise__chart__body__tabs .enterprise__chart__body__info {
    width: 100%;
  }

  .enterprise__chart__body__tabs .enterprise__chart__body__info__box {
    width: 100%;
  }
}

option {
  padding: 10px !important;
}

@media only screen and (max-width: 576px) {
  .dashboardScreen__content__hiring__btn--button {
    max-width: none !important;
    width: 100% !important;
  }

  .expiring__body_detail_image {
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    margin-right: 10px;
  }

  .dash__account_image {
    width: 50px;
    height: 60px;
    margin-right: 10px;
  }

  .dashboar__expiring__jobs__box .expiring__body_detail {
    padding: 18px 15px 5px 15px;
  }

  .dash__graph__bar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .dash__avatar {
    min-width: 75px;
    min-height: 75px;
    width: 75px;
    height: 75px;
  }

}