.ps1 {
    background: radial-gradient(circle, rgba(250, 253, 246, 1) 0%, rgba(250, 253, 246, 1) 27%, rgba(255, 255, 255, 1) 95%, rgba(250, 253, 246, 1) 100%);
}

.ps1__right {
    height: 360px;
}

.ps1__right img {
    width: auto;
}

.ps4__text {
    max-width: 730px;
    width: 100%;
}

.ps1__features {
    background-color: var(--darkBlue);
    border-radius: 5px;
    max-width: 380px;
    width: 100%;
    min-height: 360px;
}

.ps1__heading {
    font-size: 60px;
    line-height: 65px;
}

.text-13 {
    font-size: 13px;
}

.ps1__btn {
    height: 40px;
    border-radius: 5px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(119deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 35%, rgb(255, 255, 255) 75%, rgb(254, 254, 254) 100%);
}

.ps1__btn:hover {
    color: var(--darkBlue) !important;
    background: linear-gradient(119deg, rgba(244, 252, 231, 1) 0%, rgba(252, 255, 251, 1) 35%, rgba(234, 251, 249, 1) 75%, rgba(244, 252, 231, 1) 100%);
}

.ps1--img1 {
    width: 450px;
    left: 100px;
    top: -40px;
    z-index: 1;
}

.ps1--img2 {
    width: 450px;
    left: 0px;
    top: 130px;
}

.ps1--img3 {
    width: 370px;
    left: 340px;
    top: 50px;
}

.ps1--img4 {
    width: 330px;
    left: 270px;
    top: 90px;
    z-index: 2;
}

.ps3 {
    background: radial-gradient(circle, rgba(250, 253, 246, 1) 0%, rgba(250, 253, 246, 1) 27%, rgba(255, 255, 255, 1) 95%, rgba(250, 253, 246, 1) 100%);
}

.ps3--img {
    width: 60%;
}

.ps3__content {
    width: 400px;
}

.ps3__h1 {
    color: #0D215980;
}

.ps3__top {
    position: absolute;
}

@media only screen and (max-width: 1400px) {
    .ps1__right img {
        width: auto;
    }
}

@media only screen and (max-width: 1200px) {
    .ps1__right {
        height: auto;
    }

    .ps1__right img {
        width: 100%;
    }

    .ps3--img {
        width: 50%;
    }


    .ps3 {
        min-height: 600px;
    }
}

@media only screen and (max-width: 992px) {
    .ps1__features {
        max-width: 100%;
    }

    .ps1__heading {
        font-size: 55px;
        line-height: 60px;
    }

    .ps3__content {
        width: 50%;
    }

    .ps3__top {
        position: static;
    }

    .ps3--img {
        width: 80%;
        margin-right: -30px;
    }

    .ps3__content {
        max-width: 600px;
        width: 100%;
    }

    .ps3 {
        min-height: 600px;
    }
}

@media only screen and (max-width: 768px) {
    .ps1__features{
        min-height: auto;
    }
    .ps1__heading {
        font-size: 50px;
        line-height: 55px;
    }

    .ps1--img1 {
        width: 400px;
        left: 19px;
        top: -40px;
        z-index: 1;
    }

    .ps1--img2 {
        width: 400px;
        left: -50px;
        top: 111px;
    }

    .ps1--img3 {
        width: 320px;
        left: 253px;
        top: 35px;
    }

    .ps1--img4 {
        width: 280px;
        left: 170px;
        top: 70px;
        z-index: 2;
    }

    .ps3--img {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {

    .ps1__heading {
        font-size: 40px;
        line-height: 45px;
    }

    .ps1--img1 {
        width: 350px;
        left: 19px;
        top: -40px;
        z-index: 1;
    }

    .ps1--img2 {
        width: 350px;
        left: -50px;
        top: 92px;
    }

    .ps1--img3 {
        width: 320px;
        left: 208px;
        top: 15px;
    }

    .ps1--img4 {
        width: 280px;
        left: 150px;
        top: 50px;
        z-index: 2;
    }
}

@media only screen and (max-width: 500px) {

    .ps1__heading {
        font-size: 35px;
        line-height: 40px;
    }

    .ps1--img1 {
        width: 300px;
        left: 25px;
        top: -40px;
        z-index: 1;
    }

    .ps1--img2 {
        width: 300px;
        left: -40px;
        top: 73px;
    }

    .ps1--img3 {
        width: 280px;
        left: 180px;
        top: 5px;
    }

    .ps1--img4 {
        width: 250px;
        left: 135px;
        top: 35px;
        z-index: 2;
    }
}

@media only screen and (max-width: 430px) {

    .ps1__heading {
        font-size: 30px;
        line-height: 35px;
    }

    .ps1--img1 {
        width: 200px;
        left: 35px;
        top: -40px;
        z-index: 1;
    }

    .ps1--img2 {
        width: 200px;
        left: -10px;
        top: 33px;
    }

    .ps1--img3 {
        width: 180px;
        left: 137px;
        top: -10px;
    }

    .ps1--img4 {
        width: 150px;
        left: 110px;
        top: 13px;
        z-index: 2;
    }
}

@media only screen and (max-width: 350px) {

    .ps1__heading {
        font-size: 25px;
        line-height: 25px;
    }
}

.ps2 {
    background-color: #F9F8F6;
}

.ps2 .accordion-button:focus {
    background-color: #F9F8F6 !important;
}

.ps2 .accordion-button {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: #F9F8F6 !important;
}

.ps2 .accordion-body {
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: #F9F8F6 !important;
}

.ps2 .accordion-button:not(.collapsed) {
    color: var(--darkGrey) !important;
    background-color: #F9F8F6 !important;
    box-shadow: none !important;
}

.ps2 .accordion {
    --bs-accordion-border-color: #F9F8F6 !important;
}

.ps2 .accordion-button::after {
    flex-shrink: 0;
    width: 25px !important;
    height: 25px !important;
    display: flex !important;
    justify-content: center !important;
    margin-left: auto;
    content: "" !important;
    background-image: url('../../../public/assets/images/arrow_dark.svg') !important;
    background-position: center;
    background-size: 20px;
    border: 0px solid #4A4A49;
    border-radius: 0%;
    text-align: center;
    font-weight: 100;
    transform: rotate(90deg);
}

.ps2 .accordion-button:not(.collapsed)::after {
    content: "" !important;
    line-height: 0px !important;
    font-weight: 200;
    background-color: transparent !important;
    color: var(--white);
    border: 0px solid;
    transform: rotate(270deg);
}

.ps2 .accordion-item:first-of-type .accordion-button {
    border-radius: 0px !important;
    border-top: 0px solid #dee2e6 !important;
}

.ps2__para {
    max-width: 660px;
}


.ps4__top {
    background: #E6E6E6;
}