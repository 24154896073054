.YDetail__button {
  max-width: 450px !important;
  width: 100%;
}
.YDetail__heading {
  color: var(--darkGrey);
  font-family: var(--fontFamily);
}
.YDetail__top__avatar {
  min-width: 80px;
  min-height: 80px;
}
.YDetail__top__menu {
  background: var(--lightGrey);
  border-radius: 20px;
  cursor: pointer;
}
.YDetail__top__menu__icon {
  color: var(--black);
}

.Dashboard__container {
  max-width: 1366px !important;
}
.remove__values__benefits {
  background: transparent;
  height: auto;
  line-height: normal;
}


.add__vb__points__inputs {
  height: 40px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #999;
}

.add__vb__points {
  background: transparent;
  padding: 0px;
  border-radius: 0px !important;
  height: 40px;
  width: 50px;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.custom-class {
  word-wrap: break-word;
}

@media only screen and (max-width: 1500px) {
  .Dashboard__container {
    max-width: 1020px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .Dashboard__container {
    max-width: 900px !important;
  }
}