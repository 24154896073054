.feedback-container {
    max-width: 700px;
    width: 100%;
}

.feedback-container .ant-checkbox .ant-checkbox-input {
    background-color: transparent !important;
}

.feedback-container .ant-picker {
    background-color: transparent !important;
    color: white !important;
    border: 0.5px solid #96969678 !important;
}

.feedback-container .ant-picker-focused {
    border-color: var(--main) !important;
}

.feedback-container .ant-picker .ant-picker-input>input {
    color: white !important;
}

.feedback-container .ant-picker .ant-picker-input>input::placeholder {
    color: #969696 !important;
}
.feedback__button {
    border-color: var(--white) !important;
    background-color: var(--white) !important;
    border-radius: 25px !important;
    color: var(--black) !important;
    font-weight: 300;
    font-size: 15px !important;
    max-width: 400px;
}

.feedback__button:hover {
    transition: all 0.3s ease-in-out;
    background-color: transparent !important;
    color: var(--white) !important;
}

.submitted--img{
    height: 400px !important;
}
.submitted__para{
    max-width: 620px;
}
.submitted--btn{
    border-radius: 25px;
    background: #A6DD50;
    color: black;
    height: 44px;
    width: 200px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -22px;
}
.submitted--btn:hover{
    color: white;
    background-color: #436C03;
    font-weight: 400;
}
@media only screen and (max-width: 576px){
    .submitted--btn{
        height: 40px;
        width: 150px;
        position: static;
        transform: translateX(0);
    }
}