.API__row__left {
  max-width: 600px;
}
.API__row__left--img {
  min-width: 140px;
  max-width: 140px;
  background: #F9F9F9;
  border-radius: 4px;
}


.api__boxes {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

.api__boxes h4{
  font-size: 18px;
}
.api__boxes .api__box__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.connect__button.connected {
  border: 1px solid #A6DD4F;
  color: #A6DD4F;
  background: #fff;
  border-radius: 8px;
  font-size: 14px;
  padding: 4px 12px;
}

.connect__button.pending {
  border: 1px solid #70707080;
  background: #fff;
  border-radius: 8px;
  font-size: 14px;
  padding: 4px 12px;
}

.api__boxes .api__box__header img {
  width: 45px;
}
.kyc__img{
  width: 55px !important;
}
.ant-select-selector .ant-select-selection-search-input, .form-control{
  font-size: 16px !important;
}
.disabled-box{
  position: relative;
}
.disabled-box .api__boxes{
  opacity: 0.5; /* or any other desired opacity */
  pointer-events: none; /* disable pointer events */  
}

.disabled-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:rgb(191 188 188 / 80%); /* semi-transparent white background */
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 80%; /* adjust as needed */
  max-width: 300px; /* adjust as needed */
}



@media only screen and (max-width: 1200px) {
  .API__row__left {
    max-width: 510px;
  }
  .API__row__left--img {
    min-width: 120px;
    max-width: 120px;
  }
}
@media only screen and (max-width: 768px) {
  .API__row__left {
    max-width: 100%;
  }
}