.mobile-sidebar {
  top: 72px !important;
  width: 100% !important;
  background: var(--darkBlue) !important;
}
.mobile-sidebar-body {
  padding: 0px !important;
}
.mobile-sidebar-backdrop {
  display: none !important;
}
.mobile-sidebar__list__item--Link {
  font-size: 20px;
  color: var(--white);
  font-weight: semibold;
  font-family: "Open Sans";
}
.mobile-sidebar__list__item--Link:hover {
  color: var(--main) !important;
}
.mobile-sidebar__footer {
  border-top: 1px solid #494F4F !important;
}
.mobile-sidebar__footer__button1 {
  border-right: 1px solid #494F4F !important;
}
.mobile-sidebar__footer__button--Link {
  font-size: 20px;
  color: var(--white);
  font-weight: semibold;
  font-family: "Open Sans";
  transition: all 0.3s ease-in-out;
}
.mobile-sidebar__footer__button--Link:hover {
  color: var(--main) !important;
}

@media only screen and (max-width: 400px) {
  .mobile-sidebar {
    top: 62px !important;
  }
}