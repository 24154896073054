.footer {
  background-color: var(--black);
  border-bottom: 2px solid var(--main);
  font-weight: 300;
}

.footer__container {
  position: relative;
}
.noselect{
  user-select: none !important;
}
.footer__scrollToTop {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
  color: var(--white);
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  font-size: 44px;
}

.footer__scrollToTop:hover {
  color: var(--white);
  opacity: 1;
}

.footer__icons {
  color: var(--white);
  font-size: 26px;
  transition: all 0.3s ease-in-out;
}

.footer__icons a:hover svg {
  fill: var(--main);
  transition: all 0.3s ease-in-out;
}

.footer__greenBar {
  background-color: var(--main);
  padding: 1.5px 0;
}

.footer__text1__link {
  font-family: var(--fontFamily);
  font-size: 14px;
  text-decoration: none;
  color: var(--white);
  transition: all 0.3s ease-in-out;
}

.footer__text1__link:hover {
  color: var(--main) !important;
}

.footer__text3 {
  font-family: var(--fontFamily);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}

.twitter {
  font-size: 32px;
}

.linkedin {
  font-size: 28px;
}

.instagram {
  font-size: 30px;
}

.footer__heading_address {
  font-size: 18px;
  color: var(--main);
}

@media only screen and (max-width: 576px) {
  .footer {
    border-bottom: 4px solid var(--main);
  }

  .footer__text3 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer {
    border-bottom: 4px solid var(--main);
  }

  .footer__text1__link {
    font-size: 15px;
  }

  .footer__text3 {
    font-size: 14px;
  }
}