.smodal .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
    position: absolute !important;
    background: url('../../../public/assets/images/feedbackarrow.svg') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    z-index: 9;
    right: 0;
}

.smodal .ant-modal .ant-modal-footer {
    display: none !important;
    opacity: 0 !important;
    transition: all 3s ease-in-out !important;
}

.smodal {
    overflow: hidden !important;
}
.smodal .ant-modal-root .ant-modal-mask{
    display: none !important;
}
.smodal .ant-modal{
    position: relative !important;
}
.smodal__text {
    max-width: 400px;
}
.smodal__text2 {
    max-width: 510px;
}

.smodal .ant-modal-close-x {
    display: none !important;
}

.Get__right__button--muted {
    background-color: #CBCBCB;
}

.Get__right__button--muted:hover {
    background-color: #a1a1a1;
}



@media only screen and (min-width: 1200px) {
    .smodal .ant-modal {
        max-width: 600px !important;
        width: 100% !important;
    }
}