.bookdemo__content__heading {
  font-family: var(--fontFamily);
  font-size: 50px;
  font-weight: 300;
  line-height: 50px;
  color: var(--main);
}

.demobook {
  min-height: 70vh;
}
.demo-text{
  max-width: 600px;
  width: 100%;
}
.book-demo-page {
  min-height: 100vh;
  background: rgb(61, 82, 31);
  background: linear-gradient(212deg, rgba(61, 82, 31, 1) 0%, rgba(0, 0, 0, 1) 22%, rgba(3, 9, 23, 1) 77%, rgba(10, 27, 73, 1) 100%);
  border-bottom: 1px solid #707070;
}

.book-demo-page .footer {
  background: transparent;
}

.bookdemo__content__para {
  font-family: var(--fontFamily);
  font-size: 22px;
  font-weight: normal;
  line-height: 30px;
  color: var(--white);
  letter-spacing: 0px;
  margin-bottom: 0px !important;
  max-width: 860px;
}

.bookdemo__content__para--span {
  color: var(--main);
}

.bookdemo__content__image {
  max-width: 730px;
  width: 100%;
}

.bookdemo__content__image--img {
  width: 100%;
}

.calendly-box iframe {
  width: 1010px !important;
  height: 775px !important;
  border-radius: 10px !important;
  margin-left: -30px !important;
}

.calendly-box .calendly-inline-widget {
  min-height: 775px !important;
  height: auto !important;
}

@media only screen and (max-width: 1400px) {
  .bookdemo__content__heading {
    font-size: 45px;
  }

  .bookdemo__content__para {
    font-size: 22px;
  }

  .calendly-box iframe {
    width: 1010px !important;
    margin-left: -120px !important;
  }

  .calendly-box .calendly-inline-widget {
    min-height: 775px !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 1200px) {
  .bookdemo__content__heading {
    font-size: 40px;
  }

  .bookdemo__content__para {
    font-size: 18px;
    line-height: 22px;
  }

  .calendly-box iframe {
    width: 1010px !important;
    height: 775px !important;
    border-radius: 10px !important;
    margin-left: 0px !important;
  }

  .calendly-box .calendly-inline-widget {
    min-height: 775px !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 992px) {
  .bookdemo__content__heading {
    font-size: 35px;
  }

  .bookdemo__content__para {
    font-size: 16px;
    line-height: 20px;
  }

  .calendly-box iframe {
    width: 100% !important;
    height: 925px !important;
  }

  .calendly-box .calendly-inline-widget {
    min-height: 765px !important;
    height: auto !important;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .bookdemo__content__heading {
    font-size: 30px;
  }

  .bookdemo__content__para {
    font-size: 16px;
    line-height: 20px;
  }

  .bookdemo__content__image--img {
    max-width: 400px;
  }

  .calendly-box iframe {
    width: 100% !important;
    height: 920px !important;
  }
}

@media only screen and (max-width: 709px) {
  .bookdemo__content__heading {
    font-size: 30px;
  }

  .bookdemo__content__para {
    font-size: 16px;
    line-height: 20px;
  }

  .bookdemo__content__image--img {
    max-width: 400px;
  }

  .calendly-box iframe {
    width: 100% !important;
    height: 775px !important;
    border-radius: 10px !important;
    margin-top: 40px !important;
  }
}

@media only screen and (max-width: 576px) {
  .bookdemo__content__heading {
    font-size: 25px;
  }

  .bookdemo__content__para {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px !important;
  }

  .bookdemo__content__image--img {
    max-width: 350px;
  }
}