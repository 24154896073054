.Navbar {
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1001;
  height: 115px;
  transition: all 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.Navbar__container {
  position: relative;
  height: 100%;
}
.Navbar__container__logo--img {
  max-width: 210px;
  width: 100%;
  text-decoration: none;
}
.check {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -6px;
  height: 50px;
  width: 50px;
  opacity: 0;
  z-index: 999;
}

.check:hover {
  cursor: pointer;
}

.ham-menu {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -6px;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 0.25em;
  display: flex;
}

.ham-menu .line {
  background-color: white;
  border-radius: 1em;
  width: 35px;
  height: 2px;
}
.ham-menu .line--black {
  background-color: black !important;
}
.ham-menu .line--white {
  background-color: white !important;
}
.ham-menu .line--blue {
  background-color: var(--darkBlue) !important;
}
.ham-menu .line1 {
  transform-origin: 0% 0%;
  transition: transform 100ms ease-in-out;
}

.ham-menu .line3 {
  width: 19.5px;
  transform-origin: 0% 100%;
  transition: transform 100ms ease-in-out;
}
.check:checked ~ .ham-menu .line1 {
  display: block;
  width: 35px;
  transform: rotate(40deg);
}
.check:checked ~ .ham-menu .line2 {
  opacity: 0;
}
.check:checked ~ .ham-menu .line3 {
  display: block;
  transform: rotate(-40deg);
  width: 35px !important;
}
a.btn.btn-secondary.dropdown-toggle.show{
  color:#fff !important;
}

@media only screen and (max-width: 1400px) {
  .Navbar {
    height: 90px;
  }
  .desktop-offcanvas{
    top: 90px !important;
  }
  .Navbar__container__logo--img {
    max-width: 210px;
  }
}
@media only screen and (max-width: 992px) {
  .Navbar {
    height: 75px;
  }
  .desktop-offcanvas{
    top: 75px !important;
  }
  .Navbar__container__logo--img {
    max-width: 210px;
  }
}
@media only screen and (max-width: 576px) {
  .Navbar {
    height: 72px;
  }
  .Navbar__container__logo--img {
    max-width: 190px;
  }
}
@media only screen and (max-width: 400px) {
  .Navbar {
    height: 62px;
  }
  .Navbar__container__logo--img {
    max-width: 150px;
  }
  .check {
    right: -10px;
  }
  .ham-menu{
    right: -10px;
  }
}