.Signup__right {
  max-width: 850px;
}
.Signup__para {
  color: var(--darkGrey);
}
.Signup__heading {
  color: var(--darkBlue);
}

@media only screen and (max-width: 1400px) {
  .Signup__right {
    max-width: 730px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .Signup__right {
    max-width: 600px !important;
  }
}