.signIn {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
  background: linear-gradient(21deg, rgba(178,218,102,1) 0%, rgba(61,80,32,1) 0%, rgba(0,0,0,1) 47%, rgba(13,33,89,1) 100%);

}
.vh-80{
  min-height: 70vh;
}
.signIn .footer{
  background: #00000000;
}
.signinPage .footer{
  background-color: transparent !important;
}
.signIn__hero--img {
  position: absolute;
  top: 0;
  right: -392px;
  width: 68%;
  z-index: -1;
}
.signIn__heading {
  font-family: var(--fontFamily);
  font-size: 36px;
  font-weight: 300;
  color: var(--main);
  text-align: center;
}
.signIn__form--input.form-control {
  background:rgb(255 255 255 / 15%) !important;
  border: 0px solid var(--inputBorder) !important;
  outline: none !important;
  border-radius: 25px !important;
  color: var(--white) !important;
  font-size: 15px;
}
.signIn__form--input:focus {
  border-color: var(--main) !important;
  box-shadow: none !important;
}
.signIn__form--input__checkBox.form-check-input {
  box-shadow: none !important;
  border-radius: 50% !important;
  border:1px solid #fff;
  background-color: transparent  !important;
}
.signIn__form--input__checkBox.form-check-input:checked,.signIn__form--input__checkBox.form-check-input:focus{
  border-color: var(--main);
}
.signIn__form--input__checkBox--label {
  font-size: 16px !important;
}
.signIn__form__forgot {
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: 300;
}
.signIn__form__forgot {
  color: var(--white) !important;
}
.signIn__form__forgot:hover {
  color: var(--main) !important;
}
.signIn__form__button {
  border-color: var(--white) !important;
  background-color: var(--white) !important;
  border-radius: 25px !important;
  color:var(--black) !important;
  font-weight: 300;
  font-size: 15px !important;
}
.signIn__form__button:hover {
  transition: all 0.3s ease-in-out;
  background-color: transparent !important;
  color: var(--white) !important;
}
.signIn label {
  font-family: var(--fontFamily);
  font-size: 16px;
  line-height: 27px;
  font-weight: 300;
  color: var(--white);
}
.signIn__account--a {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: 300;
  color: var(--midGrey);
  width: 100%;
  justify-content: center;
}

.signIn__form--input__checkBox.form-check-input:checked[type=checkbox] {
  position: relative;
}

.signIn__form--input__checkBox.form-check-input:checked[type=checkbox]:before {
  position: absolute;
  content: "";
  background-color: var(--main) !important;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: 1.7px;
  top: 1.5px;
}


.signIn__account--a:hover {
  color: var(--main);
  transition: all 0.3s ease-in-out;
}
.signIn__account__icon {
  font-size: 28px;
}
.signIn__image {
  margin-bottom: -180px;
}
.signIn__image--img {
  width: 100%;
}
@media only screen and (max-width: 1400px) {
  .signIn__heading {
    font-size: 36px;
  }
  .signIn label {
    font-size: 16px;
  }
  .signIn__account--a {
    font-size: 14px;
  }
  .signIn__hero--img {
    right: -273px;
    width: 68%;
  }
}
@media only screen and (max-width: 1200px) {
  .signIn__heading {
    font-size: 36px;
  }
  .signIn label {
    font-size: 16px;
  }
  .signIn__account--a {
    font-size: 14px;
  }
  .signIn__hero--img {
    right: -237px;
    top: 90px;
    width: 70%;
  }
}
@media only screen and (max-width: 992px) {
  .signIn {
    min-height: auto;
  }
  .signinPage{
    background: rgb(166,221,80);
    background: linear-gradient(0deg, rgba(166,221,80,1) 0%, rgba(166,221,80,1) 10%, rgba(166,221,80,1) 13%, rgba(0,0,0,1) 60%, rgba(0,0,0,1) 100%);
  }
  .signIn__heading {
    font-size: 36px;
  }
  .signIn label {
    font-size: 16px;
  }
  .signIn__account--a {
    font-size: 14px;
    text-align: center;
  }
  .signIn__hero--img {
    right: -174px;
    top: 113px;
  }
}
@media only screen and (max-width: 768px) {
  .signIn__heading {
    font-size: 30px;
  }
}
@media only screen and (max-width: 576px){
  .signIn__label{
    font-size: 14px !important;
  }
}