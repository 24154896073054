.chat__page {
    padding-top: 130px !important;
}

.chat__wrapper {
    display: flex;
    background: #f8f9fb;
}


.chat__sidebar {
    width: 320px;
    background: var(--aside-bg);
    height: calc(100dvh - 73);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    flex-shrink: 0;
    overflow: hidden;
    border-right: 1px solid #dbeafe;
    background: #0d2159;
    padding-top: 0px;
}

.departments .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
    background: var(--darkGrey) !important;
    color: #fff !important;
}

.departments .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after {
    color: #fff !important;
}

.chat__sidebar__tabs {
    display: flex;
    padding: 0px;
    text-align: center;
    font-size: 20px;
    align-items: center;
}

.chat__sidebar__tabs>li {
    width: 50%;
    font-size: 22px;
    color: #fff;
    border-radius: 0px;
    line-height: 22px;
}

.chat__sidebar__tabs>li.react-tabs__tab--selected {
    color: #0d2159;
}

.chat__sidebar__tabs>li.react-tabs__tab--selected:after {
    display: none;
}

.chat-aside-list {
    overflow-y: scroll !important;
}

.chat-aside-list::-webkit-scrollbar {
    width: 12px !important;
}

.chat-aside-list::-webkit-scrollbar-track {
    background: #0d2159 !important;
}

.chat-aside-list::-webkit-scrollbar-thumb {
    background-color: white !important;
    border-radius: 20px !important;
    border: 3px solid #0d2159 !important;
}

@media (min-width: 768px) {
    .chat__sidebar {
        /* position: fixed; */
        left: 0;
        top: 73px;
        z-index: 1;
    }

}


.chat__convo__list__group {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.chat__convo {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.chat__convo__image {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 38px;
    width: 38px;
    border-radius: var(--tyn-shape);
    background: var(--media-bg);
}

.chat__convo__image img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.chat__convo__detail .chat__convo__detail_row h6,
.chat__convo__detail .chat__convo__detail_row p {
    margin: 0px;
}

.chat__convo__list {
    padding: 12px 24px;
    /* border-bottom: 1px solid #dddfff; */
}


.chat__convo__detail .chat__convo__detail_row {
    display: flex;
    /* gap: 1rem; */
    color: #fff;
    flex-direction: column;
    align-items: baseline;
}

.chat__convo__detail .chat__convo__detail_row h6 {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
}

.chat__convo__detail .chat__convo__detail_row p {
    font-size: 0.75rem;
    color: #fff;
    margin-bottom: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat__convo__detail .chat__convo__detail_row .meta {
    font-size: 0.625rem;
}

.chat-aside-list {
    /* overflow: hidden scroll; */
    direction: inherit;
    height: calc(100vh - 200px);
    padding-bottom: 30px;
}

.chat__convo__list:hover {
    background: #04237a;
    cursor: pointer;
}



.chat__sidebar .tyn-aside-search .form-control-wrap {
    position: relative;
}

.chat__sidebar .tyn-aside-search .form-control-wrap .form-control-icon.start {
    position: absolute;
    top: 5px;
    left: 13px;
    color: #fff;
}

.chat__sidebar .tyn-aside-search .form-control-wrap input {
    border-radius: 30px;
    font-size: 12px;
    padding-left: 35px;
    height: 40px;
    padding-top: 10px;
}

.chat__sidebar .tyn-aside-search {
    padding: 0px 20px 10px;
}

.chat__sidebar h3 {
    padding-left: 20px;
}





/* chat detail */


.chat-container {
    /* max-width: 400px; */
    margin: 20px auto;


}

.message {
    margin-bottom: 10px;
    overflow: hidden;
}

.my-message {
    float: right;
    clear: both;
}

.message__time {
    font-size: 10px;
}

.my-message .message-content {
    background-color: var(--main);
    color: white;
    border-radius: 10px 10px 0 10px;

}

.my-message .message__time {
    text-align: right;
}

.incoming-message {
    float: left;
    clear: both;
}

.incoming-message .message-content {
    background-color: #eaeaea;
    color: black;
    border-radius: 10px 10px 10px 0;

}

.incoming-message .message-content p {
    margin: 0px;
}

.incoming-message .message__time {
    text-align: left;
}

.message-content {
    padding: 10px;
}








.chat__center__box {
    width: calc(100% - 340px);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    max-width: 100%;
    flex-grow: 1;
}

.chat__header {
    padding: 10px 20px;
    background: #eaeaea;
    border-bottom: 1px solid #ddd;
    box-shadow: -11px 10px 50px rgba(0, 0, 0, 0.2);

}

.chat__header .chat__header__info {
    display: flex;
    align-items: center;
}

.chat__header .chat__header__info img {
    width: 38px;
    height: 38px;
    margin-right: 20px;
    border-radius: 50%;
}

.chat__content__scrollable {
    background: #f8f9fb;
    height: calc(100vh - 260px);
    padding: 20px;
    overflow-y: auto;
}

.chat__content__scrollable::-webkit-scrollbar {
    width: 8px;
}

.chat__content__scrollable::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.chat__content__scrollable::-webkit-scrollbar-track {
    background-color: #f8f9fb;
}

.chat__content__scrollable {
    scrollbar-width: thin;
    scrollbar-color: #888 #f8f9fb;
}

.chat__sending__form .chat__sending__form__inner {
    display: flex;
    padding: 20px 25px;
    background: #ebebeb;
}

.chat__sending__form .chat__sending__form__inner input {
    width: 100%;
    height: 40px;
    box-shadow: none;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 30px;
    font-size: 12px;
}

.chat__sending__form .chat__sending__form__inner button {
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: #0d2159;
    border-radius: 50%;
    color: #fff;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.chat__info__box {
    width: 300px;
    background-color: #fff;
}

.customHeight {
    line-height: 12px;
}

.chat__info__box .chat__info__box__top {
    text-align: center;
    padding: 20px;
}

.chat__info__box .chat__info__box__top .chat__info__box__top__image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
}

.chat__info__box .chat__info__box__top .chat__info__box__top__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat__info__box .chat__info__box__team ul {
    list-style: none;
    padding: 0px;

}

.chat__info__box .chat__info__box__team ul li {
    display: inline-block;
    margin-right: 5px;
    text-align: center;
}

.chat__info__box .chat__info__box__team ul li img {
    max-width: 24px;
    margin: auto;
}

.chat__info__box .chat__info__box__team h3 {
    font-size: 20px;
    margin-bottom: 15px;
}

.chat__info__box .chat__info__box__team {
    padding: 20px;
    margin-top: 50px;
}

.chat__info__box .chat__info__box__team ul li p {
    font-size: 12px;
}

.chat__info__details {
    margin-top: 20px;
}

.chat__info__details .chat__info__details__info {
    display: flex;
    margin-left: 20px;
}

.chat__info__details .chat__info__details__info img {
    margin-right: 10px;
    height: 20px;
    margin-bottom: 5px;
}

.chat__info__details {
    margin-top: 20px;
}

.chat__info__details .chat__info__details__info {
    display: flex;
    margin-left: 20px;
}

.chat__info__details .chat__info__details__info img {
    margin-right: 10px;
    height: 20px;
    margin-bottom: 5px;
}

.chats__header__icons ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}

.chats__header__icons ul li {
    color: #fff;
    text-align: center;
    cursor: pointer;
}

.chats__header__icons ul li:hover {
    color: var(--main);
}

.chats__header__icons ul li svg {
    font-size: 26px;
}

.chats__header__icons ul li.single-chat svg {
    font-size: 22px;
}

.chats__header__icons ul li.files-icon svg {
    font-size: 20px;
}

.chats__header__icons {
    margin-bottom: 10px;
    border-bottom: 1px solid #cccccc45;
    padding-bottom: 15px;
}