.es1__title1, .es1__title2, .es1__title3{
    max-width: 230px;
}
.es1__title4, .es1__title5, .es1__title6{
    max-width: 300px;
}
.lightBlueBar{
  background-color: #12D3DB !important;
}
.darkBlueBar{
  background-color: var(--darkBlue) !important;
}
.lightGreenBar{
  background-color: #A6DD50 !important;
  color: #436C03 !important;
}
.darkGreenBar{
  background-color: #436C03 !important;}

.teal{
    color: #12D3DB !important;
}
.es1__text{
    max-width: 830px;
}
.bg-lightGrey{
    background-color: #F9F9F9;
}
.es1__companyimg1{
    max-width: 200px;
    width: 100%;
}
.es1__companyimg2{
    max-width: 150px;
    width: 100%;
}
.es1__companyimg3{
    max-width: 200px;
    width: 100%;
}
.es1__companyimg4{
    max-width: 160px;
    width: 100%;
}
.es1__companyimg5{
    max-width: 200px;
    width: 100%;
}
.traditional__content {
    max-width: 1000px;
  }
  .traditional__content__heading {
    color: var(--darkBlue);
    font-weight: semibold;
    line-height: 27px;
    font-size: 18px;
    font-family: var(--fontFamily);
  }
  .traditional__content__para {
    color: var(--darkGrey);
    font-family: var(--fontFamily);
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: -0.5px;
  }
  .traditional__border {
    border-bottom: 1px solid var(--lightGrey);
  }
  
  .calendly-box .calendly-inline-widget{
    height: 750px !important;
  }
  
  .calendly-box iframe {
    width: 100%;
    height: 750px;
  }
  
  @media only screen and (max-width: 1400px) {
    .traditional__content__heading {
      font-size: 18px;
      line-height: 26px;
    }
    .traditional__content__para {
      font-size: 18px;
      line-height: 23px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .traditional__content__heading {
      font-size: 16px;
      line-height: 24px;
    }
    .traditional__content__para {
      font-size: 16px;
      line-height: 24px;
    }
    .es1__companyimg1{
        max-width: 160px;
    }
    .es1__companyimg2{
        max-width: 120px;
    }
    .es1__companyimg3{
        max-width: 160px;
    }
    .es1__companyimg4{
        max-width: 120px;
    }
    .es1__companyimg5{
        max-width: 200px;
    }
  }
  .features__header {
    font-family: var(--fontFamily);
    color: var(--blue);
    font-size: 50px;
    line-height: 55px;
    font-weight: 300;
  }
  .features__header2 {
    font-family: var(--fontFamily);
    color: var(--lightBlue2);
    font-size: 60px;
    line-height: 65px;
    font-weight: 300;
  }
  .features__content__image--img {
    max-height: 220px;
    min-height: 220px;
    width: 100%;
  }
  .features__content__text__heading {
    font-family: var(--fontFamily);
    font-size: 16px;
    font-weight: normal;
    color: var(--darkGrey);
  }
  
  .benefits-background {
    background: #e6e6e628 !important;
  }
  
  .dark-blue {
    color: var(--darkBlue);
  }
  
  @media only screen and (max-width: 1400px) {
    .features__header {
      font-size: 40px;
      line-height: 10px;
    }
    .features__header2{
      font-size: 60px;
      line-height: 1;
    }
    .features__content__text__heading {
      font-size: 21px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .features__header,.features__header2 {
      font-size: 35px;
    }
    .features__content__image--img {
      max-height: 180px;
    }
    .features__content__text__heading {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 992px) {
    .features__header,.features__header2 {
      font-size: 30px;
    }
    .features__content__image--img {
      max-height: 160px;
    }
    .features__content__text__heading {
      font-size: 20px;
    }
    .es1__companyimg1{
        max-width: 120px;
    }
    .es1__companyimg2{
        max-width: 100px;
    }
    .es1__companyimg3{
        max-width: 120px;
    }
    .es1__companyimg4{
        max-width: 100px;
    }
    .es1__companyimg5{
        max-width: 150px;
    }
  }
  @media only screen and (max-width: 768px) {
    .features__header,.features__header2 {
      font-size: 25px;
    }
    .features__content__image--img {
      max-height: 140px;
    }
    .features__content__text__heading {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 576px) {
    .features__header,.features__header2 {
      font-size: 23px;
    }
    .features__content__image--img {
      max-height: 120px;
    }
    .features__content__text__heading {
      font-size: 18px;
    }
  }
  .lightBlueBar {
    min-height: 110px;
    height: auto;
    background-color: var(--lightBlue);
  }
  .lightBlueBar__content__heading {
    max-width: 1150px;
    width: 100%;
    font-family: var(--fontFamily);
    font-size: 28px !important;
    line-height: 50px;
    font-weight: 300;
  }
  .lightBlueBar__content__icon {
    font-size: 80px;
    color: var(--darkGrey) !important;
  }
  .lightBlueBar__content__icon__image{
    max-width: 50px;
  }
  @media only screen and (max-width: 1400px) {
    .lightBlueBar__content__heading {
      max-width: 1015px;
      font-size: 25px !important;
    }
  }
  @media only screen and (max-width: 1200px) {
    .lightBlueBar__content__heading {
      max-width: 750px;
      font-size: 25px !important;
      line-height: 35px;
    }
  }
  @media only screen and (max-width: 992px) {
    .lightBlueBar__content__heading {
      max-width: 650px;
      font-size: 22px !important;
      line-height: 30px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .lightBlueBar__content__heading {
      max-width: 550px;
    }
  }
  @media only screen and (max-width: 576px) {
    .lightBlueBar__content__heading {
      max-width: 350px;
      font-size: 18px !important;
      line-height: 25px !important;
    }
  }
  .darkBlueBar {
    min-height: 110px !important;
    height: auto;
    background-color: var(--blue);
  }
  .darkBlueBar__heading {
    color: var(--darkBlue) !important;
    font-size: 34px;
  }
  
  @media only screen and (max-width: 1400px) {
    .darkBlueBar__heading {
      font-size: 34px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .darkBlueBar__heading {
      font-size: 32px;
    }
  }
  @media only screen and (max-width: 992px) {
    .darkBlueBar__heading {
      font-size: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    .darkBlueBar__heading {
      font-size: 26px;
    }
  }
  @media only screen and (max-width: 576px) {
    .darkBlueBar__heading {
      max-width: 400px !important;
      width: 100%;
      font-size: 22px;
      line-height: 26px;
    }
  }
  .jobs--img{
    border: 0px solid !important;
  }