.enav {
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%;
}

.enav__top {
    background-color: var(--darkBlue);
    height: 90px;
}

.enav__top__logo {
    width: 200px;
}

.enav__top__right__search {
    width: 350px;
    background: rgba(255, 255, 255, 0.119);
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
}

.enav__top__right__search input {
    background-color: transparent;
    border: 0px solid;

}

.enav__top__right__search input::placeholder {
    font-family: var(--fontFamily);
    color: rgba(255, 255, 255, 0.479);
    font-weight: 300px;
}

.enav__top__right__search input:focus {
    border: 0px solid;
    outline: none;
    box-shadow: none;
}

.enav__top__right__user {
    width: 25px;
    height: 25px;
    border: 1.5px solid white;
    object-fit: cover;
}

.enav__top__right__dropdown {
    background: rgba(255, 255, 255, 0.119);
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
}

.enav__top__right__dropdown--ul {
    top: 5px !important;
    box-shadow: 0px 3px 10px #00000014;
    border: 0.5px solid #00000067;
    border-radius: 8px;
    z-index: 20;
}

.enav__mid {
    background-color: #253769;
    height: 40px;
}

.enav__mid__link {
    height: 40px;
    border-radius: 6px;
    padding: 2px 14px 0px 14px;
}

.enav__offcanvas {
    background-color: var(--darkBlue) !important;
    z-index: 100 !important;
    width: 80% !important;
}

.enav__burger {
    right: 20px !important;
}

@media only screen and (max-width: 992px) {
    .enav__top {
        height: 80px;
    }

    .DAccount__top {
        top: 82px;
    }

    .enav__mid__link {
        padding: 2px 0px 0px 14px !important;
        margin-right: 14px;
    }
}

.border-top-05 {
    border-top: 0.5px solid rgba(255, 255, 255, 0.443);
}

@media only screen and (max-width: 576px) {
    .enav__top {
        height: 70px;
    }

    .enav__top__logo {
        width: 170px;
    }

    .enav__burger {
        right: 5px !important;
    }
    .ant-drawer-content-wrapper{
        width: 80% !important;
    }
}

.enav__mid__link__dropdown {
    padding-left: 28px !important;
    margin-left: 15px;
}