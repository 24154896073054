.ps1__title1 {
    max-width: 250px;
}

.ps1__title2 {
    max-width: 200px;
}

.ps1__title3 {
    max-width: 250px;
}

.ps1__title4 {
    max-width: 250px;
}

.ps1__title5 {
    max-width: 250px;
}

.ps1__title6 {
    max-width: 250px;
}

.toggle__border {
    border-bottom: 1px solid var(--lightGrey);
}

.toggle__content {
    max-width: 980px;
}

.toggle__content__heading {
    font-family: var(--fontFamily);
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0px;
    color: var(--darkBlue);
}

.toggle__content__para {
    font-family: var(--fontFamily);
    font-size: 16px;
    color: var(--darkGrey);
    line-height: 22px;
    letter-spacing: 0px;
    font-weight: 300;
}

.lightGreenBar {
    background-color: var(--main) !important;
}

.darkGreenBar {
    background-color: var(--darkGreen) !important;
}

.color-main {
    color: var(--main) !important;
}

@media only screen and (max-width: 992px) {
    .toggle-main-padding {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
}

@media only screen and (max-width: 768px) {
    .toggle__button__bg {
        max-width: 90px;
        height: 38px;
    }

    .toggle__button__bg__circle {
        height: 32px;
        width: 32px;
    }

    .toggle__button__bg__circle--icon {
        font-size: 20px;
    }
}

@media only screen and (max-width: 576px) {
    .toggle-main-padding {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}