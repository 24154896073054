.uas1__content{
    max-width: 370px;
    width: 100%;
}
.uas3{
    background-color: #1A1A1A;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 470px;
}
.uas3 p{
    max-width: 660px;
    width: 100%;
}
@media only screen and (max-width: 768px){
    .uas1__content{
        max-width: 100%;
        width: 100%;
    }
}