@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-Medium.woff2') format('woff2'),
        url('OpenSansCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-BoldItalic.woff2') format('woff2'),
        url('OpenSansCondensed-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-ExtraBold.woff2') format('woff2'),
        url('OpenSansCondensed-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-Light.woff2') format('woff2'),
        url('OpenSansCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-LightItalic.woff2') format('woff2'),
        url('OpenSansCondensed-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-Italic.woff2') format('woff2'),
        url('OpenSansCondensed-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-ExtraBoldItalic.woff2') format('woff2'),
        url('OpenSansCondensed-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-MediumItalic.woff2') format('woff2'),
        url('OpenSansCondensed-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-Bold.woff2') format('woff2'),
        url('OpenSansCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-Regular.woff2') format('woff2'),
        url('OpenSansCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-SemiBoldItalic.woff2') format('woff2'),
        url('OpenSansCondensed-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-SemiBold.woff2') format('woff2'),
        url('OpenSansCondensed-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

