.jobs {
  font-family: var(--fontFamily);
}

.jobs__content__hiring {
  box-shadow: -25px 15px 70px rgba(0, 0, 0, 0.0705882353);
  border-radius: 12px;
}

.jobs__content__hiring__heading {
  color: var(--main);
  letter-spacing: -0.88px;
}

.jobs__content__hiring__para {
  color: var(--darkBlue);
  letter-spacing: -0.33px;
}

.jobs__content__hiring__btn {
  max-width: 300px;
  width: 100%;
}

.jobs__content__hiring__btn--button {
  border-radius: 8px;
  min-height: 35px;
  height: 100%;
  width: 100%;
  background-color: var(--main);
  color: var(--white);
  border: 0;
}

.active__roles {
  color: var(--darkBlue) !important;
}


.jobs__filters {
  padding: 0px;
  list-style: none;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 50px;
}

.jobs__filters li {
  min-width: 12%;
  padding-right: 5px;
}

.jobs__filters li button {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border: 0.5px solid #41414080;
  border-radius: 8px;
  line-height: 30px;
  color: var(--darkGrey);
  font-size: 15px;
  letter-spacing: -0.32px;
  margin: 0px;
  display: block;
  width: 100%;
  padding: 0 8px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchBox {
  background: var(--lightGrey2);
  border-radius: 8px;
  position: relative;
}

.searchBox svg {
  position: absolute;
  z-index: 2;
  font-size: 20px;
  top: 9px;
  left: 10px;
}

.searchBox input {
  padding-left: 40px;
  width: 100%;
  height: 32px;
  border: none;
  background-color: var(--lightGrey2);
  border-radius: 8px;
}

.jobs__filters li {
  min-width: auto !important;
  padding-right: 0px !important;
}

.searchBox input::placeholder {
  color: var(--darkGrey);
}


.filter__data .filter__image {
  float: left;
  width: 80px;
  margin-right: 20px;
  background-color: var(--white);
  height: 75px;
}

.filter__data .filter__image img {
  min-width: 80px;
  border: none;
  padding: 0px;
  border-radius: 8px;
}

.filter__content {
  float: left;
  width: calc(100% - 100px);
}

.filter__data .filter__content__para {
  display: flex;
  font-size: 16px;
}

.filter__data .filter__content__para .filter__content__label {
  font-weight: 600;
  margin-right: 10px;
}

.filter__data .filter__content__para>p:last-child {
  margin-left: 20px;
}

.filter__content__tag__content {
  background-color: var(--pastelBlue);
  border: none;
  height: 23px;
  line-height: 22px;
  display: flex !important;
  align-items: center !important;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus-within {
  box-shadow: none !important;
  border: 0px solid !important;
}

.offer_image {
  background-color: #5F89FF !important;
}

.filter__content__tag__content small.filter__content__tag__content--small {
  color: var(--darkBlue);
}

.desc__img {
  width: 70px;
  margin-right: 15px;
}

.filter__border:last-child {
  border-bottom: 0px solid #4a4a4971;
}

.jobs__border:first-child {
  border: 1px solid #4a4a4971;
  border-radius: 8px;
}

.jobs__border:last-child {
  border: 1px solid #4a4a4971;
  border-radius: 8px;
}

.jobs__border {
  border: 1px solid #4a4a4971;
  border-radius: 8px;
}

.filter__border.professional {
  padding-left: 60px;
}

.filter__border:hover {
  background-color: rgb(95 137 255 / 10%);
}

.filter__border:hover .filter__content__link__heading {
  color: var(--darkBlue) !important;
}

.filter__border:hover .filter__content__link__heading__grey,
.filter__content__link__heading__grey__icon {
  color: var(--darkGrey) !important;
}

.filter__border:hover .filter__content__link__heading__grey__icon {
  color: var(--darkGrey) !important;
}

.jobs__tabs {
  margin-top: 20px;
}

.jobs__star {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.jobs__tabs .react-tabs__tab {
  color: var(--midGrey);
}

.desc__button {
  max-width: 180px !important;
  width: 100%;
  height: auto !important;
}

.desc__hiring {
  background-color: #5F89FF;
  border-radius: 8px;
  max-width: 300px;
  padding: 1px 10px 1px 5px;
}

.application-sent {
  background-color: #E8FFC4 !important;
  color: #9BC756 !important;
  border: 1px solid #9BC756 !important;
}

.jobs__tabs .react-tabs__tab-panel {
  padding-top: 20px;
}

.jobs__tabs .react-tabs__tab::after {
  display: none;
}

.jobs__tabs .react-tabs__tab--selected::after {
  display: none;
}

ul.pro_jobs_data.jobs__filters {
  justify-content: flex-start;
  margin-bottom: 10px;
  padding-left: 60px;
}

ul.pro_jobs_data.jobs__filters li {
  margin-right: 10px;
}

.filters_buttons_pro {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.filters_buttons_pro>div {
  width: 100%;
}

.filters_buttons_pro button {
  width: 100%;
  border: 1px solid var(--darkGrey);
  border-radius: 8px;
  height: 40px;
}

.filters_buttons_pro .filters_buttons_pro_save {
  width: 80%;
  margin-right: 15px;
}

.filters_buttons_pro button.filters_buttons_pro_save_btn {

  background-color: var(--darkGrey);
  color: #fff;
}

.filters_buttons_pro .filters_buttons_pro_saved_filters {
  background: #fff;
}

.filters_buttons_pro+div {
  margin-bottom: 30px;
}

ul.pro_jobs_data.jobs__filters li button {
  padding: 0px 8px 0 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

ul.pro_jobs_data.jobs__filters li button span {
  margin-right: 10px;
}

.pro_jobs_main_hd {
  font-size: 50px;
  color: var(--darkBlue);
  margin-bottom: 0px;
  font-weight: 400;
  padding-left: 60px;
}

.pro_jobs_main_hd strong {
  font-weight: 600;
}

ul.pro_jobs_data.jobs__filters li button.active {
  color: var(--lightBlue2);
}

.pro_jobs_data.jobs__filters__list {
  list-style: none;
  padding: 0px;
  display: flex;
  padding-left: 60px;
}

.pro_jobs_data.jobs__filters__list li {
  border: 1px solid var(--lightBlue2);
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 8px;
  color: var(--lightBlue2);
  cursor: pointer;
}

.jobs__Icon {
  background: #5F89FF;
  border-radius: 50% !important;
  padding: 3px 7px 6px 7px;
  max-width: 40px !important;
  height: 40px;
  margin-right: 10px;
}

.jobs__Icon img {
  width: 100%;
}

.job__top {
  background: #F8F8F8 !important;
}

.job__top__search {
  background: #F8F8F8 !important;
}

button.job__top__search__clear {
  background: #F8F8F8 !important;
}

.filter__content {
  width: 100% !important;
}

.jobs__icon__image img {
  max-width: 36px;
  margin-right: 10px;
}


.paginate-button {
  align-items: center;
  background-color: #fff !important;
  border: 1px solid #70707041 !important;
  display: inline-flex !important;
  justify-content: center;
  border-radius: 5px;
  color: #555;
  margin-right: 10px;
}

.expiring__body_detail_image.company__image {
  background: transparent;
}

.expiring__body_detail_image.company__image img {
  width: 100% !important;
}
.expiring__body_detail_image.company__image__detail {
  background: transparent;
  border:1px solid #ddd;
}

.expiring__body_detail_image.company__image__detail img {
  width: 100% !important;
}

@media only screen and (max-width: 1400px) {
  .jobs__content__hiring__heading {
    font-size: 22px;
  }

  .jobs__content__hiring__btn {
    max-width: 250px;
  }

  .jobs__filters li button {
    padding: 0px 5px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .jobs__content__hiring__heading {
    font-size: 30px;
  }

  .jobs__content__hiring__btn {
    max-width: 200px;
  }

  .jobs__content__hiring__btn--button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 992px) {
  .jobs__content__hiring__heading {
    font-size: 25px;
  }

  .jobs__content__hiring__btn {
    max-width: 150px;
  }

  .jobs__content__hiring__btn--button {
    font-size: 12px;
  }
}

.filter__content__link__heading__grey {
  color: var(--lightGrey) !important;
  letter-spacing: -0.48px !important;
}

.filter__content__link__heading__grey__icon {
  color: #e6e6e6 !important;
}

.jobs__filter__header {
  font-size: 16px;
}

.jobs__filter__li {
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .desc__button {
    max-width: 150px !important;
  }

  .desc__button--small {
    font-size: 14px !important;
  }

  .desc__hiring {
    max-width: 250px;
    font-size: 14px;
  }
}

.desc__para {
  max-width: 950px;
  width: 100%;
}

.desc__hiring .pro__dash__jobs__icon {
  width: 24px !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}

@media only screen and (max-width: 576px) {
  .desc__button {
    max-width: 50% !important;
  }

  .desc__button--small {
    font-size: 14px !important;
  }

  .desc__hiring {
    max-width: 100%;
    font-size: 14px;
  }

  .lightBlueBar__content__icon__image {
    max-width: 60px !important;
    width: 100% !important;
    min-width: auto !important;
  }

  .filter__data .filter__image {
    margin-right: 15px;
  }

  .desc__applynow,
  .desc__back {
    max-width: 100% !important;
    width: 100%;
  }

  .desc__img {
    width: 60px;
    margin-right: 12px;
  }
}

.desc__applynow {
  background-color: var(--main);
  color: white;
  border-radius: 8px;
  max-width: 200px;
  width: 100%;
  height: 37px !important;
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
  transition: all 0.3s ease-in-out;
}

.desc__applynow:hover {
  background-color: #436C03;
}

.link__main {
  text-decoration: none;
  color: var(--main);
}

.desc__back {
  color: #CBCBCB;
  border: 1px solid #CBCBCB;
  border-radius: 8px;
  background-color: white;
  max-width: 100px;
  width: 100%;
}

.desc__back:hover {
  color: white;
  background-color: var(--main);
}

.apply__back {
  color: var(--main);
  border: 1px solid var(--main);
  border-radius: 8px;
  background-color: white;
  max-width: 100px;
  width: 100%;
}

.apply__back:hover {
  color: white;
  background-color: var(--main);
}

.text-grey {
  color: #999999 !important;
}

.jobs--img {
  border: 0px solid !important;
}



.br-200 {
  border-radius: 200px;
}

.filter__content__dates {
  max-width: 200px;
  width: 100%;
}

.filter__image {
  background: transparent !important;
}

.bg-lightgrey {
  background: #F8F8F8 !important;
}

.job__top {
  border-bottom: 0.5px solid #41414050;
}

.job__top__search__icon {
  color: #4a4a49a3;
}

.job__top__search {
  border: 0px solid;
  background-color: white;
}

.job__top__search::placeholder {
  color: #4a4a49a3;
}

.job__top__search:focus {
  outline: none;
}

.job__top__search1 {
  width: 40%;
  border-right: 0.5px solid #41414050;
}

.job__top__search2 {
  width: 40%;
}

.job__top__search__clear {
  border: 0px solid;
  background: white;
}

.job__top__search__btn {
  background: #A6DD50;
  border-radius: 8px;
}

.bg-midGrey {
  background-color: #CBCBCB !important;
}

.all_jobs .jobs {
  padding-top: 100px;
  margin-top: -100px !important;
}

.bg-darkGrey {
  background-color: var(--darkGrey) !important;
}

.job__top {
  background: white;
}

@media only screen and (max-width: 1200px) {
  .all_jobs .jobs {
    padding-top: 110px;
    margin-top: -90px !important;
  }
}

@Media only screen and (max-width: 786px) {
  .job__top {
    background: none;
    border: 0px solid !important;
  }

  .job__top__search1 {
    border-right: 0px solid;
    width: 100%;
    border-radius: 8px;
  }

  .job__top__search2,
  .job__top__search {
    border-right: 0px solid;
    width: 100%;
    border-radius: 8px;
  }
}

.jobs__tabs .react-tabs__tab--selected,
.jobs__tabs .react-tabs__tab--selected:focus {
  color: #5f89ff !important;
  border: 0px solid !important;
  border-bottom: 0px solid #5f89ff !important;
  background: transparent !important;
}

.candidates__tabs .react-tabs__tab {
  padding-bottom: 10px !important;
}

.candidates__tabs .react-tabs__tab--selected::before {
  content: "" !important;
  display: block !important;
  width: 50% !important;
  height: 4px !important;
  background-color: #486FD9 !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  left: 25% !important;
  bottom: 0.5px !important;
  position: absolute !important;
}