.mailto__link{
    color:var(--black);
}
.psubmit__criteria{
    background: url('../../../../public/assets/images/submit/CriteriaBox.svg');
    height: 100%;
    background-position: center;
    border-radius: 15px;
}
.psubmit__criteria--img{
    max-width: 175px;
    width: 100%;
}
.psubmit--button{
    border-radius: 5px;
    background: #F9A738;
}
.mw-320{
    max-width: 320px;
    width: 100%;
}

@media only screen and (max-width: 576px){
    .psubmit__criteria--img{
        max-width: 150px;
    }
}