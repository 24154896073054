.Plan__card {
  transition: all 0.3s ease-in-out;
}

.Plan__card:hover {
  box-shadow: rgba(56, 56, 57, 0.1) 0px 15px 43px;
}

.Plan__card__button {
  width: 200px;
  border: 1px solid #1677ff;
  background: var(--white);
  font-family: var(--fontFamily);
  color: #1677ff;
  transition: all 0.3s ease-in-out;
}

.Plan__card__button:hover {
  background: #1677ff;
  color: var(--white);
}

.plan__box .Plan__card__button {
  background-color: #1677ff;
  color: #fff;
}

.Plan__card__button.current {
  background-color: #fff;
  color: var(--main);
  border-color: #fff;
}

.Plan__card__container__button {
  height: 30px;
  width: 70px;
}

.Plan__card__container__box {
  width: 50px;
  height: 45px;
  background: var(--darkBlue);
}

.Plan__table__content__button {
  height: 35px !important;
  max-width: 150px;
  width: 100%;
}

.Plan__table__content__button2 {
  height: 34px !important;
  width: 95px !important;
  border: 1px solid var(--darkGrey) !important;
  background: var(--white) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.3s ease-in-out !important;
}

.Plan__table__content__button2:hover {
  background: var(--darkGrey) !important;
  color: white !important;
}

.plan__box {
  min-height: 380px;
  margin-bottom: 30px;
  display: flex;
  /* border:none !important; */
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(56, 56, 57, 0.1) 1px 20px 10px;
}

.plan__box .plan__heading {
  text-align: center;
}

.plan__box h2 {
  text-align: center;
  /* font-weight: 800; */
}

.plan__box ul {
  list-style: none;
  padding: 0px;
  margin-top: 30px;
}

.plan__box ul li {
  position: relative;
  padding-left: 15px;
  font-size: 14px;

}

.plan__box ul li:before {
  position: absolute;
  width: 6px;
  height: 6px;
  content: "";
  top: 8px;
  left: 0;
  border-radius: 50%;
  background-color: var(--main);
}


.plan__box.current {
  background-image: linear-gradient(#285ae7, #0d2159);
}

.plan__box.current .plan__heading,
.plan__box.current .plan__pricing,
.plan__box.current ul li {
  color: #fff
}

.plan__box.current ul li:before {
  background-color: #fff;
}




.card__parent {
  display: flex;
  flex-wrap: wrap;
}

.card__container {
  position: relative;
  /* background-image: url("/assets/images/card/bg.png"); */
  background-color: #000;
  /* background-size: cover; */
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  flex: 47% 1;
}

.card__header__logo {
  width: 70px;
  height: 50px;
  padding: 8px;
}

.dots {
  height: 34px !important;
  border: 1px solid var(--darkGrey) !important;
}

.dots.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus-within {
  box-shadow: none !important;
  border: 1px solid var(--darkGrey) !important;
}

.card__logo img {
  width: 48px;
  margin-right: 10px;
}

.cardH5 {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.card__header .chip {
  width: 60px;
}

.cardH6 {
  color: #fff;
  font-size: 10px;
  font-weight: 400;
}

.cardH5.number {
  margin-top: 4px;
  font-size: 18px;
  letter-spacing: 1px;
}

.cardH5.name {
  margin-top: 20px;
}

.plan__box h2.plan__pricing {
  font-size: 24px;
}

.card__container .card-details {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.card__container .card-details .valid-date {
  color: #fff;
}

.card__container .card-details .name-number h6 {
  color: #fff;
}

.plan__box h2.plan__pricing span {
  font-size: 12px;
  color: #808080;
}

.plan__box.current h2.plan__pricing span {
  color: #fff;
}


/* new plan cards */

.plan__cards {
  padding: 18px;
  border: 1px solid #CBCBCB;
  border-radius: 10px;
  font-family: 'Open Sans';
  position: relative;
}

.plan__cards .plan__cards__heading {
  text-align: center;
  font-weight: 600;
}

.plan__monthly__pricing h2 {
  margin: 0px;
  font-weight: 800;
  font-size: 34px;
  font-family: 'Open Sans';
}

.plan__monthly__features {
  padding: 0px;
  list-style: none;
  margin-top: 30px;
}

.plan__monthly__features li {
  margin-bottom: 8px;
  font-size: 12px;
}

.plan__monthly__features li.disabled {
  color: #CBCBCB;
}

.plan__monthly__features li svg {
  color: #5F89FF;
  font-size: 20px;
  margin-right: 5px;
}


.plan__monthly__pricing span {
  color: #969696;
  font-size: 12px;
}

.plan__monthly__features li.disabled svg {
  visibility: hidden;
}

.plan__cards.most__popular:after {
  position: absolute;
  content: "Most Popular";
  background: #5F89FF;
  top: -30px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan__cards.most__popular {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: none;
  background: #0D2159;
}

.plan__cards.most__popular .plan__cards__heading {
  color: #fff;
}

.plan__cards.most__popular .plan__monthly__pricing h2 {
  color: #fff;
}

.plan__cards.most__popular .plan__monthly__features li {
  color: #fff;
}

.plan__cards .buy__now__plans {
  background: #486FD9;
  color: #fff;
  padding: 10px 25px;
  border-radius: 4px;
}

.plan__cards.most__popular .plan__monthly__features li.disabled {
  color: #CBCBCB;
}

.plan__cards.current__package .package__button {
  visibility: hidden;
}

.plan__cards.current__package:before {
  content: "CURRENT PACKAGE";
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 5px;
  text-align: center;
  color: #4A4A49;
  font-weight: 600;
  background: #E6E6E6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media only screen and (max-width: 992px) {
  .most__popular-col {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .Plan__table {
    overflow: scroll !important;
  }

  .Plan__row {
    min-width: 768px !important;
  }
}

@media only screen and (max-width: 576px) {
  .most__popular-col-sm {
    padding-top: 30px;
  }

  .card__header__logo {
    width: 60px;
    height: 45px;
    padding: 8px;
  }
}