.top-padding{
    padding-top: 150px;
}
.uhs1{
    background: radial-gradient(circle, rgba(224,237,203,1) 20%, rgba(255,255,255,1) 77%, rgba(255,255,255,1) 100%);
}
.uhs1__heading{
    max-width: 550px;
}
.uhs1__para{
    max-width: 740px;
}
.uhs1--img{
    max-width: 1100px;
    width: 100%;
}

.uhs2{
    background-color: #F9F8F6;
    padding-top: 60px ;
    padding-bottom: 60px ;
}
.uhs2__card1{
    background: #BFD0FF;
}
.uhs2__card{
    padding: 30px 10px;
    min-height: 250px;
}
.uhs2__card1__text{
    max-width: 240px;
    width: 100%;
}
.uhs2__card2{
    background: #cbcbcb7e;
}
.uhs2__card--img{
    height: 200px;
}
.uhs2__card3{
    background-image: url('../../../public/assets/UpdatedFrontend/Home/homecard3.svg');
    background-size: cover;
    padding: 0px 10px;
    min-height: 250px;
}
.uhs2__card3__text{
    position: absolute;
    bottom: 30px;
}
.uhs3__title{
    padding: 5px 15px;
    background: #E6E6E6;
    display: inline-block;
}
.uhs3__card1{
    background-color: rgb(3, 19, 65);
}
.uhs3__card2{
    background-color: #4E70D1;
}
.uhs3__card1__content{
    max-width: 510px;
}
.uhs3__card2__content{
    max-width: 460px;
}
.uhs3{
    margin-bottom: -115px;
}
.uhs4{
    padding-top: 180px;
}
.uhs4__card{
    padding: 40px 0px;
}
.uhs4__card1{
    border-right: 1px solid #5F89FF;
    border-bottom: 1px solid #5F89FF;
}

.uhs4__card2{
    border-right: 1px solid #5F89FF;
    border-bottom: 1px solid #5F89FF;
}
.uhs4__card3{
    border-bottom: 1px solid #5F89FF;
}
.uhs4__card4, .uhs4__card5{
    border-right: 1px solid #5F89FF;
}
.uhs4__card__para{
    max-width: 300px;
}
.uhs4__card1::before, .uhs4__card2::before{
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #F9F8F6;
    bottom: -15px;
    right: -15px;
    z-index: 2;
}
.uhs5__container{
    background: #d8ff98b7;
    padding-top: 45px;
}
.uhs5__para{
    max-width: 800px;
}
.uhs6{
    margin-top: 30px;
    background-color: #1A1A1A;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 400px;
}
.uhs6__heading{
    max-width: 600px;
    width: 100%;
}
.uhs6__para{
    max-width: 690px;
    width: 100%;
}
@media only screen and (max-width: 1400px){
    .uhs2__card--img{
        height: 150px;
    }
    .uhs1__para{
        max-width: 700px;
    }
    .uhs3__card1__content{
        max-width: 450px;
    }
    .uhs3__card2__content{
        max-width: 420px;
    }
}
@media only screen and (max-width: 992px){
    .uhs4__card2{
        border-right: 0px solid #5F89FF;
        border-bottom: 1px solid #5F89FF;
    }
    .uhs4__card3{
        border-right: 1px solid #5F89FF;
        border-bottom: 1px solid #5F89FF;
    }
    .uhs4__card4{
        border-right: 0px solid #5F89FF;
        border-bottom: 1px solid #5F89FF;
    }
    .uhs4__card__para{
        max-width: 300px;
    }
    .uhs4__card2::before{
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
    }
    .uhs4__card1::before, .uhs4__card3::before{
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #F9F8F6;
        bottom: -15px;
        right: -15px;
        z-index: 2;
    }
    .top-padding{
        padding-top: 120px;
    }
}
@media only screen and (max-width: 576px){
    .uhs2__card3{
        min-height: 330px;
    }
    .uhs2__card--img{
        height: auto;
        max-width: 350px;
        width: 100%;
    }
    .uhs4__card1, .uhs4__card2, .uhs4__card3, .uhs4__card4, .uhs4__card5{
        border-right: 1px solid #5F89FF;
        border-left: 1px solid #5F89FF;
        border-bottom: 1px solid #5F89FF;
    }
    .uhs4__card6{
        border-right: 1px solid #5F89FF;
        border-left: 1px solid #5F89FF;
    }
    
    .uhs4__card__para{
        max-width: 300px;
    }
    .uhs4__card2::before{
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
    }
    .uhs4__card1::before, 
    .uhs4__card2::before,
    .uhs4__card3::before,
    .uhs4__card4::before,
    .uhs4__card5::before
    {
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #F9F8F6;
        bottom: -15px;
        right: -15px;
        z-index: 2;
    }
    .uhs4__card1::after, 
    .uhs4__card2::after,
    .uhs4__card3::after,
    .uhs4__card4::after,
    .uhs4__card5::after
    {
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #F9F8F6;
        bottom: -15px;
        left: -15px;
        z-index: 2;
    }
}