.offcanvas {
  background: var(--black) !important;
  z-index: 3 !important;
}
.sidebar__offcanvas{
  height: 82vh;
}
.desktop-offcanvas{
  top: 115px !important;
}
.offcanvas-backdrop {
  display: none !important;
}
.desktop__offcanvas-body{
  margin-top: 90px !important;
}
.offcanvas__list__item--Link {
  font-size: 20px;
  color: var(--white);
  font-weight: semibold;
  font-family: var(--fontFamily);
  transition: all 0.3s ease-in-out;
}
.offcanvas__list__item--Link__active{
  color: var(--main);
}
.offcanvas__list__item--Link:hover {
  color: var(--main) !important;
}
.offcanvas__list__item--Link__icon {
  font-size: 32px;
}
.offcanvas__link__button {
  font-size: 20px;
  color: var(--main);
  font-weight: semibold;
  font-family: var(--fontFamily);
  transition: all 0.3s ease-in-out;
  max-width: 300px;
  width: 100%;
  background: none;
  border-radius: 8px;
}
.offcanvas__link__button:hover{
  color: white;
}
.offcanvas__link:hover {
  color: var(--main) !important;
}
.text-grey{
  color: #969696;
}


.sidebar-close {
  color: var(--white);
  font-size: 40px;
  cursor: pointer;
}

.sidebar-menu {
  max-width: 300px;
  width: 100%;
}

.sidebar-header {
  max-width: 300px;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .offcanvas__list__item--Link {
    font-size: 18px;
  }
  .sidebar-close {
    font-size: 30px;
  }
  .offcanvas__list__item--Link__icon {
    font-size: 24px;
  }
  .offcanvas__link__button {
    font-size: 18px;}
}
@media only screen and (max-width: 1200px) {
  .offcanvas__link__button {
    font-size: 16px;}
  .offcanvas__list__item--Link {
    font-size: 16px;
  }
  .sidebar-close {
    font-size: 27px;
  }
  .offcanvas__list__item--Link__icon {
    font-size: 22px;
  }
  .offcanvas__link__button {
    font-size: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .offcanvas {
    width: 370px !important;
  }
  .offcanvas__list__item--Link {
    font-size: 16px;
  }
  .sidebar-close {
    font-size: 25px;
  }
  .offcanvas__list__item--Link__icon {
    font-size: 20px;
  }
  .offcanvas__link__button {
    font-size: 18px;
  }
}