.demo {
    background: rgb(165, 220, 79);
    background: linear-gradient(321deg, rgba(165, 220, 79, 1) 0%, rgba(101, 141, 83, 1) 19%, rgba(13, 33, 89, 1) 52%, rgba(20, 29, 35, 1) 84%, rgba(49, 68, 39, 1) 100%);
}
.pricing .demo{
    background: #EDEDED;
}
.pricing .demo h1 {
    color: black !important;
}
.pricing .demo p{
    color: var(--darkGrey) !important;
    font-weight: 400 !important;
}