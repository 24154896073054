.Overlay-fullSize {
  width: 100% !important;
  z-index: 0 !important;
}

.css-3nejl.Appointment-appointment {
  background-color: var(--main) !important;
}

.css-gyiown .VerticalAppointment-title,
.css-gyiown .VerticalAppointment-time {
  color: #4A4A49 !important;
  font-family: "Open Sans" !important;
}

.css-gyiown .VerticalAppointment-title {
  font-size: 15px !important;
  font-weight: 500 !important;
  overflow: hidden;
  text-overflow: unset !important;
  white-space: normal !important;
  line-height: 17px;

}

.css-gyiown.VerticalAppointment-content {
  height: 100% !important;
}

.css-gyiown .VerticalAppointment-container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

@media only screen and (max-width: 1150px) {
  .css-vr9er9-MuiModal-root-MuiDrawer-root .Overlay-fullSize {
    width: 100% !important;
  }

  .Overlay-fullSize {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1000px) {
  .Overlay-fullSize {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1100px) {
  .css-gyiown .VerticalAppointment-title {
    font-size: 14px !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}