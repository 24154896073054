.pro__inbox__container__main__sidebar .react-tabs__tab-list {
    background-color: var(--lightGrey);
    height: 100vh;
    min-width: 85px;
    max-width: 85px;
    width: 85px;
    padding-top: 100px;
    border-bottom: none;
    margin-bottom: 0px;
    z-index: 3 !important;
    position: fixed !important;
    top: 80px;
    left: 0px !important;
}
.inbox__input{
    border: 0.5px solid #969696;
    border-radius: 4px;
}
.inbox__input:focus{
    outline: none;
}
.pro__inbox__container__main__sidebar .tabs__container {
    width: 100%;
    background-color: var(--white);
    margin-left: 77px;
    height: auto;
    /* border-left: p0.5px solid rgb(112 112 112 / 50%); */
}
.tab__box{
    height: 100%;
    overflow-y: hidden;
}
.pro__inbox__container__main__sidebar ul {
    list-style: none;
    padding: 0px;
}

.pro__inbox__container__main__sidebar ul li div {
    padding: 0px;
    text-align: center;

}

.pro__inbox__container__main__sidebar ul li div svg {
    display: block;
    font-size: 22px;
    margin: auto;
}

.pro__inbox__container__main__sidebar ul li {
    width: 100%;
}

.pro__inbox__container__main__sidebar ul li div.active {
    color: var(--lightBlue2);
}


.pro__inbox__container__main__sidebar ul li.react-tabs__tab {
    padding: 0px !important;
    border: none;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pro__inbox__container__main__sidebar .react-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: none;
    /* background-color: var(--lightGrey); */
    position: relative;

}


.pro__inbox__container__main__sidebar ul li.react-tabs__tab--selected {
    background: none;
    border: none;
    color: var(--lightBlue2);
    padding: 0px;
}

.pro__inbox__container__main__sidebar ul li.react-tabs__tab--selected:after {
    display: none !important;
}

.pro__inbox__container__main__sidebar ul li.react-tabs__tab--selected svg {
    font-size: 28px;
}




.compose__box__container {
    display: flex;
    height: 100%;
}

.compose__box__container__left {
    width: 75%;

}

.compose__box__container__right {
    width: 25%;
}

.compose__box__container__left__header {
    min-height: 80px;
    border-top: 0.5px solid rgb(112 112 112 / 50%);
    border-bottom: 0.5px solid rgb(112 112 112 / 50%);
    border-right: 0.5px solid rgb(112 112 112 / 50%);
}



.compose__box__container__right__header {
    min-height: 80px;
    border: 0.5px solid rgb(112 112 112 / 50%);
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.compose__box__container__left__items .compose__box__container__left__items__box {
    min-height: 40px;
    display: flex;
    width: 100%;
    align-items: center;

}

.compose__box__container__left__items {
    padding: 25px 25px 0px 25px;
    border-right: 0.5px solid rgb(112 112 112 / 50%);
}

.compose__box__container__left__items .compose__box__container__left__items__box label {
    width: 100%;
}

.compose__box__container__left__items .compose__box__container__left__items__box label input {
    min-height: 36px;
    width: calc(100% - 100px);
    margin-bottom: 15px;
    padding-left: 20px;
}

.compose__box__container__left__items .compose__box__container__left__items__box label span {
    width: 100px;
    display: inline-block;
}

.compose__box__container__left__items .compose__box__container__left__items__box textarea {
    width: 100%;
    min-height: 300px;
    padding-left: 20px;
}


.compose__box__container__right__header h5 {
    font-size: 18px;
}

.compose__box__container__right__attach__box {
    padding: 20px 0px 20px 20px;
    height: 87% !important;
}



.compose__box__container__right__header h5 {
    font-size: 14px;
}

.ant-upload.ant-upload-select {
    max-width: 100%;
}

.compose__box__container__right__attach__box .ant-upload.ant-upload-select button {
    width: 100% !important;
    max-width: 300px;
    font-size: 11px !important;
}


.compose__box__container__right__header h5 {
    font-size: 14px;
}

.compose__box__container__right__attach__box {
    padding: 20px;
    display: flex;
    flex-direction: column;

    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ant-upload.ant-upload-select {
    max-width: 100%;
}

.compose__box__container__right__attach__box .ant-upload.ant-upload-select button {
    width: 100% !important;
    max-width: 300px;
    font-size: 14px !important;
}

.compose__box__container__right__dropdowns {
    width: 100%;
}

.compose__box__container__right__dropdowns .ant-select-selector {
    width: 100% !important;
}


.compose__box__container__right__dropdowns .ant-select-selector::before {
    color: var(--darkGray);
    background-color: var(--white);
}
.inbox__icon{
    width: 22px;
}
.inbox__icon--mail{
    width: 23px;
}
.email-item {
    padding: 15px 12px 15px 12px;
    border-bottom: 1px solid rgba(226, 229, 236, 0.6);
    display: flex;
    cursor: pointer;
}
.chats__top{
    padding-top: 5px;
}
.email-item.active {
    opacity: 1;
}
.inbox__header__icon{
    font-size: 25px;
}
.email-item .avatar {
    margin-right: 8px;
    flex-shrink: 0;
}

.email-item .avatar {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    display: block;
    position: relative;
}

.email-item .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    display: block;
}

.email-item-body {
    flex: 1;
    position: relative;
}







.inbox__container {
    display: flex;
}

.inbox__nav {
    width: 15%;
    min-width: 200px;
    border-right: 0.5px solid rgb(112 112 112 / 50%);
    min-height: 86vh;
}

.inbox__nav__header {
    padding: 15px;
    border-top: 0.5px solid rgb(112 112 112 / 50%);
    border-bottom: 0.5px solid rgb(112 112 112 / 50%);
    display: flex;
    justify-content: flex-end;
    min-height: 80px;
    align-items: center;
    cursor: pointer;
}

.inbox__nav__body {
    padding: 15px;
}

.inbox__nav__items svg {
    font-size: 22px;
    color: var(--darkBlue);
    margin-right: 10px;
}

.inbox__nav__items>div {
    margin-bottom: 5px;
    cursor: pointer;
}

.inbox__nav__items span {
    letter-spacing: -0.32px;
    font-size: 16px;
    color: var(--darkBlue);
}

.inbox__emails__list__header {
    padding: 15px;
    display: flex;
    min-height: 80px;
    border: 0.5px solid rgb(112 112 112 / 50%);
    align-items: center;
    justify-content: space-between;
    border-left: 0;
    border-right: 0;
}

.inbox__nav__header svg {
    font-size: 31px;
}

.inbox__emails__list__header .searchBox__emails {
    width: 100%;
}

.refresh__emails {
    font-size: 24px;
    margin-left: 20px;
}

.refresh__emails svg {
    cursor: pointer;
}

.inbox__emails__list {
    border-right: 0.5px solid rgb(112 112 112 / 50%);
    width: 30%;
}

.email__items__attach {
    display: flex;
    justify-content: flex-end;
}


.email__summary__boxsvg {
    filter: invert(1);
}

.email-item-summary svg {
    color: var(--darkBlue2);
}

.email__summary__box svg {
    color: var(--midGrey);
    font-size: 20px;

}

.email__summary__box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.email__summary__box .email-item-summary {
    margin: 0px;
    padding-right: 40px;
    width: 80%;
    letter-spacing: -0.32px;

}

.email__item__fav svg {
    color: var(--midGrey);
}

.email__item__fav svg {
    font-size: 20px;
}







.inbox__email__view {
    width: 55%;
}

.inbox__email__view__header {
    display: flex;
    justify-content: center;
    min-height: 80px;
    align-items: center;
    padding: 15px;
    border: 0.5px solid rgb(112 112 112 / 50%);
    border-left: none;
}

.inbox__email__view__header__left {
    width: 40%;
}

.inbox__email__view__header__right {
    width: 30%;
    display: flex;
    justify-content: flex-end;
}

.inbox__email__view__header__center {
    width: 30%;
    text-align: center;
}

.inbox__email__view__header__left ul {
    list-style: none;
    padding: 0px;
    display: flex;
    margin: 0px;
    align-items: center;
}

.inbox__email__view__header__left ul li a {
    font-size: 28px;
    color: var(--darkGrey);
    padding: 5px;
    margin-right: 2px;
    display: flex;
    align-items: center;
}

.inbox__email__view__header__left ul li {
    width: auto !important;
}

.inbox__email__view__header__center span {
    font-size: 16px;
    letter-spacing: -0.45px;
}

.inbox__email__view__header__center a {
    color: var(--darkGrey);
}

.inbox__email__view__header__right a {
    color: var(--darkGrey);
    font-size: 26px;
    margin-left: 2px;
    padding: 5px;
}


.inbox__email__view__body__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inbox__email__view__body__header__userInfo {
    display: flex;
    align-items: center;
}

.inbox__email__view__body__header__userInfo span {
    display: block;
    line-height: 18px;
}

.inbox__email__view__body__header__userInfo .avatar {
    width: 82px;
    height: 82px;
}

.inbox__email__view__body__header__userInfo .email-item-sender-name {
    font-weight: 600;
    letter-spacing: -0.36px;
}

.inbox__email__view__body__header__userInfo .email-item-sender-email {
    color: var(--darkGrey);
    letter-spacing: -0.36px;
}

.inbox__email__view__body {
    padding: 15px;
    border-right: 0.5px solid rgb(112 112 112 / 50%);
}

.inbox__email__view__body__header__time .email-item-date {
    color: var(--darkGrey);
    font-size: 13px;
}

.inbox__email__view__body__header__userInfo .inbox__email__view__body__header__userInfo__name {
    margin-left: 10px;
}

.inbox__email__view__body__content {
    padding: 30px 0;
}


.inbox__email__view__body__attachments_list {
    padding-top: 30px;
    border-top: 0.5px solid rgb(112 112 112 / 50%);
    margin-top: 50px;
}

.inbox__email__view__body__attachments_list .attachment__box {
    width: 300px;
    height: 100px;
    background: var(--lightGrey2);
}

.inbox__email__view__body__attachments_list a {
    font-size: 18px;
    text-decoration: none;
    color: var(--lightBlue2);
}

.compose__box__container__right__dropdowns .Get__left__content__para {
    font-size: 16px;
    margin-bottom: 10px;
}

.pro__inbox__container__main__sidebar .react-tabs__tab-list {
    position: relative;
    z-index: 3;
}
.inbox__select{
    margin-left: -2px;
}
.pro__inbox__container__main__sidebar .react-tabs {
    position: relative;
}

.chat__sidebar__tabs__single.chat__sidebar__tabs>li{
    width:100%
}
@media only screen and (max-width: 1500px) {
    .pro__inbox__container__main__sidebar .react-tabs__tab-list{
        left: 260px;
    }
    .inbox__toolbar__text{
        font-size: 14px;
    }
    .inbox__icon--mail{
        width: 20px;
    }
    .inbox__icon{
        width: 20px;
    }
    .inbox__header__icon{
        font-size: 20px;
    }
    .inbox__email__view__body__content{
        padding-bottom: 0px;
    }
    .inbox__email__view__body__attachments_list a{
        font-size: 16px;
    }
    .compose__box__container__left__items .compose__box__container__left__items__box textarea {
        height: 220px;
        min-height: auto;
    }
    .compose__box__container__right__header,
    .compose__box__container__left__header,
    .inbox__nav__header,
    .inbox__emails__list__header,
    .inbox__email__view__header{
        min-height: auto;
        height: 70px;
    }
    .inbox__nav{
        width: 10%;
        min-width: 150px;
    }
    .inbox__emails__list{
        width: 45%;
    }
    .email__summary__box .email-item-summary{
        padding-right: 0px;
    }
    .pro__inbox__container__main__sidebar .react-tabs__tab-list{
        width: 80px;
        min-width: auto;
    }
    .pro__inbox__container__main__sidebar .tabs__container{
        margin-left: 70px;
    }
}
@media only screen and (max-width: 1400px) {
    .inbox__toolbar__text{
        font-size: 13px;
    }
    .inbox__icon{
        width: 20px;
    }
    .pro__inbox__container__main__sidebar .react-tabs__tab-list{
        width: 70px;
        min-width: auto;
    }
    .pro__inbox__container__main__sidebar .tabs__container{
        margin-left: 65px;
    }
    .inbox__header__icon{
        font-size: 18px;
    }
    .inbox__email__view__body__attachments_list .attachment__box{
        width: 250px;
    }
    .compose__box__container__left__items .compose__box__container__left__items__box textarea {
        height: 180px;
    }

    .compose__box__container__right__header h5 {
        font-size: 14px;
    }

    .compose__box__container__right__attach__box .ant-upload.ant-upload-select button {
        font-size: 11px !important;
    }


    .inbox__email__view__header__left ul li a {
        font-size: 16px;
    }

    .inbox__email__view__header__center span {
        font-size: 14px;
    }

    .inbox__email__view__header__right a {
        font-size: 16px;
    }

    .refresh__emails {
        font-size: 16px;
    }

    .inbox__nav {
        width: 10%;
        min-width: 150px;
    }

    .inbox__emails__list {
        width: 40%;
        min-width: 250px;
    }

    .inbox__nav__body {
        padding: 12px 22px;
    }

    .email-item {
        padding: 10px;
    }

    .email-item-date {
        font-size: 12px;
    }

    .email__summary__box .email-item-summary {
        padding-right: 0px;
        letter-spacing: -0.40px;
        font-size: 14px;
    }

    .inbox__nav__items span {
        font-size: 14px;
    }

    .inbox__nav__header svg {
        font-size: 22px;
    }


}
@media only screen and (max-width: 1200px){
    .compose__box__container__left{
        width: 70%;
    }
    .compose__box__container__right{
        width: 30%;
    }
    .email-item .avatar {
        width: 45px;
        height: 45px;}
    .inbox__email__view__body {
        border-right: 0px solid;
    }
}
@media only screen and (max-width: 576px){
    .inbox__avatar--sm{
        width: 60px !important;
        min-width: 60px !important;
        height: 60px !important;
        min-height: 60px !important;
    }
    .inbox__para--sm{
        font-size: 15px;
        line-height: 23px ;
    }
}
.inbox__reply--button{
    background-color: var(--main);
}
.inbox--input-sm{
    outline: none;
    border: 0px solid;
}
.inbox__draft {
    max-width: 120px;
    width: 100%;
}
.inbox__reply {
    max-width: 250px;
    width: 100%;
}