.Dashboard__input {
  max-width: 100% !important;
}

.create__indexCore__chart.econfirm__chart>div {
  width: 250px;
}

.locations__select .ant-select-selector {
  max-width:100% !important
}