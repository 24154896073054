.DAccount__top{
  background: #E3EBFF !important;
  position: fixed;
  top: 130px;
  width: 100%;
  z-index: 4;
}
.DAccount__btn{
  background: #e3ebff00;
  color: #0D2159;
  border: 0px solid;
}
.edash{
  padding-top: 170px !important;
}

.not-authorised {
  border-color: red;
}

.talent__locations__head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.talent__locations__head h5 {
  color: #999;
}

.talent__stats {
  margin-bottom: 50px;
}
.talent__locations__body .talent__locations__row strong {
  color: #525050;
  font-size: 18px;
}
.talent__locations__body .talent__locations__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.landscape__form .ant-select-single.ant-select-sm {
  height: auto;
}