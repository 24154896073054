.hero {
  min-height: 100vh !important;
  height: auto !important;
  background-image: url("../../../../public/assets/images/home/banner.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 1;
}

.hero__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.57);
}

.hero__overlay__content {
  z-index: 1;
  position: static !important;
}

.hero__overlay__content__heading {
  color: var(--main);
  font-size: 55px;
  font-weight: 300;
  line-height: 55px;
  font-family: "Open Sans";
}

.hero__overlay__content__para {
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  max-width: 550px;
  font-family: "Open Sans";
  margin-top: 34px !important;
}

.hero__progress {
  max-width: 400px;
  width: 100%;
  z-index: 1;
}

.hero__inner {
  min-height: 100vh !important;
  height: auto !important;
}

.CircularProgressbar-text {
  fill: #A6DD50 !important;
  font-family: var(--fontFamily) !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

@media only screen and (max-width: 1400px) {
  .hero__overlay__content__heading {
    font-size: 40px;
    line-height: 43px;
  }

  .hero__overlay__content__para {
    max-width: 500px;
  }

  .hero__progress {
    max-width: 360px;
  }
}

@media only screen and (max-width: 1200px) {
  .hero__overlay__content__heading {
    font-size: 40px;
    line-height: 45px;
  }

  .hero__overlay__content__para {
    max-width: 450px;
  }

  .hero__progress {
    max-width: 330px;
  }
}

@media only screen and (max-width: 992px) {
  .hero__overlay__content__heading {
    font-size: 40px;
    line-height: 45px;
  }

  .hero__overlay__content__para {
    max-width: 450px;
  }

  .hero__progress {
    max-width: 330px;
  }

  .hero {
    min-height: 900px !important;
  }

  .hero__inner {
    min-height: 900px !important;
  }
}

@media only screen and (max-width: 768px) {
  .hero__overlay__content__heading {
    font-size: 40px;
    line-height: 45px;
  }

  .hero__overlay__content__para {
    max-width: 450px;
    margin-top: 28px !important;
  }

  .hero__progress {
    max-width: 330px;
  }
}

@media only screen and (max-width: 576px) {
  .hero__overlay__content {
    max-width: 318px;
  }

  .hero__overlay__content__heading {
    font-size: 26px;
    line-height: 30px;
  }

  .hero__progress {
    max-width: 330px;
  }
}

@media only screen and (max-width: 390px) {
  .hero__overlay__content {
    max-width: 318px;
  }

  .hero__overlay__content__heading {
    font-size: 32px;
    line-height: 37px;
  }

  .hero__progress {
    max-width: 315px;
  }
  .hero {
    min-height: 850px !important;
  }

  .hero__inner {
    min-height: 850px !important;
  }
}

@media only screen and (max-width: 350px) {
  .hero__overlay__content {
    max-width: 318px;
  }

  .hero__overlay__content__heading {
    font-size: 26px;
    line-height: 30px;
  }

  .hero__progress {
    max-width: 270px;
    width: 100%;
  }
}