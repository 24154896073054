.Navbar {
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 4 !important;
  height: 115px;
  transition: all 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.nav-user-icon {
  color: #fff;
  font-size: 24px;
  /* margin-top: -9px; */
  margin-right: 25px;
  display: flex;
  align-items: center;
  margin-top: 0px;
  padding: 8px;
  border-radius: 22px;
  position: relative;
}

.nav-user-icon img {
  width: 40px;
}
.navbar-demo-text {
  min-width: 170px !important;
  color: var(--main);
  justify-content: center !important;
  font-size: 16px;
}
.navbar-demo-text:hover{
  color: white;
}
.demo-text-blue:hover{
  color: #406703 !important;
}
.navbar-demo-button {
  min-width: 170px !important;
  border: 1px solid var(--main);
  color: var(--main);
  padding: 6px;
  justify-content: center !important;
  border-radius: 25px;
  font-size: 14px;
  margin-right: 25px;
  height: 40px;
}

.navbar-demo-button:hover {
  background-color: var(--main);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}

.Navbar__container {
  position: relative;
  height: 100%;
}

.Navbar__container__logo--img {
  max-width: 210px;
  width: 100%;
  text-decoration: none;
}

.home .offcanvas__list__item .home-active {
  color: var(--main);
}
.whyIndexcore .offcanvas__list__item .why-indexcore-active {
  color: var(--main);
}
.professionals .offcanvas__list__item .professionals-active {
  color: var(--main);
}

.enterprise .offcanvas__list__item .enterprise-active {
  color: var(--main);
}

.pricing .offcanvas__list__item .pricing-active {
  color: var(--main);
}

.about-us .offcanvas__list__item .about-us-active {
  color: var(--main);
}
.contact-us .offcanvas__list__item .contact-us-active {
  color: var(--main);
}
.individuals-get-started .offcanvas__list__item .individuals-get-started-active {
  color: var(--main);
}
.enterprise-signup .offcanvas__list__item .enterprise-signup-active{
  color: var(--main);
}

.nav-user-icon .user-account-icon {
  border: 1px solid var(--white);
  padding: 2px;
  border-radius: 50%;
  font-size: 26px;
}
.nav-user-icon{
  cursor: pointer;
}
.nav-user-icon small {
  font-size: 14px;
  margin: 0px 5px;
 
}

.nav-user-icon .user-arrow-icon {
  font-size: 14px;
}

.nav-user-icon:hover {
  background-color: rgb(255 255 255 / 20%);
}

.nav-user-icon:hover small {
  color: var(--main);
}
.blue__user .user-account-icon{
  border-color: var(--darkBlue) !important;
}
.blue__user:hover{
  background-color: rgba(111, 112, 110, 0.103);
}
.blue__user:hover small{
  color: #000000 !important;
}
.blue__user:hover .user-account-icon, .blue__user:hover .user-arrow-icon {
  color: #000000 !important;
  border-color: #000000 !important;
}
.nav-user-icon:hover .user-account-icon {
  color: var(--main);
  border-color: var(--main);
}

.nav-user-icon:hover .user-arrow-icon {
  color: var(--main);
  
}

.nav-user-icon:hover .nav__user__dropdown{
  display:block;
  z-index: 9999 !important;
}
.nav__user__dropdown {
  display: none;
  position: absolute;
  top: 0px;
  font-size: 14px;
  width: 250px;
  right: 0px;
  transition: all 0.3s ease-in-out;
  z-index: 9999 !important;

}
.hover--main{
  transition: all 0.3s ease-in-out;
}
.hover--main:hover{
  color: var(--main) !important;
}
.nav__user__dropdown__box {
  padding-top: 50px;
  position: relative !important;
  z-index: 9999 !important;
}

.nav__user__dropdown__box .nav__user__dropdown__box__top {
  box-shadow: rgb(56 56 57 / 30%) 0px 15px 43px !important;
  background: #000;
  padding: 15px 15px 7px 15px;
  border-radius: 8px 8px 0 0;
}

.nav__user__dropdown__box .nav__user__dropdown__box__top a {
  display: block;
  width: 100%;
  padding: 7px 0;
  text-decoration: none;
  color: var(--white);
  font-size: 16px;
}

.nav__user__dropdown__box .nav__user__dropdown__box__top a.nav__user__dropdown__login {
  text-align: center;
  color: var(--white);
  text-decoration: none;
  padding: 7px;
  background-color: var(--main);
  border-radius: 8px;
}

.nav__user__dropdown__box .nav__user__dropdown__box__top a svg {
  font-size: 24px;
}

.nav__user__dropdown__box .nav__user__dropdown__box__bottom {
  background-color: rgb(23 23 23);
  padding: 10px 15px;
  border-radius: 0 0 8px 8px;
}

.nav__user__dropdown__box .nav__user__dropdown__box__bottom a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

.nav__user__dropdown__box .nav__user__dropdown__box__bottom a svg {
  font-size: 24px;
}



/* new menu css */

.dropdown.desktop--nav ul {
  background: transparent;
  border: none;
  transform: none !IMPORTANT;
  top: 70px !important;
  right: 0px !important;
  left: unset !important;
}

.dropdown.desktop--nav ul li a {
  color: var(--white);
  text-align: right;
  width: 100%;
  text-decoration: none;
  font-size: 18px;
}

.dropdown.desktop--nav ul li {display: flex;justify-content: flex-end;}

.spacer-menu-item {
  height: 50px;
}

span.menu--group--hd {
  color: #969696;
  margin-bottom: 10px;
}


.dropdown.desktop--nav a:after {
  display: none;
}

.dropdown.desktop--nav a,.dropdown.desktop--nav a:hover,.dropdown.desktop--nav a:focus,.dropdown.desktop--nav a:active,.dropdown.desktop--nav a:focus {
  background-color: transparent;
  border: none;
}

.dropdown.desktop--nav a.btn.btn-secondary.dropdown-toggle,.dropdown.desktop--nav a.btn.btn-secondary.dropdown-toggle:focus,.dropdown.desktop--nav a.btn.btn-secondary.dropdown-toggle:hover,.dropdown.desktop--nav a.btn.btn-secondary.dropdown-toggle:active {
  padding: 0px;
  
}

.dropdown.desktop--nav a.btn.btn-secondary.dropdown-toggle .hide-menu-drop {display: none;}

.dropdown.desktop--nav a.btn.btn-secondary.dropdown-toggle.show .hide-menu-drop {
  display: block;
}

.dropdown.desktop--nav a.btn.btn-secondary.dropdown-toggle.show .show-menu-drop {
  display: none;
}

.dropdown.desktop--nav a.btn.btn-secondary.dropdown-toggle svg {
  font-size: 60px;
}



.home .desktop-new-nav .home-active {
  color: var(--main);
}

.professionals .desktop-new-nav .professionals-active {
  color: var(--main);
}

.enterprise .desktop-new-nav .enterprise-active {
  color: var(--main);
}

.pricing .desktop-new-nav .pricing-active {
  color: var(--main);
}

.about-us .desktop-new-nav .about-us-active {
  color: var(--main);
}

.individuals-get-started .desktop-new-nav .individuals-get-started-active {
  color: var(--main);
}

.screen-darken{
  content: ''; 
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,1);
  pointer-events: none;
  z-index:10; opacity:1; 
  visibility:visible;
  position: fixed;
}

.navbar{
  position: relative;
  z-index: 15;
}
.darkBlue-color{
  color: var(--darkBlue);
}

