.sc {
    min-height: 87vh;
    height: 100%;
}

.sc__right {
    background: linear-gradient(195deg, rgba(13, 33, 89, 1) 0%, rgba(25, 48, 112, 1) 59%, rgba(96, 139, 251, 1) 100%);
    max-width: 450px;
    width: 100%;
    position: relative;
}

.sc__right--img {
    max-width: 100%;
    width: 100%;
}

.sc__right__icon {
    background-color: rgba(255, 255, 255, 0.498);
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.sc__right__icon:hover {
    transform: scale(1.1);
}

.sc__right--img2 {
    width: 100%;
}

.sc__right2 {
    background: linear-gradient(333deg, rgba(143, 193, 81, 1) 0%, rgba(25, 48, 112, 1) 30%, rgba(25, 48, 112, 1) 100%);
}

.sc__right__submitext {
    max-width: 490px;
}
.ops{
    max-width: 400px;
    width: 100%;
}
@media only screen and (max-width: 400px) {
    .sc__right--img {
        max-width: 200px;
        width: 100%;
    }
}

.so__prof {
    background: linear-gradient(38deg, rgba(0, 0, 0, 1) 49%, rgba(138, 184, 66, 1) 100%);
    width: 67%;
    height: 420px;
    position: relative;
}
.so__prof2{
    background: linear-gradient(38deg, rgba(14, 33, 89, 1) 49%, rgba(61, 94, 182, 1) 100%);
}
.sc__prof__heading{
    font-size: 35px;
}
.sc__prof__para{
    max-width: 380px;
}
.sc__prof__para2{
    width: 50%;
    max-width: none;
}
.so__prof__link{
    background: rgba(255, 255, 255, 0.199);
}
.so__prof__link:hover{
    transform: scale(1.1);
}
.so_prof--img{
    height: 350px;
    position: absolute;
    right: 0;
    top: 40px;
}
.so_prof--img2{
    height: 365px;
    top: auto;
    bottom: 20px;
}

@media only screen and (max-width: 1400px){
    .so_prof--img2{
        height: 300px;
    }
    .ops{
        max-width: 350px;
        width: 100%;
    }
}
@media only screen and (max-width: 1200px){
    .so_prof--img{
        height: 280px;
    }
    .sc__prof__para{
        max-width: 300px;
    }
    .ops{
        max-width: none;
        width: 50%;
    }
}
@media only screen and (max-width: 992px){
    .so__prof{
        width: 100%;
    }
    .so_prof--img{
        height: 350px;
        position: static;
        margin-left: auto;
    }
    .sc__prof__para{
        max-width: 375px;
        width: 100%;
    }
    .ops{
        max-width: none;
        width: 90%;
    }
    .sc__right{
        max-width: none !important;
        width: 100% !important;
    }
    .sc__right--img{
        max-width: 80% !important;
    }
}
@media only screen and (max-width: 768px){
    .so_prof--img{
        height: 260px;
    }
}
@Media only screen and (max-width: 576px){
    .ops{
        max-width: none;
        width: 100%;
    }
}