.Experts {
  background: var(--lightBlue3);
}

.Experts__name {
  font-size: 18px;
  color: var(--darkblue);
}

.jobd__btn {
  height: 37px !important;
}

.Experts__name span {
  color: var(--darkGrey);
  font-size: 14px;
  display: block;
  font-weight: 300;
}

.Experts__arrow {
  color: var(--darkGrey);
  cursor: pointer;
  transition: all 0.1s linear;
}

.Experts__arrow.enterprise__experts__arrow {
  opacity: 1;
  font-size: 16px;
}

.Experts__title {
  color: var(--darkGrey);
  font-size: 14px;
  line-height: 16px;
}

.enterprise__experts.Experts__title {
  opacity: 1;
}

.candidates__fav {
  border: 1px solid #e7f8f9 !important;
  background: rgba(16, 187, 193, 0.0941176471);
  border-radius: 8px !important;
}

.candidates__all {
  border: 1px solid #f8f8f8 !important;
  background: #f8f8f8 !important;
  border-radius: 8px !important;
}

.candidates__all:hover {
  border-color: #cbcbcb !important;
  background: #f0f0f0 !important;
}

.candidates__fav:hover {
  background: #12d3db33 !important;
  border-color: #12d3db !important;
}

.Experts__search {
  border: 1px solid var(--bordergrey);
  height: 30px;
  background: var(--lightblue3);
  border-radius: 4px;
}

.candidates__icon1 {
  width: 17px;
}

.candidates__icon2 {
  width: 21px;
}

.Experts__search--input {
  border: 0px solid !important;
  background: rgba(0, 0, 0, 0) !important;
}

.Experts__search--input:focus {
  outline: none !important;
  border: 0px solid !important;
}

.Experts__search__icon {
  color: var(--darkTeal);
  cursor: pointer;
  font-size: 18px;
}

.Experts__title__box {
  border-radius: 8px !important;
  padding: 6px 10px;
  display: flex;
}



.meeting-box input[type='radio'] {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}



.meeting-box {
  position: relative;
}

.meeting-box input[type='radio']+div {
  border: 1px solid #ced4da;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
}

.meeting-box input[type='radio']:checked+div {
  border-color: #b2da66;
}

.meeting-box svg {
  color: #a6dd50;
  font-size: 28px;
  margin-right: 10px;
}

.meeting__scheduler {
  width: 100%;
}

.meeting__scheduler>div>div {
  width: 100%;
  max-height: 320px;
  overflow: hidden;
}

.meeting__scheduler .has-selection button {
  color: #fff !important
}

.slots--box {
  margin-left: 60px !important;
}

.slots--chips {
  background: #B2DA66;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-right: 5px;
}

.slots--heading {
  margin-bottom: 20px;
}

.react-calendar__tile--active {
  background: #B2DA66 !important;
}

.user__box {
  display: flex;
  align-items: center;
  cursor: 'pointer'
}

.verified__box {
  display: flex;
  align-items: center;
}

.verified__box span {
  margin-right: 10px;
}

.verified__box>div {
  width: 20px;
  height: 20px;
}

.indexScore__box>span {
  margin-left: 10px;
}

.location__box {
  background: #5F89FF;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
}

.candidate__score__button {
  min-width: 150px;
  height: 42px;
  color: #fff;
}

.scoring__popup__body {
  text-align: center;
  padding-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-color: #00000067;
  box-shadow: 0px 8px 10px #00000014;
}

.scoring__popup__body h4 {
  color: #4A4A49;
  font-weight: 400;
  margin-bottom: 0px;
}

.scoring__popup__body p {
  opacity: 0.5;
  color: #4A4A49;
}

.scoring__popup__body button {
  padding: 10px;
  color: #fff;
  background: #5F89FF;
  border-color: #5F89FF;
  margin-top: 30px;
}

.scoring__popup__body p.scoring__popup__pricing {
  color: #000000BE;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 20px;
  font-weight: 500;
}

.scoring__popup__body p.scoring__popup__pricing a {
  color: #000000BE;
}

.scoring__popup__footer {
  text-align: center;
  background: #F0F0F0;
  padding: 15px 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.scoring__popup__footer p {
  font-size: 12px;
}

.scoring__popup .ant-modal-content {
  padding: 0px;
  background: #F0F0F0;
}

.scoring__popup .ant-modal-title {
  padding-top: 20px;
  padding-left: 20px;
}

.scoring__popup .ant-modal-header {
  margin-bottom: 0px;
  background: #fff !important;
}

.sorting__menus {
  padding: 5px 10px;
  height: 40px;
  margin-right: 15px;
  border: 1px solid #41414080;
  border-radius: 8px;
  color: #0d2159;
}

.index__score {
  padding: 2px 10px;
  border-radius: 10px;
}

.index__score.rating__high {
  background: #A6DD50;
}

.index__score.rating__low {
  background: red;
}

.index__score.rating__average {
  background: #FFD0BC;
}


.menu___button {
  background: transparent;
}

.sorting__menus strong {
  font-weight: 500;
}

.candidates__menu {
  border: none;
  background: transparent;
  text-decoration: none;
}

.candidates__menu:hover {
  background: transparent;
  color: #000;
  box-shadow: none;
}

.candidates__menu:hover svg {
  text-decoration: none;
  color: #000;
}

.user__box {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .meeting__scheduler>div>div {
    max-height: max-content;
  }

  .Experts__title__box {
    border: 0.5px solid #41414080;
    max-width: 200px;
  }
}

.filters__group {
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px solid #41414080;
  min-width: 250px;
}

.filters__group__btn {
  width: 100px;
  background-color: white;
  color: #0d2159;
  font-weight: 500;
  padding: 6px 0px;
  min-width: 30px;
}

.filters__group__btn2 {
  border-left: 0.5px solid #41414080;
  border-right: 0.5px solid #41414080;
}

@media only screen and (max-width: 576px) {
  .filters__group {
    width: 100%;
  }

  .filters__group__btn {
    width: 33.3%;
  }

  .candidate__score__button {
    min-width: 130px;
    width: 100%;
  }
}

.candidates__filter {
  border: 0.5px solid #41414080;
  border-radius: 8px;
  padding: 0px 10px !important;
  color: #0d2159;
  background-color: white !important;
}

.Candidates__title {
  font-size: 12px !important;
  white-space: nowrap;
}

.high {
  padding: 2px;
  background: var(--main) !important;
  color: #436c03;
}

.average {
  padding: 2px;
  background: #e6e6e6;
  color: #4a4a49;
}

.low {
  padding: 2px;
  background: #f8d9ef;
  color: #fe0001;
}

.tag-high {
  padding: 6px;
  background: #b7face;
  color: #436c03;
}

.tag-average {
  padding: 6px;
  background: #ffd0bc;
  color: #bc5528;
}

.tag-low {
  padding: 6px;
  background: #f8d9ef;
  color: #fe0001;
}

.tag-14 {
  font-size: 12px;
  padding: 2px 10px;
  text-align: center;
  display: inline-block;
  width: auto;
  max-width: none;
}

.candidates__filter__icon {
  margin-top: 2px;
}

.candidates__email {
  border-top: 0.5px solid #41414044;
  border-bottom: 0.5px solid #41414044;
}

.bordertop--grey {
  border-bottom: 0.5px solid #41414044;
}

.ant-avatar-group>*:not(:first-child) {
  margin-inline-start: -18px !important;
}

.candidates__avatarGroup>*:not(:first-child) {
  margin-inline-start: -29px !important;
}

.avatar__left {
  max-width: 280px;
}

@media only screen and (max-width: 992px) {
  .candidates__avatarGroup>*:not(:first-child) {
    margin-inline-start: -22px !important;
  }
}

.repost__btn {
  color: white;
  background: transparent linear-gradient(0deg, #3e5fbb 0%, #2568ff 100%);
  border-radius: 8px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
}

.candidates__searchbox {
  border: 0.5px solid #41414080;
  border-radius: 8px;
  overflow: hidden;
  padding: 0px !important;
  min-width: 170px;
  width: 100%;
}

.candidates__searchbox__icon {
  color: #0d2159;
}

.candidates__searchbox__input {
  color: #0d2159;
  border: 0px solid !important;
  width: 100%;
  background-color: rgba(13, 33, 89, 0);
}

.candidates__searchbox__input::placeholder {
  color: #0d2159;
}

.candidates__searchbox__input:focus {
  outline: none;
}

.candidates .ant-radio-button-wrapper {
  line-height: 37px !important;
}

.candidate__content__left__para__note {
  color: var(--lightBlue2);
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.candidate__content__left__para__note svg {
  margin-right: 5px;
  font-size: 20px;
}

.candidate__content__right__items {
  display: flex;
  justify-content: flex-start;
}

.candidate__content__right__items>div {
  width: 50%;
}

.candidate__content__left {
  max-width: 700px;
  width: 100%;
}

.candidate__content__right {
  max-width: 450px;
  width: 100%;
}

.enterprise.candidate__content__right {
  max-width: 500px;
  width: 100%;
}

.candidate__content__label {
  font-weight: 500;
  color: var(--darkGrey);
  font-size: 14px;
}

.candidate__content__main {
  color: var(--main);
}

.prog-orange .progress-bar {
  background-color: #ff8955;
}

.prog-main .progress-bar {
  background-color: var(--main);
}

.prog-red .progress-bar {
  background-color: #fe0001;
}

.progressBar__candidates .progress-bar {
  height: 30px;
  border-radius: 40px;
}

.progressBar__candidates {
  height: 30px !important;
}

.progressBar__candidates__text {
  top: 5.5px;
  left: 10px;
  color: #4a4a49;
  font-size: 16px !important;
}

.avatars {
  display: inline-block;
  /* transform: scale(-1, 1); */
  transform: revert;
}

.avatar {
  position: relative;
  display: inline-block;
  border: 4px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 55px;
}

.avatar:not(:first-child) {
  /* margin-left: -60px; */
  margin-left: -28px;
}

.avatar img {
  width: 100%;
  display: block;
  transform: scale(-1, 1);
}

.members__data__invited {
  display: flex;
  align-items: center;
}

.members__data__invited .stacked__members__names {
  font-size: 14px;
  margin-left: 20px;
  color: var(--darkGrey);
  letter-spacing: -0.28px;
}

.avatar.add__new__member {
  height: 55px;
  background-color: var(--midGrey);
  text-align: center;
  font-size: 37px;
  line-height: 100%;
}

.avatar.add__new__member svg {
  color: var(--white);
}

/* candidate profile detail */

.verify__check__list {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

.verify__check__list .verify__check h4 {
  color: var(--darkBlue);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.verify__check__list .verify__check span {
  font-size: 18px;
  color: var(--main);
}

.verify__check__list .verify__check svg {
  color: var(--main);
  font-size: 18px;
}

.candidate__popup {
  width: 450px;
  padding: 15px;
}

.candidate__detail__popup {
  margin-bottom: 15px;
}

.MuiPopover-paper {
  box-shadow: none !important;
  border-radius: 12px !important;
  box-shadow: 0px 0px 15px #0000000d !important;
}

.candidate__popup__button {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.candidate__popup__check__profile {
  margin-right: 10px;
}

.candidate__popup__check__profile,
.candidate__popup__download {
  width: 50%;
}

.candidate__popup__check__profile {
  width: 190px;
  text-decoration: none;
  border: 1px solid var(--lightBlue2);
  border-radius: 8px !important;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  color: var(--lightBlue2);
  transition: all 0.3s ease-in-out;
  font-family: var(--fontFamily);
  font-size: 14px;
  height: 37px !important;
}

.candidate__popup__check__profile:hover {
  color: white;
  background-color: var(--lightBlue2);
}

.candidate__popup__download {
  width: 220px;
  display: flex;
  background-color: var(--lightBlue2);
  color: #fff;
  text-decoration: none;
  height: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  cursor: pointer;
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  height: 37px !important;
}

.candidate__popup__download:hover {
  text-decoration: none;
}

.candidate__popup__download img {
  width: 25px !important;
  margin-right: 5px;
}

.invite__popup {
  max-width: 390px;
  padding: 15px;
}

.invite__popup .Experts__name {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  letter-spacing: -0.32px;
  color: var(--black);
}

.invite__popup p {
  margin: 0px 0 30px 0;
  font-size: 16px;
  color: var(--darkGray);
}

.invite__popup input {
  width: 100%;
  height: 42px;
  border: 0.5px solid #969696;
  border-radius: 8px !important;
  padding-left: 10px;
}

.invite__popup button {
  width: 100%;
  margin-top: 5px;
  height: 42px;
  border: none;
  background-color: var(--lightBlue2);
  color: #fff;
  line-height: 40px;
  font-size: 16px;
  border-radius: 8px !important;
}

.invite__popup .candidate__detail__popup {
  margin: 0px;
}

.invite__popup.remove__invitee {
  width: 390px;
}

.invite__popup.remove__invitee .Experts__name.fm {
  margin-left: 10px;
}

.invite__popup.remove__invitee .candidate__detail__popup {
  margin-bottom: 30px;
}

.invite__popup.remove__invitee .invite__poup__check__profile button {
  background-color: transparent;
  border: 1px solid red;
  color: red;
  line-height: 36px;
  border-radius: 12px;
}

.invite__popup.remove__invitee .invite__poup__check__profile button svg {
  font-size: 23px;
  margin-top: -5px;
  margin-left: 10px;
  font-weight: 400;
}

.invite__popup.remove__invitee .Experts__name.fm span {
  color: var(--midGrey);
}

.grey__out {
  color: var(--midGrey);
}

.button__to__add__member {
  border: 1px solid #b2da66;
  padding: 6px 20px;
  border-radius: 8px;
  background-color: #b2da66;
  color: #fff;
}

.button__to__add__member:hover {
  color: #b2da66;
  border-color: #b2da66;
  background: #fff;
}

@media only screen and (max-width: 1400px) {
  .candidate__content__right__items>div {
    font-size: 12px;
  }

  .candidate__content__label {
    font-size: 12px;
  }

  .candidate__content__right {
    max-width: 250px;
  }
}

@media only screen and (max-width: 1200px) {
  .Experts__name {
    font-size: 16px;
  }

  .Experts__title {
    font-size: 12px;
  }
}

@media only screen and (max-width: 992px) {
  .Experts__title {
    font-size: 10px;
    line-height: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .Experts__title {
    font-size: 8px;
    line-height: 10px;
  }

  .Experts__search {
    height: 25px;
  }

  .Experts__search--input {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {
  .Experts__search {
    height: 25px;
  }

  .candidate__popup__check__profile {
    margin-right: 0px;
  }

  .candidate__popup__check__profile,
  .candidate__popup__download {
    width: 100%;
  }

  .candidate__popup__check__profile a,
  .candidate__popup__download a {
    width: 100%;
    padding: 0px 20px;
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 10px;
  }

  .Experts__search--input {
    font-size: 12px;
  }

  .Experts__top {
    display: flex !important;
  }

  .candidate__popup {
    max-width: 450px;
    width: 100%;
    padding: 15px;
  }

  .candidate__popup__button {
    flex-wrap: wrap;
    display: block;
  }

  .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    width: 100% !important;
  }
}

.fw-500 {
  font-weight: 500;
}

@media only screen and (max-width: 340px) {

  .Experts__top {
    display: block !important;
  }

  .Experts__search {
    margin-bottom: 20px;
  }
}

.candidate__content {
  background: var(--lightGrey2);
  border-radius: 8px;
}

.candidate__popup__check__profile a,
.candidate__popup__download a {
  width: 100%;
  padding: 0px 10px;
  font-size: 14px;
  white-space: nowrap;
}

.candidate__content__left__heading {
  color: var(--black) !important;
  font-size: 40px !important;
  font-weight: bold !important;
  letter-spacing: -1.88px !important;
}

.candidate__content__left__para {
  line-height: 24px !important;
}

.candidate__content__right__iconContainer__icon {
  font-size: 24px;
}

.indexCore__score__heading {
  color: var(--black) !important;
  font-weight: normal !important;
  font-size: 20px !important;
}

.indexCore__score__progressBar__container {
  height: 12px !important;
}

.favoriteCandidates__heading {
  font-size: 22px;
  color: var(--darkTeal);
  letter-spacing: -0.38px;
}

.favoriteCandidates__heading--span {
  color: var(--lightGrey);
}

.favoriteCandidates__border {
  border-bottom: 1px solid rgba(97, 207, 217, 0.3607843137);
}

.meeting__container__step {
  color: #5f89ff;
}

abbr[title] {
  text-decoration: none !important;
  font-family: var(--fontFamily) !important;
  font-weight: normal !important;
}

.react-calendar {
  border: 0px solid !important;
  max-width: 350px !important;
  width: 100% !important;
}

.react-calendar__navigation {
  border-bottom: 1px solid #4a4a49a0 !important;
}

.react-calendar__navigation button {
  background: none !important;
  white-space: nowrap !important;
}

.react-calendar__tile {
  font-family: var(--fontFamily) !important;
  font-weight: 600 !important;
  border-radius: 12px !important;
  transition: all 0.3s ease-in-out !important;
  padding: 9px 0px !important;
  width: 30px !important;
  height: 35px !important;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #e6e6e6 !important;
}

.react-calendar__month-view__days__day--neighboringMonth:hover {
  color: #e6e6e6 !important;
  background: none !important;
}

.react-calendar__tile--active {
  background: #10bac1 !important;
  color: white !important;
}

.timeSchedule {
  max-width: 350px;
  width: 100%;
  height: 265px;
  overflow-y: auto;
}

.timeSchedule--div {
  border: 0.5px solid #969696;
  border-radius: 4px;
  color: #969696;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  user-select: none;
}

.timeSchedule::-webkit-scrollbar {
  width: 11px !important;
}

.timeSchedule::-webkit-scrollbar-track {
  background: #e6e6e6 !important;
  border-radius: 20px !important;
}

.timeSchedule::-webkit-scrollbar-thumb {
  background-color: #fff !important;
  border-radius: 20px !important;
  border: 3px solid #e6e6e6 !important;
}

.timeSchedule__top {
  max-width: 330px;
  border-bottom: 1px solid #4a4a49a0;
}

.meetingDetails {
  max-width: 350px;
  width: 100%;
  min-height: 265px;
}

.meetingDetails__top {
  border-bottom: 1px solid #4a4a49a0;
}

.meetingdetails--textarea {
  border-color: #969696;
}

.meetingdetails--textarea:focus {
  border-color: #969696 !important;
  outline: none !important;
}

.meetingdetails--input {
  border: 0.5px solid #cbcbcb !important;
  outline: none !important;
  border-radius: 20px;
  max-width: 100px;
  font-size: 12px;
}

.meetingdetails--input:focus {
  outline: none !important;
}

.email__tag {
  border: 0.5px solid #cbcbcb !important;
  border-radius: 20px;
}

.meetingConfirm {
  max-width: 430px;
  width: 100%;
}

.border-darkGrey {
  border-color: var(--darkGray) !important;
}

.meetingConfirm__body {
  border-bottom: 1px solid #4a4a49a0;
  border-top: 1px solid #4a4a49a0;
}

.meetingDate--img {
  width: 40px;
  box-shadow: 0px 0px 15px #0000000d;
}

.meetingDate--ul {
  margin-top: 45px;
}

@media only screen and (max-width: 375px) {
  .meetingDate {
    flex-wrap: wrap;
  }

  .meetingDate--ul {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0px !important;
  }
}


/* pipeline starts */

.pipeline__fav {
  color: white;
  border-radius: 8px 8px 0px 0px;
  letter-spacing: -0.36px;
  font-weight: 600;
}

.pipeline__num {
  color: #12d3db;
  border-radius: 8px 8px 0px 0px;
  letter-spacing: -0.36px;
  height: 50px;
}

.bg__12D3DB {
  background-color: #12d3db;
}

.bg__12D3DB__light {
  background-color: #12d4db3d;
  color: #12d3db;
}

.bg__A6DD50 {
  background-color: #a6dd50 !important;
}

.bg__A6DD50__light {
  background-color: #a7dd5043 !important;
  color: #a6dd50;
}

.bg__0D2159 {
  background-color: #0d2159 !important;
}

.bg__0D2159__light {
  background-color: #0d215943 !important;
  color: #0d2159;
}

.bg__486FD9 {
  background-color: #486fd9 !important;
}


.pipeline__cardBody {
  overflow: hidden;
  border-radius: 8px;
}

.pipeline__cardBody--img {
  max-width: 30px;
  width: 100%;
  overflow: hidden;
}

.pipeline__cards {
  background-color: #e6e6e683;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.candidates__avatar {
  width: 50px;
  height: 50px;
}

.candidates__avatar--img {
  width: 35px;
}

.dropdown__filter {
  border: 0.5px solid #5F89FF !important;
  box-shadow: 0px 4px 30px #0000000B !important;
  width: 250px !important;
  border-radius: 8px !important;
}

.dropdown__filter .ant-dropdown-menu-item-active {
  background-color: white !important;
}

.dropdown--input {
  border: 0px solid;
  border-bottom: 1px solid #C4C4C4;
}

.dropdown--input:focus {
  outline: none;
}

.dropdown--input::placeholder {
  color: #CBCBCB;
}

.dropdown__list {
  height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dropdown__list::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 20px !important;
}

.dropdown__list::-webkit-scrollbar-track {
  background: #E5E5E5 !important;
  border-radius: 20px !important;
}

.dropdown__list::-webkit-scrollbar-thumb {
  background-color: #4a4a494d !important;
  border-radius: 20px !important;
  border-radius: 2px solid #E5E5E5 !important;
}

.dropdown__list__item {
  color: #969696;
}

.filter__right {
  width: 100%;
}

.candidates__searchbox2 {
  max-width: 250px;
}

@media only screen and (max-width: 576px) {
  .candidates__searchbox2 {
    max-width: 100%;
  }

  .repost__btn2 {
    width: 100%;
  }

  .team__member__right__btn {
    width: 100%;
  }
}

.team__member {
  background: #F0F0F0;
  border: 1px solid #CBCBCB;
  border-radius: 10px;
}

.team__member__right__btn {
  background: #969696 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

.meeting__box,
.meeting__option {
  border: 1px solid #ced4da;
  border-radius: 8px;
}

.meeting__box__inner {
  border-bottom: 1px solid #ced4da;
}

.meeting__box__inner__avatar {
  width: 20px;
}

.meeting__box__inner__btn {
  background-color: white;
  border: 0px solid;
  outline: none;
  color: red;
}

.meeting__container {
  max-width: 650px !important;
  width: 100%;
}

.react-calendar {
  border-bottom: 1px solid #4a4a49a0 !important;
  padding-bottom: 13px !important;
}

.meeting__btn1 {
  border: 0px solid;
  background-color: white;
}

.meeting__time {
  border-bottom: 1px solid #4a4a49a0 !important;
}

.meeting__company {
  max-width: 480px;
  width: 100%;
  border: 0.5px solid #4A4A49;
}

.suggest__btn {
  max-width: 220px !important;
  width: 100% !important;
}

.prof-profile {
  margin-left: 0px !important;
}

@media only screen and (max-width: 992px) {
  .prof-profile {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 576px) {
  .suggest__btn {
    max-width: 100% !important;
    width: 100% !important;
  }

}

/* kanban */
.react-kanban-column {
  padding: 0px !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  margin-bottom: 10px !important;
}

.react-kanban-column .custom-card {
  margin: 10px !important;
}

.react-kanban-column-header {
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
  padding-right: 10px !important;

}

.react-kanban-board {
  overflow: hidden !important;
}

.react-kanban-column-header__spacer {
  padding: 10px !important;
  width: auto !important;
  border-top-right-radius: 0px !important;
  font-family: var(--fontFamily) !important;
  font-weight: 600 !important;
  color: var(--darkGrey) !important;
}

.card-count {
  color: #9E9E9D !important;
  font-size: 14px !important;
  background-color: #DBDBDB !important;
  border-radius: 6px;
  width: 20px !important;
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__dot{
  width: 10px;
  height: 10px;
}
.react-kanban-board .candidates__avatar--img{
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}
.react-kanban-board .candidates__avatar--img img{
  object-fit: cover;
  width: 100%;
  height:40px;
}
.react-kanban-board>div>div:nth-child(1) .react-kanban-column-header .card__dot{
  background-color: #4A4A49 !important;
}
.react-kanban-board>div>div:nth-child(2) .react-kanban-column-header .card__dot{
  background-color: #618CFC !important;
}
.react-kanban-board>div>div:nth-child(3) .react-kanban-column-header .card__dot{
  background-color: #11D3DB !important;
}
.react-kanban-board>div>div:nth-child(4) .react-kanban-column-header .card__dot{
  background-color: #A6DD50 !important;
}
.react-kanban-board>div>div:nth-child(5) .react-kanban-column-header .card__dot{
  background-color: #FE0001 !important;
}


.react-kanban-board>div>div:nth-child(1) .react-kanban-column-header__spacer {
  background-color: #a7dd5000 !important;

}

.react-kanban-board>div>div:nth-child(2) .react-kanban-column-header__spacer {
  background-color: #0d215900 !important;
}

.react-kanban-board .react-kanban-column:nth-child(1) {
  background-color: #F9F9F9 !important;
}

.react-kanban-board .react-kanban-column:nth-child(2) {
  background-color: #E7EEFF !important;
}

.react-kanban-board .react-kanban-column:nth-child(3) {
  background-color: #E7FBFC !important;
}

.react-kanban-board .react-kanban-column:nth-child(4) {
  background-color: #F6FCEE !important;
}

.react-kanban-board .react-kanban-column:nth-child(5) {
  background-color: #FFECEC !important;
}

.react-kanban-board>div>div:nth-child(4) .react-kanban-column-header__spacer {
  background-color: #12d4db00 !important;
}

.react-kanban-board>div>div:nth-child(5) .react-kanban-column-header__spacer {
  background-color: rgba(255, 0, 0, 0) !important;
}

.react-kanban-board>div>div:nth-child(1) .react-kanban-column-header {
  background-color: #F9F9F9 !important;
}

.react-kanban-board>div>div:nth-child(2) .react-kanban-column-header {
  background-color: #E7EEFF !important;
}

.react-kanban-board>div>div:nth-child(4) .react-kanban-column-header {
  background-color: #F6FCEE !important;
}

.react-kanban-board>div>div:nth-child(5) .react-kanban-column-header {
  background-color: #FFECEC !important;
}

.react-kanban-column .custom-card {
  border-radius: 8px;
  overflow: hidden !important;
  padding: 15px !important;
  border: 0.5px solid #4A4A4980 !important;
}

.react-kanban-column .custom-card .ant-progress-text {
  display: none !important;
}

.react-kanban-column .custom-card .ant-progress-outer {
  padding-right: 0px !important;
}

.react-kanban-column .custom-card .ant-progress-inner {
  margin-top: -20px !important;
}

.react-kanban-column .custom-card .ant-avatar {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}

.react-kanban-column .custom-card .relevance {
  background-color: #B7FACE;
  color: #4C7712;
  padding: 3px 10px;
}

.score__text {
  padding: 2px 10px;
  font-family: var(--fontFamily) !important;
  font-size: 12px !important;
}

.score__text.prog-red {
  background-color: #FFD0BC;
  font-size: 12px !important;
}

.score__text.prog-orange {
  background-color: #f8ac3a;
  font-size: 12px !important;
}

.score__text.prog-main {
  background-color: #A6DD50;
  font-size: 12px !important;
}

.candidates .ant-radio-button-wrapper {
  border: 0.5px solid #41414080 !important;
  color: var(--darkBlue) !important;
  font-family: var(--fontFamily) !important;
}

.candidates .ant-radio-button-wrapper-checked::before {
  background-color: transparent !important;
}

.candidates .ant-radio-button-wrapper-checked {
  background-color: #F4F5F7 !important;
  color: #0D2159 !important;
  font-weight: 600 !important;
}

.candidates .ant-radio-button-wrapper:hover {
  color: #0D2159 !important;
  background-color: #F4F5F7 !important;
}

.ant-radio-button-wrapper>.ant-radio-button>span {
  margin-top: 10px !important;
}

.ant-radio-button-wrapper:focus-visible {
  box-shadow: none !important;
}

@media only screen and (max-width: 576px) {
  .ant-radio-button-wrapper {
    font-size: 14px !important;
  }
}

.ant-table-wrapper .ant-table-column-title {
  display: inline-block !important;
  flex: none !important;
}

.ant-table-wrapper .ant-table-column-sorters {
  display: flex !important;
  justify-content: start !important;
}

.ant-table-wrapper .ant-table-column-title,
.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child,
.ant-table-wrapper .ant-table-thead>tr>th {
  font-size: 12px !important;
  color: #4A4A49 !important;
}

.react-kanban-board::-webkit-scrollbar {
  width: 6px !important;
}

.react-kanban-board::-webkit-scrollbar-track {
  background: #f8f8f8 !important;
}

.react-kanban-board::-webkit-scrollbar-thumb {
  background-color: var(--darkGrey) !important;
  border-radius: 20px !important;
  border: 5px solid #f8f8f8 !important;
}

@media only screen and (min-width: 1200px) {
  .react-kanban-board {
    max-width: 100% !important;
    min-width: 1100px !important;
    width: 100% !important;
    overflow-x: auto !important;
  }

  :has(> .react-kanban-column) {
    flex-wrap: nowrap !important;
    justify-content: start !important;
  }

  .react-kanban-column {
    min-width: 325px !important;
    width: auto !important;
  }

  .react-kanban-column:last-child {
    min-width: 325px !important;
    width: auto !important;
  }
}
@media only screen and (min-width: 1400px) {
  .react-kanban-board {
    max-width: 100% !important;
    min-width: 1100px !important;
    width: 100% !important;
    overflow-x: auto !important;
  }

  :has(> .react-kanban-column) {
    flex-wrap: nowrap !important;
    justify-content: start !important;
  }

  .react-kanban-column {
    min-width: 400px !important;
    width: auto !important;
  }

  .react-kanban-column:last-child {
    min-width: 425px !important;
    width: auto !important;
  }
}