.Experts {
  background: var(--lightBlue3);
}

.candidates__button {
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  height: 40px !important;
  box-shadow: 0px 0px 15px #0000000D;
  background: #F8F8F8;
}

.candidates__button__schedule {
  background: #12D3DB;
}

.candidates__button__icon {
  font-size: 20px !important;
}

.candidates__button--img {
  width: 20px;
}

.Experts__name {
  font-size: 16px;
  color: var(--darkblue);
}

.Experts__arrow {
  color: var(--darkGrey);
  opacity: 0.4;
  font-size: 22px;
  cursor: pointer;
  transition: all 0.1s linear;
}

.Experts__arrow:hover {
  transform: scale(1.3, 1.3);
}
.prof__para{
  font-size: 14px !important;
  line-height: 22px !important;
}
.Experts__title {
  color: var(--darkGrey);
  opacity: 0.4;
  font-size: 14px;
  line-height: 16px;
}

.Experts__row {
  border: 1px solid var(--bordergrey);
  background: rgba(16, 187, 193, 0.0941176471);
  border-radius: 18px;
}

.Experts__search {
  border: 1px solid var(--bordergrey);
  height: 30px;
  background: var(--lightblue3);
  border-radius: 4px;
}

.Experts__search--input {
  border: 0px solid !important;
  background: rgba(0, 0, 0, 0) !important;
}

.Experts__search--input:focus {
  outline: none !important;
  border: 0px solid !important;
}

.Experts__search__icon {
  color: var(--darkTeal);
  cursor: pointer;
  font-size: 16px;
}

.pro__profile__icons__top ul {
  list-style: none;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
}

.pro__profile__icons__top ul li {
  margin: 0px 5px;
}

.pro__profile__icons__top ul li .pro__profile__back {
  width: 100px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 37px;
  border: 1px solid var(--darkGrey);
  color: var(--darkGrey);
  text-decoration: none;
  padding: 0px;
  border-radius: 8px;
  font-size: 16px;
}

.pro__profile__icons__top ul li .pro__profile__back:hover {
  background-color: var(--darkGrey);
  color: #fff;
}

.pro__profile__icons__top ul li .pro__profile__edit {
  width: 100px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 37px;
  border: 1px solid var(--lightBlue2);
  color: var(--lightBlue2);
  text-decoration: none;
  padding: 0px;
  border-radius: 8px;
  font-size: 16px;
}

.pro__profile__icons__top ul li .pro__profile__edit:hover {
  background-color: var(--lightBlue2);
  color: #fff;
}

.pro__profile__icons__top ul li .pro__profile__save {
  width: 100px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 37px;
  border: 1px solid #10BAC1;
  color: var(--white);
  background-color: #10BAC1;
  text-decoration: none;
  padding: 0px;
  border-radius: 8px;
  font-size: 16px;
}

.pro__profile__icons__top ul li .pro__profile__save:hover {
  background-color: var(--white);
  color: #10BAC1;
}

.profile__left {
  border: 0.5px solid #E6E6E6;
}

.pro__profile__header__inner {
  background-color: #CBCBCB40;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #70707040;
}

.pro__profile__editBox {
  display: flex;
  background-color: #4a4a491f;
  cursor: pointer;
  width: 60px;
  flex-direction: row;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.pro__profile__editBox svg {
  font-size: 14px;
  margin-right: 5px;
}

.pro__profile__header__inner__top {
  display: flex;
  justify-content: flex-end;
}

.pro__profile__userInfo {
  display: flex;
  align-items: center;
  margin-top: -15px;
}

.pro__profile__userInfo .pro__user__imageBox {
  font-size: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 15px;
  margin-top: -10px;
}

.pro__profile__userInfo .pro__user__name h4 {
  font-size: 36px;
  color: var(--darkBlue);
  letter-spacing: -0.72px;
}

.pro__profile__coverInfo p {
  color: var(--darkGrey);
  letter-spacing: -0.32px;
  font-size: 16px;
}



.pro__profil__addMore {
  border: 1px solid var(--darkGrey);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
}

.pro__yourProfile__progressBar {
  border: 0.5px solid var(--midGrey);
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  border-radius: 12px;
}

.pro__yourProfile__progressBar__inner {
  display: block;
}

.pro__yourProfile__progressBar__inner .CircularProgressbar.progressBars {
  width: 300px;
  max-width: 100%;
  margin: 0px;
}

.pro__yourProfile__progressBar__inner .CircularProgressbar-text {
  display: none;
}

.pro__yourProfile__progressBar__inner .yourIndex__pro__index_score {
  margin: 0px !important;
}

.work__exp__box__header {
  margin-top: 40px;
}


.work__exp__box__item {
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid #70707070;
  padding-top: 30px;
  padding-bottom: 30px;
}

.work__exp__box__item:first-child {
  border-top: none;
}

.work__exp__box__item__content {
  display: flex;
  width: 100%;
}

.work__exp__box__para {
  width: 90%;
}

.work__exp__box__company__info {
  padding: 0px 20px;
}

.work__exp__box__company__info h5 {
  font-size: 18px;
  letter-spacing: -0.36px;
  color: var(--darkGrey);
}

.work__exp__box__company__info p {
  letter-spacing: -0.32px;
  font-size: 16px;
}

.lightBlueBar__content__icon__image2 {
  border: 1px solid #70707080;
  border-radius: 8px;
  padding: 0px;
  max-width: 70px;
}

.work__exp__box__list {
  padding-bottom: 40px;
}



.reference__item .work__exp__box__company__info {
  padding-left: 0px;
}

.reference__item .work__exp__box__item__duration {
  display: flex;
  width: 40%;
  justify-content: flex-end;
}

.reference__item .work__exp__box__item__content {
  width: 60%;
}

.reference__verification {
  margin-right: 20px;
}

.reference__verification--main {
  color: var(--main) !important;
}

.reference__verification {
  color: #FF8D1C !important;
}

.reference__verification--red {
  color: #FF0202 !important;
}


.work__exp__box__item.edu__and__qualif__item .work__exp__box__company__info {
  padding-left: 0px;
}

.work__exp__box__item.edu__and__qualif__item .work__exp__box__item__duration p {
  margin-bottom: 5px;
}

.work__exp__box__item.edu__and__qualif__item .work__exp__box__item__duration p.edu__classInfo {
  font-size: 18px;
  color: var(--darkGrey);
  font-weight: 300;
}

.work__exp__box__item.edu__and__qualif__item .work__exp__box__item__duration {
  text-align: right;
}

.pro__profile__editBox.reupload {
  width: 100px;
}


.yourProfile__videoUpload {
  height: 400px;
  background: #f8f8f8;
  margin: 30px 0px 20px 0px;
}


.pro__profile__editBox.reupload {
  width: 100px;
}

.work__exp__percent {
  height: 90%;
  background: #a7dd501d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  font-size: 60px;
  font-weight: 600;
  margin-left: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 90%;
}

.work__exp__percent.achieved {
  color: var(--main);
}

.work__exp__percent.pending {
  color: #FF8955;
  background-color: #fff3ee;
}

.work__exp__box__header.work__exp__online__presence {
  margin-bottom: 20px;
}

.work__exp__box__header.work__exp__open__questions {
  margin-bottom: 20px;
}

.work__exp__box.open__questions__box {
  margin-bottom: 40px;
}

.work__exp__box__header.work__exp__online__presence {
  margin-bottom: 20px;
}

.work__exp__box__header.work__exp__open__questions {
  margin-bottom: 20px;
}

.yourprofile__form__buttons {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.yourprofile__form__buttons .back__button {
  height: 50px;
  display: block;
  width: 260px;
  text-align: center;
  text-decoration: none;
  border: 0.5px solid var(--darkGrey);
  line-height: 45px;
  color: var(--darkGrey);
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px;
  margin-right: 20px;
}

.yourprofile__form__buttons .back__button:hover {
  color: var(--white);
  background-color: var(--darkGrey);
}

.yourprofile__form__buttons .save__button {
  height: 50px;
  display: block;
  width: 260px;
  text-align: center;
  text-decoration: none;
  border: 0.5px solid #10BAC1;
  line-height: 45px;
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px;
  margin-right: 20px;
  background-color: #10BAC1;
}

.save__button,
.back__button {
  max-width: 180px;
}

.yourprofile__form__buttons .save__button:hover {
  color: #10BAC1;
  background-color: var(--white);
}

.locked-button .invitation-button.accept {
  position: relative;
}

.locked-button .invitation-button.accept .locked {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #14a800a8;
  border-radius: 30px;
  color: #000;
  font-size: 24px;
}
.download-pack-button {
  background-color: var(--main);
  border-radius: 8px;
}
.comment__image{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}
@media only screen and (max-width: 1400px) {
  .pro__yourProfile__progressBar__inner .CircularProgressbar.progressBars {
    width: 240px;
    margin: auto;
  }

  .pro__yourProfile__progressBar__inner .yourIndex__pro__index_score {
    margin-bottom: -100px !important;
  }

  .pro__profile__coverInfo p {
    font-size: 14px;
  }

  .pro__profile__userInfo .pro__user__name h4 {
    font-size: 28px;
  }

  .lightBlueBar__content__icon__image2 {
    max-width: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .Experts__name {
    font-size: 16px;
    font-weight: 600;
  }

  .lightBlueBar__content__icon__image2 {
    max-width: 50px;
  }

  .Experts__title {
    font-size: 12px;
  }
}

@media only screen and (max-width: 992px) {

  .Experts__title {
    font-size: 10px;
    line-height: 12px;
  }
}

@media only screen and (max-width: 768px) {

  .Experts__title {
    font-size: 8px;
    line-height: 10px;
  }

  .Experts__search {
    height: 25px;
  }

  .Experts__search--input {
    font-size: 14px;
  }

  .work__exp__box__list {
    padding-bottom: 0px;
  }

  .candidates__button {
    height: 40px !important;
  }

  .candidates__button__schedule {
    font-size: 14px;
  }

  .candidates__button__icon {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 576px) {

  .Experts__search {
    height: 25px;
  }

  .Experts__search--input {
    font-size: 12px;
  }

  .Experts__top {
    display: flex !important;
  }

  .pro__profile__icons__top ul {
    flex-wrap: wrap;
  }

  .pro__profile__icons__top ul li .pro__profile__save,
  .pro__profile__icons__top ul li .pro__profile__edit,
  .pro__profile__icons__top ul li .pro__profile__back {
    margin-bottom: 10px;
    min-width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .pro__profile__userInfo .pro__user__name h4 {
    font-size: 25px;
    line-height: 25px;
  }

  .work__exp__box__para {
    width: 100%;
  }
}

@media only screen and (max-width: 340px) {
  .Experts__top {
    display: block !important;
  }

  .Experts__search {
    margin-bottom: 20px;
  }
}

.candidate__content {
  background: var(--lightGrey2);
  border-radius: 18px;
}

.candidate__content__left {
  max-width: 800px;
  width: 100%;
}

.candidate__content__left__heading {
  color: var(--black) !important;
  font-size: 40px !important;
  font-weight: bold !important;
  letter-spacing: -1.88px !important;
}

.candidate__content__left__para {
  line-height: 24px !important;
}

.candidate__content__right {
  max-width: 250px;
  width: 100%;
}

.candidate__content__right__iconContainer__icon {
  font-size: 24px;
}

.indexCore__score__heading {
  color: var(--black) !important;
  font-weight: normal !important;
  font-size: 20px !important;
}

.indexCore__score__progressBar__container {
  height: 12px !important;
}

.favoriteCandidates__heading {
  font-size: 22px;
  color: var(--darkTeal);
  letter-spacing: -0.38px;
}

.favoriteCandidates__heading--span {
  color: var(--lightGrey);
}

.favoriteCandidates__border {
  border-bottom: 1px solid rgba(97, 207, 217, 0.3607843137);
}

.candidates__button--img1 {
  width: 15px;
}

.profile__icon {
  background: #E6E6E6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.profile__icon--img {
  width: 20px;
}

.profile__icon--img1 {
  width: 17px;
}

.profile__add {
  width: 23px;
}

.profile__left__top,
.profile__left__circular,
.profile__left__purpose,
.border-05 {
  border-bottom: 0.5px solid #E6E6E6;
}

.profile__right {
  border: 0.5px solid #E6E6E6;
}

.indexcore_score {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
}

.profile__right .ant-tabs-nav-wrap {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.profile__cell{
  border: 0.5px solid #40414180;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 5px;
  width: 32px;
  height: 32px;
}
.profile__cell img{
  width: 20px;
  height: 20px;
}
.profile__cell__last{
  border: 0.5px solid #40414180;
  border-radius: 5px;
  height: 32px;
  min-width:300px;
}
.profile__cell__last--div1{
  border-right: 0.5px solid #40414180;
}
.bg-lightBlue{
  background: #E7EEFF;
}
.grey{
  color: #4a4a4985;
}
.profile__icon__del{
  background: #FFDDDD !important;
}
.profile__cell__last__avatar{
  width: 20px;
  height: 20px;
}
.profile__right .ant-tabs-tab-active::before {
  background: none !important;
  background-color: transparent !important;
  content: none !important;
}
.added{
  color: red !important;
}

.profile__right .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--darkBlue) !important;
}

.profile__right .ant-tabs-tab:hover {
  color: var(--darkBlue) !important;
}

.profile__right .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 22px !important;
}

.filter__content__tag__content-verified {
  color: #436C03 !important;
  background: #B7FACE !important;
}

.profile__social {
  max-width: 150px;
  width: 15%;
  height: 100px;
  background: #e6e6e644 0% 0% no-repeat padding-box;
  border: 0.5px solid #70707040;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
}

.profile__social--img {
  width: 40px;
}

.profile__social--img2 {
  width: 60px;
}

.profile__social__overlay {
  background: #E6E6E6;
  opacity: 0;
  border-radius: 6px;
}

.profile__social:hover .profile__social__overlay {
  opacity: 1;
}
.candidates-profile-avatar{
  max-width: 100% !important;
  height: 100px !important;

}
.profile__avatar--img{
  object-fit: cover !important;
}
.candidates-profile-avatar-cont{
  max-width: 120px !important;
  height: 120px !important;
}
@media only screen and (max-width: 1500px) {
  .profile__social {
    width: 18%;
    height: 110px;
  }
}


@media only screen and (max-width: 1200px) {
  .profile__social {
    width: 23%;
    height: 110px;
  }
}

@media only screen and (max-width: 576px) {
  .profile__social--img {
    max-width: 35px;
    width: 100%;
  }

  .profile__social {
    width: 31%;
    height: 110px;
  }
}

@media only screen and (max-width: 410px) {
  .profile__social--img {
    max-width: 35px;
    width: 100%;
  }

  .profile__social {
    width: 46%;
    height: 110px;
    justify-content: center;
  }
}

.bluegradient__btn {
  background: transparent linear-gradient(0deg, #3E5FBB 0%, #2568FF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #0000000D;
  border: 1px solid #486FD9;
  border-radius: 8px;
}

.profile__comment {
  border: 0.5px solid #70707040;
  border-radius: 6px;
}

.lightblue__btn {
  background: #bfd0ff79 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

.profile__comment .ant-avatar-group>*:not(:first-child) {
  margin-inline-start: 2px !important;
}

.bg-pastel {
  background: #bfd0ff79 0% 0% no-repeat padding-box;
  border-radius: 6px;
}

.profile__comment--input {
  width: 100%;
  border: 0.5px solid #70707040;
  border-radius: 6px;
}

.profile__comment--input:focus {
  border: 1px solid #486FD9 !important;
}

.profile__more {
  width: 33px;
  height: 33px;
  border: 0.5px solid #70707040;
  border-radius: 8px;
  background: white;
}

.profile__btn {
  border: 0.5px solid #70707040;
  border-radius: 8px;
  background: white;
  height: 33px;
}

.message_index {
  background: #E6E6E6 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 20px;
  line-height: 18px;
  height: 19px;
}

.profile__avatar {
  max-width: 150px;
  width: 100%;
  height: 150px;
}

.profile__avatar--img {
  max-width: 90px;
  width: 100%;
}

.pro-profile-container {
  padding-top: 80px;
  margin-top: -80px;
}

@media only screen and (max-width: 1400px) {
  .profile__right .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 15px !important;
  }
}

.profile__right .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
  overflow-x: hidden !important;
  padding-right: 20px !important;
}

#rc-tabs-1-tab-tab #3 {
  padding-right: 20px !important;
}

.meetingDropdown .ant-dropdown-menu {
  background-color: #F8F8F8 !important;
  border: 0.5px solid #969696af !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.meetingDropdown .ant-dropdown-menu-item:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  background: rgba(255, 255, 255, 0) !important;
}

.meetingDropdown .ant-dropdown-menu-item:focus {
  background-color: rgba(255, 255, 255, 0) !important;
  background: rgba(255, 255, 255, 0) !important;
}

.meetingDropdown .ant-dropdown-menu-item-divider {
  border-bottom: 0.5px solid #969696af !important;
}
.meetingDropdown .ant-dropdown-menu li {
  padding: 5px 10px !important;
}

.meetingDropdown .ant-dropdown-menu-item-divider{
  display: none !important;
}
.meeting__search {
  background: #00000000;
  border: 0px solid;
  width: 100%;
}

.meeting__search:focus {
  background: #00000000;
  border: 0px solid;
  outline: none;
  box-shadow: none;
}

.meeting__user {
  width: 25px;
}

.meeting__name {
  font-size: 16px;
  line-height: 12px;
}

.meeting--button {
  background-color: white;
  color: #969696;
  box-shadow: 0px 3px 2px #00000008;
  border: 0.5px solid #4141404a;
  border-radius: 8px;
  max-width: 100px;
  width: 100%;
}

.meeting--button--img {
  width: 20px;
}

.meeting--button.added:hover {
  border: 0.5px solid #FE0001;
  background: #FE00010D;
  box-shadow: 0px 3px 2px #00000008;
  border: 0.5px solid #FE0001;
  border-radius: 8px;
  color: #FE0001 !important;
}

.meeting--button.added:hover img {
  filter: brightness(50%);
}
.prof .ant-select-selector::before{
  display: none !important;
}
.prof .ant-select-selector{
  height: auto !important;
  overflow: hidden;
}
.prof .ant-select-selector{
  height: 32px !important;
  font-size: 14px !important;
  padding: 0.2px 11px !important;
  border-top: 0px solid !important;
  border-right: 0px solid !important;
}
.prof .ant-select-arrow{
  display: flex !important;
}
.prof .ant-space-compact{
  overflow: hidden;
}
.prof .ant-input-outlined{
  width: 190px !important;
  color: #4a4a4985 !important; 
  font-weight: 600 !important;
  border-right: 0.5px solid #dee2e6 !important;
}
.profile .ant-radio-inner{
  background-color: transparent !important;
  border: 1px solid #40414180 !important;
}
.messagebox{
  margin-bottom: 20px;
}
.messagebox-right{
  background: #EFF3FF !important;
}
.messagebox-right-date{
  position: absolute;
  right: 0;
  bottom: -18px;
}
.messagebox-left{
  background: #F6F6F6 !important;
}
.messagebox-left-date{
  position: absolute;
  left: 0;
  bottom: -18px;
}
.profile-company-avatar{
  width: 40px !important;

}
.download-link{
  color: var(--darkGrey) !important;
}
.download-link:hover{
  color: #2568FF !important;
}
.ant-collapse-header{
  display: flex !important;
  align-items: center !important;
}
.candidates .css-3bmhjh-MuiPaper-root-MuiPopover-paper{
  top: 35% !important;
  left: 35% !important;
  transform: translate(50, 50) !important;
}
.prof__right__btn:hover{
  background-color: #bfd0ff7c!important;
}
.interview__drop .ant-select-compact-last-item>.ant-select-selector .ant-avatar-image{
  width: 25px !important;
  height: 25px !important;
  margin-bottom: 2px !important;
}
.drop{
  border-top: 0.5px solid #dee2e6;
}
.drop__btn1{
  border-right: 0.5px solid #dee2e6;
}