.YDetail__button {
  max-width: 450px !important;
  width: 100%;
}

.YDetail__heading {
  color: var(--darkGrey);
  font-family: var(--fontFamily);
}

.YDetail__top__avatar {
  min-width: 80px;
  min-height: 80px;
}

.YDetail__top__menu {
  background: var(--lightGrey);
  border-radius: 20px;
  cursor: pointer;
}

.YDetail__top__menu__icon {
  color: var(--black);
}

.Dashboard__container {
  max-width: 100% !important;
  padding-right: 30px;
}

.pro__persona {
  max-width: 280px;
}

.pro__YDetail__country.YDetail__bottom .ant-select-selector {
  width: 100% !important;
}

.pro__YDetail__para {
  font-size: 14px;
}

.YDetail__pro__add__another {
  font-size: 14px;
}

.YDetail__pro__add__svg {
  font-size: 26px !important;
}


.nopadd-right {
  padding-right: 0px !important;
}

.react-tel-input .form-control {
  width: 100%;
  margin-top: 5px !important;
  height: 40px;
}

.invalid-email{
  color:red;
}


.candidate__bars .ant-progress .ant-progress-steps-item:nth-child(1) {
  width: 10% !important;
}
.candidate__bars  .react-tabs__tab:nth-child(1) {
  width: 10% !important;
}
.candidate__bars  .react-tabs__tab:nth-child(2) {
  width: 20% !important;
}
.candidate__bars .ant-progress .ant-progress-steps-item:nth-child(2) {
  width: 20% !important;
}
.candidate__bars .ant-progress .ant-progress-steps-item:nth-child(3) {
  width: 16% !important;
}
.candidate__bars .react-tabs__tab:nth-child(3) {
  width: 16% !important;
}
.candidate__bars .ant-progress .ant-progress-steps-item:nth-child(4) {
  width: 12% !important;
}
.candidate__bars .react-tabs__tab:nth-child(4) {
  width: 12% !important;
}
.candidate__bars  .react-tabs__tab:nth-child(5) {
  width: 16% !important;
}
.candidate__bars .ant-progress .ant-progress-steps-item:nth-child(5) {
  width: 15% !important;
}
.candidate__bars .ant-progress .ant-progress-steps-item:nth-child(6) {
  width: 15% !important;
}

@media only screen and (max-width: 1500px) {
  .Dashboard__container {
    max-width: 100% !important;
    padding-right: 50px;
  }
}

@media only screen and (max-width: 1400px) {
  .Dashboard__container {
    max-width: 100% !important;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1300px) {
  .Dashboard__container {
    max-width: 100% !important;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .Dashboard__container {
    max-width: 100% !important;
    padding-right: 17px;
  }
}

.ant-progress .ant-progress-steps-item {
  width: 20% !important;
}
.ant-progress .ant-progress-steps-item {
  background: #CBCBCB;
}
.ant-progress .ant-progress-steps-item-active {
  background-color: var(--main) !important;
}

.PDetail__top {
  border: 0.5px solid #707070;
  border-radius: 8px;
  overflow: hidden;
}

.PDetail__top--blue {
  background-color: #E5ECFF;
}

.PDetail__top--blue__left {
  width: 75%;
}

.PDetail__top--blue__skip {
  border: 1px solid #5F89FF;
  background-color: transparent;
  color: #5F89FF;
  border-radius: 8px;
  height: 37px;
  width: 100px;
}

.PDetail__top--blue__goto {
  border: 1px solid #5F89FF;
  background-color: #5F89FF;
  color: white;
  border-radius: 8px;
  height: 37px;
  width: 100px;
}



.PDetail__2, .PDetail__3 {
  border: 0.5px solid #E6E6E6;
  border-radius: 8px;
}
.PDetail__2__avatar{
  width: 130px;
  height: 130px;
}
.PDetail__2__avatar--img{
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}
.resume__bar{
  background-color: #5F89FF;
  color: white;
}
.btn--white{
  color: var(--darkGrey);
  background-color: white;
  transition: all 0.3s ease-in-out;
  height: 37px;
}
.btn--white:hover{
  color: white;
  background-color: var(--darkGrey);
}
.text-20{
  font-size: 20px;
  line-height: 24px;
}
.PDetail__3__row{
  max-width: 750px;
  width: 100%;
}
.PDetail__3__row__col{
  max-width: 370px;
  width: 100%;
}
.PDetail__3__row--img{
  max-width: 150px;
}
.yourProfile__questions__list textarea:read-only{
  background: #0000000d !important;
}


.references__emails input {
  max-width: 300px;
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .PDetail__top--blue__left {
    width: 100%;
  }
  .PDetail__2__avatar{
    width: 100px;
    height: 100px;
  }
  .PDetail__2__avatar--img{
    width: 60px;
    height: 60px;
  }
  .text-20{
    font-size: 18px;
    line-height: 22px;
  }
  .PDetail__3__row{
    max-width: 650px;
    width: 100%;
  }
  .PDetail__3__row__col{
    max-width: none !important;
    width: 50%;
  }
  .company-col{
    width: 100% !important;
  }
}
@media only screen and (max-width: 576px) {
  .PDetail__2__avatar{
    min-width: 50px;
    width: 50px;
    min-height: 50px;
    height: 50px;
  }
  .PDetail__2__avatar--img{
    width: 50px;
    height: 50px;
  }
  .PDetail__2__right{
    width: 100%;
  }
  .text-20{
    font-size: 16px;
    line-height: 20px;
  }
  .PDetail__3__row__col{
    max-width: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 340px){
  .PDetail__2__avatar{
    min-width: 60px;
    width: 60px;
    min-height: 60px;
    height: 60px;
  }
  .PDetail__2__avatar--img{
    width: 40px;
    height: 40px;
  }
}
