.explore__content {
  max-width: 975px;
  width: 100%;
}
.explore__content__heading {
  color: var(--main) !important;
}
.explore__content__para {
  color: var(--darkGrey) !important;
}
.explore__border {
  border-bottom: 1px solid var(--lightGrey);
}