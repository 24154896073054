.holding {
    background: linear-gradient(35deg, rgba(15, 34, 80, 1) 0%, rgba(0, 0, 0, 1) 32%, rgba(0, 0, 0, 1) 60%, rgba(56, 76, 28, 1) 110%);
    min-height: 100vh;
}
.holding1{
    padding-top: 100px;
}
.holding .footer{
    background-color: rgba(255, 255, 255, 0) !important;
    border-top: 0.5px solid #707070;
}
.holding1__job{
    border-radius: 5px;
}
.holding1__details{
    min-width: 180px;
    max-width: 180px;
}
.holding1__para{
    max-width: 545px;
    width: 100%;
}
.holding1__image{
    min-width: 90px;
    height: 90px;
}
.holding1__tags{
    max-width: 280px;
}

.holding2__para{
    max-width: 370px;
}
.holding2__right{
    background: #1A1A1A;
    border-radius: 5px;
    height: 270px;
}
.holding2{
    padding: 50px 0px;
}
.holding2__link{
    color: #fff;
}
.holding2__link:hover{
    color: var(--main);
}
.holding2__btn{
    background-color: white;
    color: var(--darkGrey);
    border-radius: 4px;
    width: 50%;
    font-weight: 500;
}
.holding2__btn2{
    width: 50%;
    font-weight: 400;
}
.holding2__btn2:hover{
    color: var(--main) !important;
}
.holding2__btn:hover{
    color: var(--darkGrey);
}
.holding4{
    padding-bottom: 80px;
}
.holding4__slider{
    background: #ffffff1d;
    margin-top: 30px;
    margin-bottom: 80px;
}
@media only screen and (max-width: 1200px){
    .holding1__tags{
        max-width: 100%;
        margin-top: 20px;
    }
}
@media only screen and (max-width: 992px){
    .holding2__para{
        max-width: 100%;
    }
    .holding1__details{
        min-width: auto;
        max-width: 100%;
        margin-top: 20px;
    }
    .holding1__para{
        max-width: 100%;
        width: 100%;
    }
    .holding1__image{
        min-width: 90px;
        height: 90px;
    }
    .holding1__tags{
        max-width: 100%;
        margin-top: 0px;
    }
}
@media only screen and (max-width: 768px){
    .holding1__image{
        min-width: 80px;
        height: 80px;
    }
    .holding1__details{
        min-width: auto;
        max-width: 100%;
        margin-top: 0px;
    }
    .holding4__slider{
        margin-top: 0px;
        margin-bottom: 50px;
    }
}
@media only screen and (max-width: 576px){
    .holding1{
        padding-top: 70px;
    }
    .holding1__image{
        min-width: 70px;
        height: 70px;
    }
    .holding1__details{
        min-width: auto;
        max-width: 100%;
        margin-top: 15px;
    }
    .holding2__btn{
        width: 100%;
    }
    .holding2__btn2{
        width: 100%;
    }
}