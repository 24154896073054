.enav {
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%;
}

.enav__top {
    background-color: var(--darkBlue);
    height: 90px;
    z-index: 5;
}

.enav__mid {
    position: relative;
    z-index: 5;
}

.enav__top__logo {
    width: 200px;
}

.enav__top__right__search {
    width: 350px;
    background: rgba(255, 255, 255, 0.119);
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
}

.enav__top__right__search input {
    background-color: transparent;
    border: 0px solid;

}

.enav__top__right__search input::placeholder {
    font-family: var(--fontFamily);
    color: rgba(255, 255, 255, 0.479);
    font-weight: 300px;
}

.enav__top__right__search input:focus {
    border: 0px solid;
    outline: none;
    box-shadow: none;
}

.enav__top__right__user {
    width: 25px;
    height: 25px;
    border: 1.5px solid white;
}

.enav__top__right__dropdown {
    background: rgba(255, 255, 255, 0.119);
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
}

.enav__top__right__dropdown--ul {
    top: 5px !important;
    box-shadow: 0px 3px 10px #00000014;
    border: 0.5px solid #00000067;
    border-radius: 8px;
    z-index: 20;
    width: 250px !important;
}
.dropdown-item__last{
    background-color: #F8F8F8 !important;
}
.dropdown-item{
    padding: 6px 12px !important;
    font-size: 14px !important;
}
.dropdown-item--img{
    width: 18px !important;
    margin-right: 10px !important;
}
.dropdown-item--img-logout{
    width: 17px !important;
    margin-right: 11px !important;
}
.dropdown-item--icon{
    font-size: 21px !important;
    margin-right: 8px !important;
    margin-left: -1px !important;
}
.dropdown-item--icon-eye{
    font-size: 21px !important;
    margin-right: 6px !important;
    margin-left: -1px !important;
}
.dropdown-item:focus, .dropdown-item:hover{
    background-color: white !important;
}
.dropdown-item__last{
    background-color: #F8F8F8 !important;
}
.dropdown-item__last:hover{
    background-color: #F8F8F8 !important;
}
.enav__mid {
    background-color: #253769;
    height: 40px;
}
.dropdown-item_live{
    border-radius: 8px;
    overflow: hidden;
    margin: 7px 7px 0px 7px;
    border: 1px solid;
    font-size: 14px !important;
}
.dropdown-item_live__top{
    padding: 4px 5px;
}
.dropdown-item_live__bottom{
    padding: 4px 5px;
    background-color: #EBEBEB;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
}
.dropdown-item_live__drop{
    padding: 0px 5px;
    background-color: white;
}
.enav__mid__link {
    height: 40px;
    border-radius: 6px;
    padding: 2px 0px 0px 14px;
    white-space: nowrap;
}

.enav__offcanvas {
    background-color: var(--darkBlue) !important;
    z-index: 100 !important;
    width: 50% !important;
}

.enav__burger {
    right: 25px !important;
}

.edash2 {
    padding-top: 150px !important;
}

.enav__mid__link.locked>img,
.enav__mid__link.locked>span {
    opacity: 0.5;
}

.enav__mid__link.locked .locked-nav {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    background: #25376999;
}

.enav__mid__link.locked {
    position: relative;
}

.enav__mid__link.locked .locked-nav svg {
    color: #fff;
    font-size: 24px;
}

.DAccount__top .locked {
    position: relative;
}

.DAccount__top .locked .locked-nav {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e3ebffd6;
    height: 100%;
    width: 100%;
    color: #000;
    font-size: 22px;
}

@media only screen and (max-width: 992px) {
    .enav__top {
        height: 80px;
    }

    .DAccount__top {
        top: 82px;
    }

    .edash {
        padding-top: 80px !important;
    }
}

.border-top-05 {
    border-top: 0.5px solid rgba(255, 255, 255, 0.443);
}

@media only screen and (max-width: 576px) {
    .enav__top {
        height: 70px;
    }

    .enav__top__logo {
        width: 170px;
    }

    .enav__burger {
        right: 5px !important;
    }
}
.ant-drawer .ant-drawer-header{
    display: none;
}
.ant-drawer .ant-drawer-mask {
    background-color: transparent !important;
}
.ant-drawer-body{
    padding: 17px 0px !important;
}