.create__indexCore__chart {
    display: flex;
    margin-bottom: 40px;
}

.create__indexCore__chart>div {
    max-width: 370px;
    margin-right: 20px;
    width: 100%;
}

.create__indexCore__chart img {
    width: 100%;
}

.create__indexCore__chart .CircularProgressbar .CircularProgressbar-path {
    stroke: var(--main);
}

.Indexcore__progress {
    width: 350px;
    height: 350px;
}

.create__indexCore__chart>div {
    max-width: 370px;
    margin-right: 20px;
    width: 100%;
}

.circular__text {
    position: absolute;
    top: 40%;
    left: 87px;
    width: 200px;
    line-height: 26px;
    font-size: 21px;
}
.form-box .ant-select.css-1e3x2xa.ant-select-single.ant-select-show-arrow {
    width: 100%;
}

.form-box .ant-select-arrow {
    display: block !important;
    color: #fff;
    font-size: 16px;
}

@media only screen and (max-width: 1500px) {
    .Indexcore__progress {
        width: 300px;
        height: 300px;
    }

    .create__indexCore__chart>div {
        max-width: 340px;
        margin-right: 20px;
        width: 100%;
    }

    .circular__text {
        position: absolute;
        top: 40%;
        left: 72px;
        width: 200px;
        line-height: 24px;
        font-size: 19px;
    }
}

@media only screen and (max-width: 1400px) {
    .Indexcore__progress {
        width: 250px;
        height: 250px;
    }

    .create__indexCore__chart>div {
        max-width: 270px;
        margin-right: 20px;
        width: 100%;
    }

    .circular__text {
        position: absolute;
        top: 36%;
        left: 63px;
        width: 150px;
        line-height: 22px;
        font-size: 16px;
    }
}
