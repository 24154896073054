.filter {
  font-family: var(--fontFamily);
}
.filter__content--small {
  color: var(--darkBlue);
  font-size: 18px;
  line-height: 15px;
}
.filter__content__heading {
  font-size: 28px;
  font-weight: normal;
  letter-spacing: -0.7px;
}
.filter__content__para {
  letter-spacing: -0.7px;
  font-size: 22px;
}
.filter__content__tag__content {
  border: 1px solid var(--darkBlue);
  border-radius: 6px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.filter__content__tag__content--small {
  font-size: 18px;
  color: var(--darkGrey);
}
.filter__content__link__heading {
  color: var(--midBlue);
  font-weight: 300;
  font-family: var(--fontFamily);
  font-size: 26px;
  letter-spacing: -0.7px;
}
.filter__content__link__heading__icon {
  font-size: 26px;
}
.filter__border {
  border-bottom: 1px solid var(--lightGrey);
}

@media only screen and (max-width: 1400px) {
  .filter__content__heading {
    font-size: 26px;
  }
  .filter__content__para {
    font-size: 20px;
  }
  .filter__content__tag__content--small {
    font-size: 16px;
  }
  .filter__content__link__heading {
    font-size: 24px;
  }
  .filter__content__link__heading__icon {
    font-size: 24px;
  }
}
@media only screen and (max-width: 1200px) {
  .filter__content__heading {
    font-size: 24px;
  }
}
.Insight__header {
  background: var(--lightGrey);
  border-radius: 16px;
}
.Insight__header__title {
  color: var(--darkgrey);
  font-family: var(--fontFamily);
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
}
.Insight__header__list__item {
  color: var(--darkgrey);
  font-family: var(--fontFamily);
  font-size: 18px;
  cursor: pointer;
}
.Insight__header__list__item__icon {
  font-size: 30px;
}
.Insight__header__list__item:hover {
  color: var(--darkgrey);
}

@media only screen and (max-width: 1400px) {
  .Insight__header__title {
    font-size: 20px;
  }
  .Insight__header__list__item {
    font-size: 15px;
  }
  .Insight__header__list__item__icon {
    font-size: 27px;
  }
}
@media only screen and (max-width: 1200px) {
  .Insight__header__title {
    font-size: 18px;
  }
  .Insight__header__list__item {
    font-size: 14px;
  }
  .Insight__header__list__item__icon {
    font-size: 25px;
  }
}
@media only screen and (max-width: 992px) {
  .Insight__header__title {
    font-size: 16px;
  }
  .Insight__header__list__item {
    font-size: 12px;
  }
  .Insight__header__list__item__icon {
    font-size: 23px;
  }
}
@media only screen and (max-width: 767px) {
  .Insight__header__title {
    font-size: 14px;
  }
}
@media only screen and (max-width: 576px) {
  .Insight__header {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .Insight__header__title {
    font-size: 12px;
  }
  .Insight__header__list__item {
    font-size: 12px;
  }
  .Insight__header__list__item2 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .Insight__header__list__item__icon {
    font-size: 20px;
  }
}
@media only screen and (max-width: 390px) {
  .Insight__header__list__item2 {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
  .Insight__header__list__item__icon {
    font-size: 18px;
  }
}
@media only screen and (max-width: 340px) {
  .Insight__header__list__item__icon {
    font-size: 17px;
  }
}