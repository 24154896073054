.scontainer {
    background-color: #F2F2F2;
}

.calc__dash {
    min-height: 100vh;
}

.scontainer__para {
    max-width: 670px;
}

.scontainer__calc {
    background: #d8ff9869;
    min-height: 420px;
}

.scontainer__calc__loading {
    background: #F2F2F2;
    left: 0;
    opacity: 0;
    transition: all 1s ease-in-out;
}

.scontainer__calc__loading--inner {
    background: #d8ff9869;
}

.scontainer__calc .ant-slider .ant-slider-track {
    background-color: #2680EB !important;
}

.calc {
    position: relative;
    z-index: 1;
}

.scontainer__calc .ant-slider .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #2680EB !important;
    width: 16px !important;
    height: 16px !important;
    top: -4px !important;
}

.scontainer__calc .ant-slider-horizontal .ant-slider-rail {
    background-color: #b4b4b4 !important;
}

.scontainer__calc .ant-slider-horizontal .ant-slider-track,
.scontainer__calc .ant-slider-horizontal .ant-slider-rail {
    height: 2px !important;
}

.calc__rangetext {
    margin-top: -10px;
}

.calc__rangebox {
    height: 150px;
}

.calc__inputbox {
    height: 80px;
}

.calc .ant-input-group .ant-input-group-addon:first-child {
    background-color: white !important;
    color: var(--darkBlue) !important;
    font-weight: 400 !important;
    font-family: var(--fontFamily) !important;
    font-size: 20px !important;
}

.calc__member .ant-input-group .ant-input-group-addon {
    background-color: white !important;
    border-left: 0px solid !important;
    width: 100% !important;
    text-align: left !important;
    color: #4a4a4946 !important;
    font-weight: 400 !important;
    font-family: var(--fontFamily) !important;
    font-size: 14px !important;
    padding-left: 0px !important;
}

.calc__member .ant-input {
    border-right: 0px solid !important;
    min-width: 50px !important;
    padding-right: 0px !important;
}

.calc .ant-input {
    color: var(--darkBlue) !important;
    font-family: var(--fontFamily) !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

.calc .ant-input:hover {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

.calc .ant-input:focus {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

.calc__search .ant-input-group .ant-input-group-addon {
    display: none !important;
}

.calc__search .ant-input-affix-wrapper {
    border-radius: 6px !important;
    width: 100% !important;
    max-width: 100% !important;
    padding-left: 7px !important;
}

.calc__search .ant-input-group {
    width: 100% !important;
    max-width: 465px !important;
}

.calc__search .ant-input-prefix {
    font-size: 22px !important;
    padding-left: 0px !important;
    color: #707070 !important;
}
.calculator .ant-select-selector {
    max-width: 100% !important;
}
@media only screen and (max-width: 992px) {
    .calc__inputbox {
        height: 110px;
    }

    .scontainer__calc {
        min-height: 330px;
    }

    .calc__search {
        height: 110px !important;
    }

    .calc__search .ant-input-group {
        width: 100% !important;
        max-width: 484px !important;
    }
}

@media only screen and (max-width: 768px) {

    .calc__inputbox,
    .calc__search {
        margin-top: 0px !important;
    }

    .calc .ant-input-group {
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 576px) {
    .calc__inputbox {
        height: 100px;
    }

    .calc__search {
        height: 100px !important;
    }

    .calc__rangebox {
        height: 130px;
    }
}


@media only screen and (min-width: 1400px) {
    .calc__search .ant-input-group {
        width: 100% !important;
        max-width: 513px !important;
    }
}

/* results */

.result__strip--height {
    height: 140px;
}

.darkGreen {
    color: #436C03;
}

.result__strip1__link:hover {
    color: var(--darkBlue);
}

.result__strip1__box {
    margin-left: 30px;
    background-color: #E1FABA;
    z-index: 1;
    opacity: 0;
    position: relative;
    padding-left: 17px;
}

.result__strip1__overlay {
    background-color: #A6DD50;
    z-index: 0;
    width: 50%;
    transition: all 1s ease-in-out;
    left: -100%;
}

.result__strip1__container::before {
    height: 100%;
    width: 100%;
    position: absolute;
    right: -100%;
    content: "";
    top: 0;
    background-color: #E1FABA;
    z-index: 5;
}

.result__strip2__overlay {
    background-color: #12D3DB !important;
    width: 990px;
    transition: all 1s ease-in-out;
    left: -100%;
}

.result__strip2__box {
    z-index: 3;
    width: 990px;
    position: relative;
    padding-left: 17px;
    padding-right: 17px;
    transition: all 1s ease-in-out;
}

.result__strip2__container::before {
    height: 100%;
    width: 100%;
    position: absolute;
    right: -100%;
    content: "";
    top: 0;
    background-color: #E1FABA;
    z-index: 5;
    display: none;
}

.result__strip3__overlay {
    background-color: #5F89FF !important;
    width: 100%;
    transition: all 1s ease-in-out;
    left: -100%;
}

.result__strip3__box {
    z-index: 3;
    position: relative;
    padding-left: 17px;
    padding-right: 17px;
    transition: all 1s ease-in-out;
}

.result__strip3__container::before {
    height: 100%;
    width: 100%;
    position: absolute;
    right: -100%;
    content: "";
    top: 0;
    background-color: #E1FABA;
    z-index: 5;
    display: none;
}

.result__heading {
    font-size: 25px !important;
}

.line-3x {
    height: 280px;
    width: 2px;
    background-color: #12d3db;
    z-index: 6;
    top: -140px;
    left: 990px;
}

.pill-3x {
    user-select: none;
    background-color: #12d3db;
    width: 65px;
    height: 25px;
    font-size: 12px;
    transform: translateX(-50%);
    top: -25px;
}

.line-4x {
    height: 280px;
    width: 2px;
    background-color: #5F89FF;
    z-index: 6;
    top: -280px;
    right: 0;
}

.pill-4x {
    user-select: none;
    background-color: #5F89FF;
    width: 65px;
    height: 25px;
    font-size: 12px;
    transform: translateX(-50%);
    top: -25px;
}

.dashResults .result__strip2__overlay {
    width: 100%;
}

.dashResults .result__strip2__box {
    width: 100%;
    padding-right: 617px;
}

.dashResults .line-3x {
    left: auto;
    right: 600px;
}

.dashResults .result__strip2__container::before {
    right: 0px;
    width: 600px;
    display: block;
}

.dashResults .result__strip1__box {
    padding-left: 15px !important;
}

.dashResults .result__strip1__box {
    margin-left: 90px;
}

.dashResults .result__strip3__container::before {
    right: -250px;
    width: 250px;
    display: block;
}

.dashResults .result__strip3__container {
    max-width: 100%;
}

.dashResults .result__strip3 {
    padding-right: 250px;
}


@media only screen and (max-width: 1600px) {
    .dashResults .result__strip3 {
        padding-right: 200px;
    }

    .dashResults .result__strip3__container::before {
        right: -200px;
        width: 200px;
    }
    .dashResults .result__strip2__box {
        padding-right: 467px;
    }
    
    .dashResults .line-3x {
        right: 450px;
    }
    
    .dashResults .result__strip2__container::before {
        width: 450px;
    }
}

@media only screen and (min-width: 1400px) {
    .calc__dashbaord {
        max-width: 1310px;
    }
}

@media only screen and (max-width: 1400px) {
    .result__strip3__container::before {
        display: block;
    }

    .result__strip2__box {
        width: 100%;
        padding-right: 350px !important;
    }

    .result__strip2__container::before {
        display: block;
    }

    .result__strip2__container::after {
        height: 100%;
        width: 332px;
        position: absolute;
        right: 0;
        content: "";
        top: 0;
        background-color: #E1FABA;
        z-index: 5;
    }

    .result__strip1 {
        background-color: #E1FABA;
    }

    .line-3x {
        left: auto;
        right: 332px;
    }
    .dashResults .result__strip3 {
        padding-right: 100px;
    }

    .dashResults .result__strip3__container::before {
        right: -100px;
        width: 100px;
    }
    .dashResults .result__strip2__box {
        padding-right: 317px;
    }
    
    .dashResults .line-3x {
        right: 330px;
    }
    
    .dashResults .result__strip2__container::before {
        width: 300px;
    }
    
}

@media only screen and (max-width: 1200px) {
    .result__strip3 {
        padding-right: 40px;
    }
}

@media only screen and (max-width: 992px) {
    .result__strip2__container::after {
        height: 100%;
        width: 240px;
    }

    .result__strip2__box {
        width: 100%;
        padding-right: 255px !important;
    }

    .line-3x {
        right: 240px;
    }

    .result__strip3 {
        padding-right: 40px;
    }

    .result__strip1__box,
    .result__strip3__box {
        padding-right: 15px !important;
    }

    .result__strip1__box {
        padding-left: 15px !important;
    }

    .result__strip1__box {
        margin-left: 60px;
    }
    .dashResults .result__strip1__box {
        margin-left: 60px;
    }
    .dashResults .result__strip2__box {
        padding-right: 317px !important;
    }
    
    .dashResults .line-3x {
        right: 300px;
    }
    
    .dashResults .result__strip2__container::before {
        width: 300px;
    }
}

@media only screen and (max-width: 768px) {
    .result__strip--height {
        height: 110px;
    }

    .line-3x {
        top: -110px;
        right: 200px;
        height: 220px;
    }

    .line-4x {
        top: -220px;
        height: 220px;
    }

    .result__heading {
        font-size: 20px !important;
    }

    .result__strip3 {
        padding-right: 30px;
    }
    .dashResults .result__strip3 {
        padding-right: 47px;
    }
    .result__strip2__container::after {
        width: 200px;
    }

    .result__strip2__box {
        width: 100%;
        padding-right: 215px !important;
    }
    
    .result p {
        font-size: 12px !important;
    }
    .dashResults .result__strip2__box {
        padding-right: 215px !important;
    }
    .dashResults .line-3x {
        right: 200px;
    }
    
    .dashResults .result__strip2__container::before {
        width: 200px;
    }

}

@media only screen and (max-width: 576px) {
    .result__heading {
        font-size: 16px !important;
        text-wrap: wrap !important;
    }

    .result__strip2__container::after {
        width: 150px;
    }

    .result__strip2__box {
        padding-right: 165px !important;
    }

    .line-3x {
        right: 150px;
    }

    .pill-3x,
    .pill-4x {
        width: 55px;
        height: 20px;
        font-size: 10px;
        top: -20px;
    }
    .dashResults .result__strip2__box {
        padding-right: 165px !important;
    }
    .dashResults .line-3x {
        right: 150px;
    }
    
    .dashResults .result__strip2__container::before {
        width: 150px;
    }
}

@media only screen and (max-width: 460px) {
    .result__strip2__container::after {
        width: 90px;
    }

    .line-3x {
        right: 90px;
    }

    .result__strip2__box {
        padding-right: 105px !important;
    }

    .result__strip1__box, .dashResults .result__strip1__box {
        margin-left: 40px;
    }

    .result__strip3 {
        padding-right: 30px;
    }
    .dashResults .result__strip2__box {
        padding-right: 105px !important;
    }
    .dashResults .line-3x {
        right: 90px;
    }
    
    .dashResults .result__strip2__container::before {
        width: 90px;
    }
}

@media only screen and (max-width: 360px) {
    .result__strip2__container::after {
        width: 90px;
    }

    .line-3x {
        right: 90px;
    }

    .result__strip2__box {
        padding-right: 105px !important;
    }

    .result__strip1__box {
        margin-left: 40px;
    }

}