.hr.interviewer-main-section{
    padding-top: 0px;
}
.interviewer-main-section .container {                        
    margin-top: 30px;
    border-radius: 15px;
}
.interviewer-main-section-border {
    opacity: 1;
    border-color: #70707040;
}
.interviewerHeader {
    text-align: center;
    padding:20px;
    display: flex;
    align-items: center;
    border: 1px solid #70707040;
    border-radius: 8px;
    
}
.empty-box {
    display: flex;
    height: 100%;
    border: 1px solid #70707040;
    border-radius: 8px;
}
.interviewerDetail{
    margin-left:10px;
}
.interviewerDetail .interviewer-name h3 {
    font-size: 20px;
    text-align: left;
    margin-bottom: 0px;
    color:#0D2159;
}

.interviewerHeader .imageBox {
    width: 68px;
    height: 68px;
    display: inline-block;
    border-radius: 50%;
    background: #5F89FF;
    padding: 15px;
}

.interviewerHeader .imageBox img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.interviewerDetail .emailBox {
    color: #108a01;
}

.interviewerDetail .emailBox span,.interviewerDetail .emailBox a {
    color: #4A4A49;
    text-decoration: none;
    
}

.interviewerDetail .departmentBox svg{
    color: #108a01;
}
.interviewerDetail .departmentBox span{
    color: #000;
    font-weight:600;
}

.interviewerDetail .headerButtons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.interviewerDetail .headerButtons button {
    padding: 10px 30px;
    border-radius: 25px;
    text-transform: capitalize;
    font-weight: 600;
}

.interviewerDetail .headerButtons button.primary-button {
    border: 2px solid #108a01;
    background: transparent;
    color: #108a01;
}

.interviewerDetail .headerButtons button.secondary-button {
    background: #108a01;
    color: #fff;
    min-width: 150px;
}




.tab-contents .row.enterprise {
    padding: 10px 0;
    background:#fff;
    border-radius:6px;
    margin-bottom:2px;
}
.tab-contents .row.enterprise:hover {
    background: #F8F8F8;
}
.candidates__avatar {
    padding-top: 7px;
}
.interview-tab-items {
    border-bottom: 1px solid #70707040;
}
.interviewer-main-section .interview-tab-items .react-tabs__tab {
    font-weight: 400;
}

.interviewer-main-section .interview-tab-items .react-tabs__tab--selected {
    border: none;
    font-weight:500;
    color:#486FD9;
    position: relative;
    background: transparent;
    padding-bottom: 10px;                        
}

.interviewer-main-section .interview-tab-items .react-tabs__tab--selected:before {
    width: 50px;
    height: 4px;
    background: #486FD9;
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.main__color__text{
    color:#A6DD50;
}
                    
.applicants__data {
    background: rgb(230 230 230 / 25%);
    border-radius: 6px;
    
}
.applicants__data__footer p {
    color: rgb(74 74 73);
    font-size: 12px;
    margin:0px;
}

.applicants__data__footer p span {
    opacity: 0.75;
}
.location {
    background: #bfd0ff;
    padding: 5px 8px;
    border-radius: 20px;
}
.filled-star{
    color:#4A4A49;
}
.stars{
    font-size:14px;
}
.zoom-icon{
    max-width:30px;
}
.star-rating {
        font-size: 30px;
        margin-bottom:0px;
    }
.star {
    cursor: pointer;
    color: gray;
  }
  
  .selected {
    color: #14a800;
  }
  .no-record-message{
    text-align:center;
    padding:20px;
  }
  .menu-button{
    background:transparent;
  }
 
  .menu-button svg {
    margin-right: 10px;
    margin-top: 3px;
}
.resume-icon-download img{
    cursor:pointer;
    width:30px;
}

.slider-parent input[type="range"] {
    background: #91caff;
    border-radius: 20px;
    width: 100%;
    height: 4px;
    position: relative;
    z-index: 2;
}

.hr-dashboard-jobs .edash{
    padding-top: 0px !important;
}

.hr-dashboard-jobs .jobd__btn{
    visibility: hidden;
}
.candidates__interview__icon img{
    width: 25px !important;
}
