.banner {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 1;
}
.banner:before{
  position: absolute;
  content: "";
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  background: transparent linear-gradient(115deg, #000000 0%, #5454543E 100%) 0% 0% no-repeat padding-box;
mix-blend-mode: multiply;
opacity: 0.83;
}
.banner__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.57); */
}
.banner__overlay__content {
  z-index: 1;
}
.banner__overlay__content__heading {
  color: var(--main);
  font-size: 55px;
  font-weight: 300;
  line-height: 57px;
  font-family: var(--fontFamily);
}
.banner__overlay__content__heading-blue {
  color: var(--blue);
}
.banner__overlay__content__heading-main {
  color: var(--main);
}
.banner__overlay__content__para {
  color: var(--white);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  max-width: 875px;
  font-family: var(--fontFamily);
  margin-top: 34px !important;
  margin-bottom: 65px;
}

.about-us .banner__overlay__content__para {
  max-width: 650px !important;
}


@media only screen and (max-width: 1400px) {
  .about-us .banner__overlay__content__para {
    max-width: 600px !important;
  }
  
  .banner__overlay__content__heading {
    font-size: 45px;
    line-height: 48px;
  }
  .banner__overlay__content__para {
    font-size: 20px;
    line-height: 28px;
    max-width: 490px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width:1201px){
  .individual__getStarted__banner .banner__overlay__content__para{
    max-width: 630px;
    font-size: 20px;

  }
}
@media only screen and (max-width: 1200px) {
  .banner__overlay__content__heading {
    font-size: 35px;
    line-height: 50px;
  }
  .banner__overlay__content__para {
    font-size: 18px;
    line-height: 25px;
    max-width: 450px;
  }
}
@media only screen and (max-width: 992px) {
  .banner__overlay__content__heading {
    font-size: 35px;
    line-height: 50px;
  }
  .banner__overlay__content__para {
    font-size: 18px;
    line-height: 25px;
    max-width: 450px;
  }
}
@media only screen and (max-width: 768px) {
  .banner__overlay__content__heading {
    font-size: 30px;
    line-height: 35px;
  }
  .banner__overlay__content__para {
    font-size: 18px;
    line-height: 23px;
    max-width: 450px;
    margin-top: 28px !important;
  }
}
@media only screen and (max-width: 576px) {
  .banner__overlay__content {
    max-width: 100%;
  }
  .banner__overlay__content__heading {
    font-size: 26px;
    line-height: 30px;
  }
  .banner__overlay__content__heading-blue {
    color: var(--main);
  }
}
@media only screen and (max-width: 390px) {
  .banner__overlay__content__heading {
    font-size: 32px;
    line-height: 37px;
  }
}
@media only screen and (max-width: 350px) {
  .banner__overlay__content__heading {
    font-size: 26px;
    line-height: 30px;
  }
}