.Whyfaq .accordion {
    --bs-accordion-border-color: white !important;
}

.accordion-button:not(.collapsed) {
    color: var(--main) !important;
    background-color: #e6e6e628 !important;
    box-shadow: none !important;
}

.accordion-button:focus {
    border-color: white !important;
    outline: 0;
    box-shadow: none !important;
}

.accordion-body {
    padding-top: 0px !important;
    background-color: #e6e6e628 !important;
}

.accordion-button::after {
    flex-shrink: 0;
    width: 25px !important;
    height: 25px !important;
    margin-left: auto;
    content: "+" !important;
    font-size: 25px;
    line-height: 24px;
    background-image: none !important;
    border: 1px solid #4A4A49;
    color: #4A4A49;
    border-radius: 50%;
    text-align: center;
    font-weight: 100;
}
.accordion-button:not(.collapsed)::after {
    content: "_" !important;
    line-height: 0px !important;
    font-weight: 200;
    background-color: var(--main) !important;
    color: var(--white);
    border: 1px solid #A6DD50;
}
.accordion-item:first-of-type .accordion-button{
    border-radius: 0px !important;
    border-top: 1px solid #dee2e6 !important;
}
.text-16{
    font-size: 16px !important;
}