.values {
  background: var(--darkBlue);
}
.values__header {
  font-family: var(--fontFamily);
  font-size: 44px;
  font-weight: 300;
  line-height: 50px;
  color: var(--white);
}
.values__para {
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: normal;
  line-height: 26px;
  color: var(--white);
}
.values__content__icon__arrow {
  color: var(--main);
  font-size: 30px;
}
.values__content__text {
  font-size: 26px;
  font-family: var(--fontFamily);
  font-weight: 300;
  line-height: 24px;
  color: var(--white);
}

@media only screen and (max-width: 1400px) {
  .values__header {
    font-size: 45px;
  }
  .values__para {
    font-size: 20px;
    line-height: 24px;
  }
  .values__content__text {
    font-size: 24px;
  }
}
@media only screen and (max-width: 1200px) {
  .values__header {
    font-size: 40px;
  }
  .values__content__text {
    font-size: 20px;
  }
}
@media only screen and (max-width: 992px) {
  .values__header {
    font-size: 35px;
  }
  .values__para {
    font-size: 16px;
  }
  .values__content__text {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .values__header {
    font-size: 30px;
  }
  .values__content__text {
    font-size: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .values__header {
    font-size: 25px;
  }
  .values__content__text {
    font-size: 20px;
  }
}