.circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid var(--midGrey);
  color: var(--midGrey);
  font-weight: 600;
  line-height: 14px;
  font-size: 14px;
}

.Tab {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
          cursor: pointer;
}
.Tab__item {
  color: var(--midGrey);
  font-size: 16px;
  line-height: 22px;
}
.NewJob__top {
  overflow: hidden !important;
}

.NewJob__bar {
  min-width: 640px;
  overflow: hidden !important;
}

.tag__input {
  border: 1px solid var(--midGrey) !important;
}
.ant-tag {
  font-family: var(--fontFamily);
  background: rgba(75, 171, 226, 0.1019607843);
  color: var(--darkGray) !important;
  display: flex !important;
  align-items: center !important;
  height: 30px;
  margin-right: 5px !important;
  border: 0px solid;
}
.Get__right__button{
  max-width: 160px !important;
}
.draft__button{
  font-size: 16px !important;
  font-family: "Open Sans" !important;
  max-width: 160px !important;
  background-color: white !important;
  color: var(--darkGrey) !important;
}

.PDetail__3__delete{
  background-color: var(--midGrey);
  border-radius: 8px;
  height: 35px;
  max-width: 200px;
  width: 100%;
}
.PDetail__3__delete:hover{
  background-color: #5f89ff;
}
@media only screen and (max-width: 768px){
  .NewJob__top {
    overflow-x: scroll !important;
  }
  .Tab__item {
    font-size: 15px;
    line-height: 23px;
  }
}
@media only screen and (max-width: 576px){
  .Get__right__button{
    max-width: 100% !important;
    width: 100% !important;
  }
  .draft__button{
    max-width: 100% !important;
  }
  .invite-button{
    width: 100% !important;
    height: 40px !important;
  }
  .PDetail__3__delete{
    max-width: 100%;
  }
}
@media only screen and (max-width: 340px){
  .Tab__item {
    font-size: 14px;
    line-height: 23px;
  }
}