@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-BoldItalic.woff2') format('woff2'),
        url('OpenSansSemiCondensed-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-ExtraBold.woff2') format('woff2'),
        url('OpenSansSemiCondensed-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-Bold.woff2') format('woff2'),
        url('OpenSansSemiCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-Regular.woff2') format('woff2'),
        url('OpenSansSemiCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-Italic.woff2') format('woff2'),
        url('OpenSansSemiCondensed-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-ExtraBoldItalic.woff2') format('woff2'),
        url('OpenSansSemiCondensed-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-MediumItalic.woff2') format('woff2'),
        url('OpenSansSemiCondensed-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-SemiBoldItalic.woff2') format('woff2'),
        url('OpenSansSemiCondensed-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-Medium.woff2') format('woff2'),
        url('OpenSansSemiCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-LightItalic.woff2') format('woff2'),
        url('OpenSansSemiCondensed-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-Light.woff2') format('woff2'),
        url('OpenSansSemiCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiCondensed';
    src: url('OpenSansSemiCondensed-SemiBold.woff2') format('woff2'),
        url('OpenSansSemiCondensed-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

