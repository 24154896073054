.Whysection2{
    background: #e6e6e628 !important;
}
.Whysection2__row--img{
    max-width: 500px;
    width: 100%;
}
.Whysection2__row__content{
    max-width: 740px;
    width: 100%;
}
@media only screen and (max-width: 1400px) {
    .Whysection2__row--img{
        max-width: 400px;
    }
    .Whysection2__row__content{
        max-width: 640px;
    }
}
@media only screen and (max-width: 1200px) {
    .Whysection2__row--img{
        max-width: 350px;
    }
    .Whysection2__row__content{
        max-width: 540px;
    }
}
@media only screen and (max-width: 768px) {
    .Whysection2__row--img{
        max-width: 350px;
    }
    .Whysection2__row__content{
        max-width: 100%;
    }
}
@media only screen and (max-width: 576px){
    .Whysection2__row--img{
        max-width: 100%;
    }
}