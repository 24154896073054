@charset "UTF-8";
.Extra__heading {
  font-size: 50px !important;
}
.Extra__heading--main {
  color: var(--main) !important;
}
.Extra__icon {
  color: var(--midGrey);
}

.ant-select-selector {
  border-color: #ced4da !important;
  max-width: 765px !important;
  width: 100%;
  height: 46px !important;
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  padding: 0.5rem 1rem !important;
  display: flex !important;
  align-items: center !important;
  position: relative !important;
}
.ant-select-selection-placeholder {
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.ant-select-selector:focus-within {
  box-shadow: none !important;
}
.ant-select-selector::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  background: var(--darkGrey);
  color: var(--white);
  font-size: 22px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  justify-content: center;
}

.ant-select-arrow {
  display: none !important;
}

.ant-select-item-option-content {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-select-selection-overflow-item {
  margin-top: -9px !important;
}

.ant-select-selection-item {
  margin-left: 0px !important;
}

.Get__right__para-grey {
  color: var(--midGrey) !important;
}

@media only screen and (max-width: 1400px) {
  .ant-select-selector {
    max-width: 650px !important;
  }
  .landscape__select .ant-select-selector, .landscape__input{
    max-width: 100% !important;
  }
  
  .Extra__heading {
    font-size: 42px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .ant-select-selector {
    max-width: 565px !important;
  }
  .landscape__select .ant-select-selector, .landscape__input{
    max-width: 100% !important;
  }
  .Extra__heading {
    font-size: 35px !important;
  }
}
@media only screen and (max-width: 992px) {
  .ant-select-selector {
    max-width: 600px !important;
  }
  .landscape__select .ant-select-selector, .landscape__input{
    max-width: 100% !important;
  }
  .Extra__heading {
    font-size: 30px !important;
  }
}
@media only screen and (max-width: 768px) {
  .ant-select-selector {
    max-width: 600px !important;
    width: 100% !important;
  }
  .landscape__select .ant-select-selector, .landscape__input{
    max-width: 100% !important;
  }
  .Extra__heading {
    font-size: 25px !important;
    line-height: 25px !important;
  }
}