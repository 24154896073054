.Plan__card {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.Plan__card:hover {
  box-shadow: rgba(56, 56, 57, 0.1) 0px 15px 43px;
}

.Plan__card__button {
  width: 200px;
  border: 1px solid #1677ff;
  background: var(--white);
  font-family: var(--fontFamily);
  color: #1677ff;
  transition: all 0.3s ease-in-out;
}

.Plan__card__button:hover {
  background: #1677ff;
  color: var(--white);
}
.plan__box .Plan__card__button{
  background-color: #1677ff;
  color:#fff;
}
.Plan__card__button.current{
  background-color: #fff;
  color: var(--main);
  border-color: #fff;
}

.Plan__card__container__button {
  height: 30px;
  width: 70px;
}

.Plan__card__container__box {
  width: 50px;
  height: 45px;
  background: var(--lightGrey);
}

.Plan__table__content__button {
  height: 30px;
  max-width: 150px;
  width: 100%;
}

.Plan__table__content__button2 {
  height: 30px;
  width: 125px;
  border-color: var(--darkGrey);
  background: var(--white);
}

.plan__box {
  min-height: 480px;
  margin-bottom: 30px;
  display: flex;
  /* border:none !important; */
  flex-direction: column;
  justify-content: space-between;
  box-shadow:rgba(56, 56, 57, 0.1) 1px 20px 10px;
}
.plan__box.pro{
  min-height: 510px;
}
.plan__box .plan__heading{
  text-align: center;
}
.plan__box h2{
  text-align: center;
  /* font-weight: 800; */
}
.plan__box ul {
  list-style: none;
  padding: 0px;
  margin-top: 30px;
}
.plan__box p{
  font-size: 14px;
}
.plan__box ul li {
  position: relative;
  padding-left: 15px;
  font-size: 14px;

}

.plan__box ul li:before {
  position: absolute;
  width: 6px;
  height: 6px;
  content: "";
  top: 8px;
  left: 0;
  border-radius: 50%;
  background-color: var(--main);
}


.plan__box.current {
  background-image: linear-gradient(#285ae7,#0d2159);
}

.plan__box.current .plan__heading,
.plan__box.current .plan__pricing,
.plan__box.current p,
.plan__box.current ul li{
  color:#fff
}
.plan__box.current ul li:before{
  background-color:#fff;
}

.plan__box ul li.indent__text{
  padding-left:40px;
}
.plan__box ul li.indent__text:before{
  left:20px;
}
@media only screen and (max-width: 768px) {
  .Plan__table {
    overflow: scroll !important;
  }

  .Plan__row {
    min-width: 700px !important;
  }
}

