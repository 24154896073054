@import './vendors/fonts/Open_Sans/stylesheet.css';
@import './vendors/fonts/Open_Sans/static/OpenSans/stylesheet.css';
@import './vendors/fonts/Open_Sans/static/OpenSans_Condensed/stylesheet.css';
@import './vendors/fonts/Open_Sans/static/OpenSans_SemiCondensed/stylesheet.css';

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
 * * ========================================================================== */
/**
 * * Remove the margin in all browsers. */
body {
  margin: 0;
  transition: all 0.3s ease-in-out;
}

/**
 * * Render the `main` element consistently in IE. */
main {
  display: block;
}

/**
 * * Correct the font size and margin on `h1` elements within `section` and
 * * `article` contexts in Chrome, Firefox, and Safari. */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * * ========================================================================== */
/**
 * * 1. Add the correct box sizing in Firefox.
 * * 2. Show the overflow in Edge and IE. */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * * 1. Correct the inheritance and scaling of font size in all browsers.
 * * 2. Correct the odd `em` font sizing in all browsers. */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
 * * ========================================================================== */
/**
 * * Remove the gray background on active links in IE 10. */
a {
  background-color: transparent;
}

/**
 * * 1. Remove the bottom border in Chrome 57-
 * * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari. */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * * Add the correct font weight in Chrome, Edge, and Safari. */
b,
strong {
  font-weight: bolder;
}

/**
 * * 1. Correct the inheritance and scaling of font size in all browsers.
 * * 2. Correct the odd `em` font sizing in all browsers. */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * * Add the correct font size in all browsers. */
small {
  font-size: 80%;
}

/**
 * * Prevent `sub` and `sup` elements from affecting the line height in
 * * all browsers. */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
 * * ========================================================================== */
/**
 * * Remove the border on images inside links in IE 10. */
img {
  border-style: none;
}

/* Forms
 * * ========================================================================== */
/**
 * * 1. Change the font styles in all browsers.
 * * 2. Remove the margin in Firefox and Safari. */
button,
input,
optgroup,
select,
textarea {
  font-family: 'Open Sans';
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', 'Helvetica', 'Arial';

}

/**
 * * Show the overflow in IE.
 * * 1. Show the overflow in Edge. */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * * 1. Remove the inheritance of text transform in Firefox. */
button,
select {
  /* 1 */
  text-transform: none;
}


/**
 * * Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * * Restore the focus styles unset by the previous rule. */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * * Correct the padding in Firefox. */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * * 1. Correct the text wrapping in Edge and IE.
 * * 2. Correct the color inheritance from `fieldset` elements in IE.
 * * 3. Remove the padding so developers are not caught out when they zero out
 * *    `fieldset` elements in all browsers. */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * * Add the correct vertical alignment in Chrome, Firefox, and Opera. */
progress {
  vertical-align: baseline;
}

/**
 * * Remove the default vertical scrollbar in IE 10+. */
textarea {
  overflow: auto;
}

/**
 * * 1. Add the correct box sizing in IE 10.
 * * 2. Remove the padding in IE 10. */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * * Correct the cursor style of increment and decrement buttons in Chrome. */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * * 1. Correct the odd appearance in Chrome and Safari.
 * * 2. Correct the outline style in Safari. */



/**
 * * Remove the inner padding in Chrome and Safari on macOS. */
/**
 * * 1. Correct the inability to style clickable types in iOS and Safari.
 * * 2. Change font properties to `inherit` in Safari. */


/* Interactive
 * * ========================================================================== */
/* * Add the correct display in Edge, IE 10+, and Firefox. */
details {
  display: block;
}

/* * Add the correct display in all browsers. */
summary {
  display: list-item;
}

/* Misc
 * * ========================================================================== */
/**
 * * Add the correct display in IE 10+. */
template,
[hidden] {
  display: none;
}

img {
  user-select: none;
}

/**
 * * Add the correct display in IE 10. */
/** CUSTOM CSS STARTS HERE **/

*::-moz-selection {
  background: #0D2159;
  color: #ffffff;
}

*::selection {
  background: #0D2159;
  color: #ffffff;
}

:root {
  --main: #B2DA66;
  --black: #000000;
  --white: #fff;
  --blue: #12D3DB;
  --darkBlue: #0D2159;
  --darkGrey: #4A4A49;
  --lightGrey: #E6E6E6;
  --borderGrey: #494F4F;
  --lightBlue: #9DE7FF;
  --inputBorder: #969696;
  --midGrey: #CBCBCB;
  --midBlue: #486FD9;
  --lightGreen: #D7FF98;
  --lightBlue2: #5F89FF;
  --pastelBlue: #BFD0FF;
  --pastelTeal: #9DE7FF;
  --pastelGreen: #9DE7FF;
  --brightGreen: #12D3DB;
  --lightGrey2: #F8F8F8;
  --darkGreen: #436C03;
  --midGrey: #CBCBCB;
  --borderGrey: #D8D8DA;
  --darkTeal: #10BAC1;
  --cardBody: #1A1A1A;
  --fontFamily: "Open Sans";
}

* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.black {
  color: black !important;
}

.bg-main {
  background-color: var(--main) !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--fontFamily) !important;
}

.darkGrey {
  color: var(--darkGrey) !important;
}

.grey96 {
  color: #969696 !important;
}

.bg-darkGrey {
  background: var(--darkGrey) !important;
}

.darkBlue {
  color: var(--darkBlue) !important;
}

.midGrey {
  color: var(--midGrey) !important;
}

.lightBlue2 {
  color: var(--lightBlue2) !important;
}

.cursor-default {
  cursor: default !important;
}

.text-35 {
  font-size: 35px !important;
  line-height: 42px !important;
}

.text-14 {
  font-size: 14px !important;
  line-height: 20px !important;
}

.text-12 {
  font-size: 12px !important;
  line-height: 14px !important;
}

.text-18 {
  font-size: 16px;
  line-height: 22px;
}

.text-22 {
  font-size: 22px !important;
}

.font-18 {
  font-size: 18px;
  line-height: 25px;
}

.Get__right__upload {
  border-radius: 8px !important;
  font-size: 14px !important;
}

.item-label {
  font-size: 14px !important;
}

@media only screen and (max-width: 1600px) {
  .text-35 {
    font-size: 30px !important;
    line-height: 38px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .text-18 {
    font-size: 16px;
    line-height: 22px;
  }

  .text-35 {
    font-size: 30px !important;
    line-height: 38px !important;
  }
}

@media only screen and (max-width: 992px) {
  .text-35 {
    font-size: 28px !important;
    line-height: 35px !important;
  }
}

.main {
  color: var(--main);
}


.fm {
  font-family: var(--fontFamily);
}

.input {
  background: #E6E6E641 !important;
  max-width: 510px !important;
  width: 100% !important;
}

body {
  font-family: 'Open Sans';
  overflow-x: hidden !important;
  overflow-y: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: white !important;
}

.ant-checkbox-wrapper {
  margin-inline-start: 0px !important;

}

.ant-picker-large:hover {
  border-color: #d9d9d9 !important;
}

.ant-picker-focused {
  border: 1px solid var(--main) !important;
  box-shadow: none !important;
}

.signinPage {
  background-image: url(/public/assets/images/signin/new-sign-in.jpg);
  background-size: cover;
  background-position: center;
}


.pricing-new {
  background-image: url(/public/assets/images/pricing/pricin-new-back.jpg);
  background-size: 300%;
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
}

.pricing-new.pricing {
  height: unset;
}

.pricing-new .footer {
  background-color: transparent;
}

.link__box {
  position: relative;
}

.link__box span {
  position: absolute;
  right: 0px;
  top: 0;
  background-color: #4A4A49;
  color: #fff;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  width: 50px;
  text-align: center;
  font-size: 18px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.link__box.done span {
  background-color: var(--main);
  font-size: 22px;
}

.link__box.done span svg {
  margin-left: 3px;
}




.contact-us .footer {
  background-color: transparent;
}

.signinPage .footer {
  border-top: 1px solid rgb(255 255 255 / 55%);
}

.enterprise__banner .banner__overlay__content__para {
  font-size: 20px;
  max-width: 774px;
}

.professional__banner .banner__overlay__content__para {
  font-size: 20px;
  max-width: 790px;
}

.error__message {
  color: red;
  font-family: var(--fontFamily);
  font-size: 14px !important;
  line-height: 18px !important;
}

.form-control,
.form-control-sm {
  border-radius: 8px !important;
  font-size: 14px !important;
}

.ant-picker-outlined {
  border-radius: 8px !important;
}

.form-control {
  min-height: 39.33px !important;
}

.form-control-sm {
  min-height: 39.33px !important;
}

.react-tel-input.form-control.form-control-lg {
  padding: 0px;
}

.react-tel-input.form-control.form-control-lg input {
  height: 48px;
  border: none;
}

.react-tel-input .flag-dropdown {
  border: none;
}

.CookieConsent {
  align-items: center !important;
  padding-left: 5%;
  padding-right: 5%;
  z-index: 999999 !important;
}

.CookieConsent button#rcc-confirm-button {
  width: 170px;
  height: 43px;
  background: transparent !IMPORTANT;
  border: 1px solid var(--main) !important;
  border-radius: 25px !important;
  font-size: 16px !important;
  padding-top: 2px !important;
}

.CookieConsent button#rcc-confirm-button:hover {
  background-color: var(--main) !important;
  color: #000 !important;
}

.CookieConsent>div:first-child {
  width: 70% !important;
  flex: none !important;
  font-size: 13px;
}

.CookieConsent h4 {
  color: var(--main);
}



@media only screen and (max-width: 1400px) {
  .enterprise__banner .banner__overlay__content__para {
    font-size: 18px;
    max-width: 680px;
  }

  .professional__banner .banner__overlay__content__para {
    font-size: 18px;
    max-width: 690px;
  }
}

@media only screen and (max-width: 1200px) {
  .enterprise__banner .banner__overlay__content__para {
    font-size: 18px;
    max-width: 580px;
  }

}


@media only screen and (max-width:1200px) {
  .main-padding {
    padding-left: 30px !important;
    padding-right: 30px !important;

  }
}

.w-sm-50 {
  width: 50%;
}

.w-sm-85 {
  max-width: 270px;
}

@media only screen and (max-width:576px) {
  .main-padding {
    padding-left: 15px !important;
    padding-right: 15px !important;

  }

  .w-sm-50,
  .w-sm-85 {
    width: 100%;
  }
}

.calender {
  height: 50vh !important;
}

.TabPane::-webkit-scrollbar {
  width: 12px !important;
}

.TabPane::-webkit-scrollbar-track {
  background: rgb(255, 255, 255) !important;
}

.TabPane::-webkit-scrollbar-thumb {
  background-color: rgb(51, 51, 51) !important;
  border-radius: 20px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}

@media only screen and (max-width: 992px) {
  .TabPane::-webkit-scrollbar-thumb {
    border: 5px solid rgb(255, 255, 255) !important;
  }
}

.nowrap {
  white-space: nowrap !important;
}

.Dashboard__container__content {
  padding-right: 40px;
}

@media only screen and (max-width: 1500px) {
  .Dashboard__container__content {
    padding-right: 10px;
  }
}

.ant-upload-list-item-error {
  max-width: fit-content;
  background: rgba(75, 171, 226, 0.1019607843);
  height: 30px !important;
  padding: 0px 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: var(--darkGrey) !important;
}

.ant-upload-list-item-name {
  color: var(--darkGrey) !important;
}

.ant-upload-list-item-error .ant-upload-list-item-actions .anticon {
  color: var(--darkGrey) !important;
}

.draft__button {
  border: 1px solid var(--darkGrey) !important;
  background: white;
  color: var(--darkGrey) !important;
  max-width: 150px;
  height: 40px;
  width: 100%;
  border-radius: 8px;
}

.General .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
  content: "";
  background-image: url('../public/assets/images/Dashboard/arrow-down.png') !important;
  background-repeat: no-repeat !important;
  height: 18px;
  background-size: cover !important;
  width: 15px;
  margin-top: 10px;
  margin-right: 10px;
}

.Login--select::before {
  position: absolute !important;
  content: "" !important;
  right: 0px !important;
  width: 40px;
  height: 100% !important;
  background-image: url('../public/assets/images/Dashboard/arrow_white.svg') !important;
  background-repeat: no-repeat !important;
  background-size: 18px !important;
  background-position: center !important;
  z-index: 1111 !important;

}

.feedback-container .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
  background-image: url('../public/assets/images/feedbackarrow.svg') !important;
  content: "";
  background-repeat: no-repeat !important;
  height: 18px;
  background-size: cover !important;
  width: 15px;
  margin-top: 10px;
  margin-right: 10px;
}


.feedback .ant-select-selector {
  background-color: black !important;
  width: 100% !important;
  max-width: 100% !important;
  border: 0.5px solid #96969678 !important;
  color: white !important;
}

.feedback .ant-select-selection-placeholder {
  color: #969696 !important;
}

@media only screen and (max-width: 1400px) {
  .draft__button {
    height: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .draft__button {
    height: 40px;
  }
}

@media only screen and (max-width: 992px) {
  .Login--select::before {
    right: 0px !important;
  }
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  width: 100% !important;

}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
  background: none !important;
  color: var(--darkGrey) !important;
  font-size: 20px !important;
}

.invite-button {
  outline: none !important;
  border: 1px solid #5F89FF !important;
  color: var(--white) !important;
  width: 200px !important;
  border-radius: 8px !important;
  height: 37px !important;
  background: #5F89FF;
  font-size: 14px !important;
  transition: all 0.3s ease-in-out;
}

.invite-button:hover {
  color: #5F89FF !important;
  background: white;
}

@media only screen and (max-width: 768px) {
  .invite-button {
    width: 150px !important;
  }

  .offers__search {
    width: 100%;
  }


}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: auto !important;
}

.download__button {
  outline: none;
  border: 1px solid #969696;
  background: #969696;
  color: white;
  border-radius: 8px;
  width: 153px !important;
}

@media only screen and (max-width: 1200px) {
  .download__button {
    width: 136px !important;

  }

}

@media only screen and (max-width: 576px) {
  .download__button {
    width: 180px !important;

  }

}

@media only screen and (max-width: 350px) {
  .download__button {
    width: 150px !important;
  }

}

.pointer {
  cursor: pointer;
}

.red {
  color: red !important;
}

.error__button {
  outline: none;
  border: 1px solid red;
  background: white;
  color: red;
  border-radius: 4px;
  width: 80px !important;
}

.Dashboard__avatar {
  min-width: 45px !important;
  min-height: 45px !important;
  max-width: 45px !important;
  max-height: 45px !important;
}

.width-90 {
  width: 90px !important;
}

.blue {
  color: #486FD9;
}

.Pjobs__tabs .react-tabs__tab-list {
  border-bottom: 0px solid !important;
  min-width: 240px !important;
  max-width: 240px !important;
  width: 240px !important;
  box-shadow: 0px 3px 2px #00000008 !important;
  border: 0.5px solid #41414080 !important;
  padding-left: 0px !important;
  border-radius: 8px !important;
  height: 40px !important;
  margin-bottom: 0px !important;
}

.tab__tag {
  font-size: 13px;
  color: #969696 !important;
  background: #E6E6E6 0% 0% no-repeat padding-box;
  padding: 2px 5px !important;
}

.Pjobs__tabs .react-tabs__tab {
  padding: 7px 0px !important;
  width: 50% !important;
  text-align: center !important;
  margin-top: -1px !important;
  overflow-y: hidden !important;
  height: 38px !important;
  user-select: none !important;
  font-weight: 500 !important;
}

.Pjobs__tabs .react-tabs__tab:first-child {
  border-right: 0.5px solid #41414080 !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.Pjobs__tabs .react-tabs__tab:last-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.Pjobs__tabs .react-tabs__tab--selected,
.Pjobs__tabs .react-tabs__tab--selected:focus {
  border-bottom: 0px solid !important;
  border: 0px solid !important;
  color: var(--darkBlue) !important;
  font-family: var(--fontFamily) !important;
  box-shadow: none !important;
  background: #F4F5F7 !important;
  overflow: hidden !important;
}

@media only screen and (max-width: 400px) {
  .Pjobs__tabs .react-tabs__tab-list {
    min-width: auto !important;
    max-width: 400px !important;
    width: 100% !important;
    margin-right: 16px !important;
    height: 37px !important;
  }

  .Pjobs__tabs .react-tabs__tab {
    height: 35px !important;
  }
}

.selectboxes .ant-select-dropdown {
  height: 200px !important;
}

.inboxTabs .ant-tabs-nav-wrap {
  padding-left: 0px !important;
}

.inboxTabs .ant-tabs-tab-active::before {
  background-color: white !important;
}

.inboxTabs .ant-tabs .ant-tabs-tab:hover {
  color: #4096ff !important;
}

.inboxTabs .ant-tabs-tab-active {
  color: #486FD9 !important;
}

.inboxTabs .ant-tabs-tab {
  margin-top: 0px !important;
}

.inboxTabs .ant-tabs-content-holder {
  border-left: 0px solid !important;
}

.feedback-container .ant-select-single.ant-select-sm.ant-select-open .ant-select-selection-item {
  color: white !important;
}

.ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
  margin-left: 6px !important;
}

.ant-tooltip {
  z-index: 20000 !important;
}

.landscape__select,
.landscape__input {
  width: 100% !important;
  max-width: 100% !important;
}

.landscape__input {
  background-color: white !important;
  height: 38px !important;
}

.landscape__input:hover {
  border-color: #ced4da !important;
}

.General .ant-select-selector::before {
  content: "";
  background: url('../public/assets/images/feedbackarrow.svg') !important;
  background-repeat: no-repeat !important;
  height: 18px;
  background-size: contain !important;
  width: 15px;
  margin-top: 10px;
  margin-right: 10px;
}

.General .ant-select-selector {
  height: 40px !important;
  border-radius: 8px !important;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  inset-inline: 12px !important;
}

.ant-select-selector .ant-select-selection-search-input {
  height: 20px !important;
  padding-left: 0px !important;
  margin-left: -4px !important;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-overflow-item {
  height: 100% !important;
  margin: auto 0px !important;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  background: rgba(75, 171, 226, 0.1019607843) !important;
  color: var(--darkGray) !important;
  font-family: var(--fontFamily) !important;
  display: flex !important;
  align-items: center !important;
  height: 20px !important;
  font-size: 14px !important;
  margin-right: 4px !important;
}

.landscape__right {
  background-color: #EDEDED;
  border: 1px solid #ced4da;
}

.landscape__right__inner {
  border: 1px solid #ced4da;
}

.menu__dots {
  color: #999999;
}

.overview__box {
  border: 1px solid #ced4da;
  padding: 5px 10px;
}

.overview__box__arrow {
  width: 25px;
  transform: rotate(310deg);
}


.new--select .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px !important;
  padding-left: 10px !important;
}

.react-kanban-column {
  width: 250px !important;
  margin: 0 !important;
}

:has(> .custom-card) {
  width: 100% !important;
  overflow: hidden !important;
}

:has(> .react-kanban-column) {
  white-space: unset !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-around;
  flex-wrap: wrap !important;
  gap: 10px !important;
}

.react-kanban-column:last-child {
  width: 100% !important;
}

@media only screen and (max-width: 1400px) {
  .react-kanban-board {
    max-width: 1400px !important;
    min-width: auto !important;
    width: 100%;
  }

  .react-kanban-column {
    width: 24% !important;
  }

  :has(> .react-kanban-column) {
    gap: 10px !important;
  }

  .custom-card p {
    font-size: 9px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .react-kanban-board {
    max-width: 100% !important;
    min-width: 900px !important;
    width: 100%;
    overflow-x: scroll !important;
  }

  :has(> .react-kanban-column) {
    gap: 10px !important;
  }

  .react-kanban-column {
    width: 24% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .react-kanban-column {
    overflow: hidden !important;
    width: 49% !important;
  }
}

@media only screen and (max-width: 992px) {
  .react-kanban-column {
    width: 48% !important;
    overflow: hidden !important;
  }

  .chat__page {
    padding-top: 80px !important;
  }
}

@media only screen and (max-width: 768px) {
  .react-kanban-column {
    width: 100% !important;
  }
}

@media only screen and (max-width: 576px) {
  .react-kanban-column {
    width: 100% !important;
  }

}

.Privacy::marker {
  color: #486FD9 !important;
  background-color: #486FD9 !important;
}

.Privacy__bottom {
  background-color: var(--lightGrey2);
}

.Privacy__bottom__text {
  max-width: 900px;
}

.ATS .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
  content: "";
  background: url('../public/assets/images/feedbackarrow.svg') !important;
  background-repeat: no-repeat !important;
  height: 18px;
  background-size: cover !important;
  width: 15px;
  margin-top: 5px !important;
}

.ATS .ant-select-open .ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
  content: "";
  background: url('../public/assets/images/feedbackarrow_blue.svg') !important;
  background-repeat: no-repeat !important;
  height: 18px;
  background-size: cover !important;
  width: 15px;
  margin-top: 5px !important;
}

.Dashboard__container {
  padding: 0px !important;
}

.Ejobs__tabs .react-tabs__tab-list {
  padding-left: 0px !important;
}

.container-dash {
  padding: 0px 60px !important;
}

.container-dash-mid {
  padding: 0px 60px 0px 48px !important;
}

.DAccount__btn {
  padding-left: 0px !important;
}

@media only screen and (max-width: 1200px) {
  .container-dash {
    padding: 0px 50px !important;
  }

  :has(> .react-kanban-column) {
    gap: 0px !important;
  }
}

@media only screen and (max-width: 992px) {
  .container-dash {
    padding: 0px 30px !important;
  }
}

@media only screen and (max-width: 576px) {
  .container-dash {
    padding: 0px 15px !important;
  }
}

.word-wrap {
  word-wrap: break-word;
  word-break: break-all;
}

.css-99cbwm.MainLayout-container {
  min-height: 750px !important;
  height: 100% !important;
  overflow-y: hidden !important;
  display: flex;
}

.css-99cbwm.MainLayout-container>div {
  height: 100% !important;
  overflow-y: hidden !important;
}

.btn--blue {
  background: #5F89FF;
  padding: 7px 25px;
}

.bg-blue {
  background: #5F89FF;
}

.btn--main {
  background: #A6DD50;
  padding: 7px 25px;
  color: white;
  border: 1px solid #A6DD50;
  transition: all 0.3s ease-in-out;
}

.btn--main:hover {
  background: white;
  color: #A6DD50;
}

.bg-grey {
  background: #E6E6E6 !important;
}

.bg-lightmain {
  background-color: #F2FAE5;
}

.btn--main-light {
  background: #CFFD5E;
  padding: 7px 25px;
}

.enterprise .enterprise-active {
  color: var(--main);
}

.professionals .professionals-active {
  color: var(--main);
}

.pricing .pricing-active {
  color: var(--main);
}

.about-us .about-us-active {
  color: var(--main);
}

.signup .signup-active {
  color: var(--main);
}

.book-a-demo .book-a-demo-active {
  color: var(--main);
}

.bg-darkBlue {
  background-color: var(--darkBlue) !important;
}

.table__outer .ant-select-selector {
  display: none !important;
}

.table__outer .ant-pagination .ant-pagination-item {
  display: none !important;
}

.table__outer .ant-pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.table__outer .ant-pagination>*:first-child {
  order: 1 !important;
}

.table__outer .ant-pagination-total-text {
  height: 29px !important;
}

.table__outer__pro .ant-pagination-total-text {
  padding-left: 10px !important;
}

.ant-table-row {
  border-radius: 15px !important;
  border: 1px solid var(--borderGrey) !important;
}

.table__outer .ant-pagination-prev,
.table__outer .ant-pagination-next {
  background-color: white !important;
  border: 1px solid #70707041 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.candidates__tabs .react-tabs__tab-list {
  padding: 0px 50px !important;
}

@media only screen and (max-width: 1200px) {
  .candidates__tabs .react-tabs__tab-list {
    padding: 0px 40px !important;
  }
}

@media only screen and (max-width: 992px) {
  .candidates__tabs .react-tabs__tab-list {
    padding: 0px 20px !important;
  }
}

@media only screen and (max-width: 576px) {
  .candidates__tabs .react-tabs__tab-list {
    padding: 0px 5px !important;
  }
}

.dash__btn {
  height: 40px !important;
  max-width: 100px !important;
}

.closed__body {
  background-color: #EDF8DC !important;
}

.ant-switch.ant-switch-checked {
  background: #A6DD50 !important;
}

.candidates__invite__btn {
  height: 30px !important;
}

.candidates__opt .ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus-within {
  box-shadow: none !important;
  border: 1px solid #4096ff !important;
  color: #4096ff !important;
}

.candidates__searchbox__icon {
  font-size: 25px !important;
}

.max-w-none {
  max-width: none !important;
}

.cross__icon {
  color: #4a4a493e;
}

.cross__icon:hover {
  color: var(--darkGrey);
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent !important;
  width: 0px !important;
}

.candidates .ant-radio-button-wrapper {
  overflow: hidden !important;
}

.bg-red {
  background-color: red !important;
}

.DAccount__btn {
  font-size: 14px !important;
}

button {
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
  border-radius: 8px !important;
}

.ant-tabs .ant-tabs-tab-btn,
.react-tabs__tab {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
}

.Download__icon {
  width: 25px !important;
}

.radius8 {
  border-radius: 8px !important;
}

.ant-table-row {
  transition: all 0.3s ease-in-out !important;
}

.ant-table-row>.ant-table-cell-row-hover {
  background: #F4F5F7 !important;
}

.ant-table-thead>tr>th,
.table__outer {
  background: #F4F5F7 !important;
}

.ant-dropdown {
  border: 0.5px solid #5F89FF !important;
  box-shadow: 0px 4px 30px #0000000B !important;
  border-radius: 8px !important;
}

.ant-switch {
  border-radius: 100px !important;
}

.ant-dropdown-arrow {
  display: none !important;
}

input::placeholder,
.form-control::placeholder,
input:placeholder-shown,
.form-control:placeholder-shown,
.form-control-md:placeholder-shown,
.ant-select-selector {
  font-size: 14px !important;
  font-weight: normal !important;
}

.Indexcore__progress circle:nth-of-type(2) {
  position: relative;
  z-index: 99;
}

.Indexcore__progress circle:nth-of-type(2)::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  z-index: 100;
}

.min-h-100 {
  min-height: 100vh;
}
input, .form-control{
  font-size: 16px !important;
}
input::placeholder {
  font-size: 14px !important;
  font-weight: normal !important;
}

.eprofile .ant-input-group-addon {
  background-color: transparent !important;
  color: var(--midGrey) !important;
  padding-right: 0px !important;
  font-weight: normal !important;
  font-family: var(--fontFamily) !important;
}

.eprofile .ant-input-outlined {
  border-left: 0px solid !important;
  padding-left: 0px !important;
  min-height: 39.33px !important;
  font-family: var(--fontFamily) !important
}

.ant-input-outlined:focus-within {
  box-shadow: none !important;
  border-color: #d9d9d9 !important;
}

.eprofile .ant-input-outlined:hover {
  border-color: #d9d9d9 !important;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input:placeholder-shown {
  font-size: 16px !important;
  font-weight: 700 !important;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-select:hover .ant-select-clear{
  opacity: 0 !important;
}
.ant-select-multiple .ant-select-selection-item{
  margin-top: 4px !important;
  margin-bottom: 0px !important;
}