.otp__protect__icon {
    width: 50px;
    height: 50px;
    display: flex;
    background: var(--main);
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 30px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.otp__protect__fields div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.otp__protect__fields div input {
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 24px;
    text-align: center;
    margin-right: 12px;
    text-transform: uppercase;
    color: #494949;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background: #fff;
    background-clip: padding-box;
}

.otp__protect__fields div input:focus {
    appearance: none;
    outline: 0;
    box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}

.otp__protect__fields p {
    max-width: 400px;
    width: 100%;
}

.resend__otp__button {
    background: none;
    padding: 0px;
    margin: 20px 0;
}

.otp__protect__fields div.otp__cta {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.otp__protect__fields div.otp__cta button {
    width: 200px;
}

@media only screen and (max-width: 576px) {
    .otp__protect__fields div input {
        width: 30px;
        height: 30px;
    }
}