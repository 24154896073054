.Whybanner {
    height: 130vh;
    background: linear-gradient(21deg, rgba(178,218,102,1) 0%, rgba(61,80,32,1) 0%, rgba(0,0,0,1) 47%, rgba(13,33,89,1) 100%);
    padding-top: 130px;
}
.why__para{
    max-width: 90%;
}
.Why__bottom{
    margin-bottom: -500px;
}
.Why__bottom--img{
    border-radius: 16px;
    box-shadow: 0px 0px 20px #00000014;
}
@media only screen and (max-width: 1400px){
    .why__para{
        max-width: 92%;
    }
}
@media only screen and (max-width: 992px){
    .why__para{
        max-width: 92%;
    }
    .Why__bottom{
        margin-bottom: -350px;
    }
}
@media only screen and (max-width: 768px){
    .Whybanner{
        padding-top: 150px !important;
        height: auto;
        height: auto;
        padding-bottom: 50px;
    }
    .why__para{
        max-width: 100%;
    }
    .Why__bottom{
        margin-bottom: 0px;
    }
}
