/* .DAccount__top__settings{
  /* background: #E3EBFF !important; */
  /* position: fixed;
  top: 130px;
  width: 100%;
  z-index: 4; 
} */
.DAccount__top__settings .ant-tabs-nav-wrap {
  max-width: 1140px;
  margin:auto;
  padding-right: 15px;
  padding-left: 15px;
}
.DAccount__btn{
  background: #e3ebff00;
  color: #0D2159;
  border: 0px solid;
}
.persona__img{
  width: 100px !important;
}
.dash_tag{
  background-color: #F2F6FF;
  padding: 2px 10px;
}
.dash_tag_cross{
  color: #A7A7A7;
}
.dash_tag_cross:hover{
  color: var(--darkGrey);
}
/* .edash{
  margin-top: 170px;
} */
@media only screen and (max-width: 992px){
  .DAccount__top2{
    position: absolute;
    top: -100px;
    width: 100%;
    height: 100px;
    left: 0;
    background-color: white;
    z-index: 2;
  }

} 