.HS1__text {
    max-width: 910px;
}

.italic {
    font-style: italic;
}

.HS1__dashtext {
    max-width: 765px;
}

.hs2-img1 {
    background-color: #D8E2FD !important;
    border-radius: 8px !important;
}

.hs2-img2 {
    background: #FCFCFC !important;
    border-radius: 8px !important;
}

.hs2-img3 {
    background: rgb(182, 198, 238);
    background: linear-gradient(21deg, rgba(182, 198, 238, 1) 41%, rgba(228, 235, 253, 1) 100%);
    border-radius: 8px !important;
}
.hs2__text{
    max-width: 900px;
}