.indexScore__progressBar .CircularProgressbar.progressBars {
  max-width: 80%;
  margin-top: 0px;
  margin-bottom: 22px;
}


.indexScore__progressBar__inner text.CircularProgressbar-text {
  display: none;
}

.yourIndex__pro__info h2 {
  color: var(--main);
  letter-spacing: -0.72px;
  font-size: 36px;
  font-weight: 300;
}

.yourIndex__pro__left__box {
  padding-left: 40px;
  padding-right: 20px;
}

.yourIndex__pro__info p {
  color: var(--darkGrey);
  letter-spacing: -0.46px;
  font-size: 16px;
}

.yourIndex__pro__info {
  margin-bottom: 40px;
}

.yourIndex__insights__detail__hd {
  color: var(--darkBlue);
  font-size: 36px;
  letter-spacing: -0.72px;
}

.yourIndex__insights__detail__para {
  color: var(--darkGrey);
  letter-spacing: -0.46px;
  font-size: 18px;
}

.yourIndex__insights__group {
  margin-bottom: 25px;
}

.yourIndex__insights__box {
  border: 1px solid #C4C4C4;
  padding: 12px 12px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.yourIndex__insights__box__desc{
  max-width: 490px;
  width: 100%;
}
.yourIndex__insights__label {
  border: 1px solid var(--darkGrey);
  display: inline-block;
  padding: 3px 8px;
  border-radius: 8px;
  margin-bottom: 25px;
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
}
.disabled-message{
  font-family: var(--fontFamily) !important;
}
.yourIndex__insights__subheading {
  color: var(--midGrey);
  font-size: 16px;
  letter-spacing: -0.32px;
}

.yourIndex__insights__para {
  letter-spacing: -0.36px;
  color: var(--darkGrey);
  font-size: 16px;
  margin-bottom: 25px;
}

.yourIndex__insights_learnMore svg {
  border: 1px solid var(--lightBlue2);
  color: var(--lightBlue2);
  font-size: 24px;
  padding: 2px;
  border-radius: 50%;
}

.yourIndex__insights_learnMore {
  font-size: 16px;
  color: var(--lightBlue2);
  display: inline-block;
}

.yourIndex__insights__group__hd {
  font-size: 16px;
  color: var(--darkGrey);
  margin-bottom: 15px;
}


.danger__label {
  color: #FE0001;
  border-color: #FE0001;
}

.warning__label {
  color: #FD895B;
  border-color: #FD895B;
}

.good__label {
  color: var(--main);
  border-color: var(--main);
}



.yourIndex__pro__index_score {
  margin-top: 150px !important;
  margin-left: 40px;
}

.yourIndex__pro__index_score sup {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--darkGrey);
  font-weight: 300;
}

.score-value {
  color: var(--main) !important;
  font-size: 75px !important;
  line-height: 85px;
}

.score-value sup {
  color: #436C03 !important;
  margin-left: -5px;
  font-weight: 500;
  font-size: 25px !important;
}

.yourIndex__pro__index_score__totalScore {
  color: var(--midGrey);
  font-size: 26px;
  line-height: 10px;
}

.pro__index__page {
  margin-top: 70px;
}

.indexScore__progressBar {
  background-color: #E6E6E626;
  border: 0.5px solid #707070;
  border-radius: 10px;
  position: fixed;
  top: 100px;
  width: 38%;
  right: 60px;
  max-width: 700px;
}

.yourIndex__insights__group {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.yourIndex__insights__group__parent__box {
  flex: 0 0 calc(33.33% - 25px); 
  margin-bottom: 20px; 
  margin-left: 15px;
}

.yourIndex__insights__box {
  max-width: 100%; 
}

.yourIndex__insights__group__parent__box {
  display: flex;
  flex-direction: column;
}

.yourIndex__insights__box {
  flex: 1;
}

.yourIndex__insights__group__parent__box:first-child, .yourIndex__insights__group__parent__box:nth-child(4) {
    margin-left: 0px;
}

.pro__index__page .progressBar__candidates{
  height:15px !important;
}
.pro__index__page .progressBar__candidates .progress-bar{
  height: 15px;
}


.indexscore__info {
  font-size: 32px;
  color: #E0E0E0;
  line-height: 1;
}

.indexscore__val {
  color: #A6DD50;
  font-weight: 600;
}

.indexscore__info__text p{
  color: #4A4A49BE; 
  margin:0px;
}

ul.improvement-list.improve.scroll__data {
  overflow-y: scroll;
  max-height: 300px;
} 

.improvement__popup .ant-modal-title {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.required__error{
  color:red;
}
.required__warning{
  color:orange;
}

.custom__button,.custom__button:hover {
  width: 180px !important;
  border-radius: 4px !important;
  height: 40px !important;
  background: #b2da66 !important;
  color: #fff !important;
  border-color: #b2da66 !important;
}

.disabled-box{
  position: relative;
}
.disabled-box .yourIndex__insights__box{
  opacity: 0.5; 
  pointer-events: none; 
}

@media only screen and (max-width: 1500px) {
  .yourIndex__pro__info__heading {
    font-size: 33px !important;
  }

  .indexScore__progressBar {
    top: 100px;
    width: 36.5%;
    right: 50px;
  }

  .yourIndex__pro__index_score {
    margin-top: 120px !important;
    margin-left: 40px;
  }

  .score-value {
    font-size: 60px !important;
    line-height: 75px;
  }

  .score-value sup {
    margin-left: -5px;
    font-size: 21px !important;
    margin-top: -5px;
  }

  .yourIndex__pro__index_score__totalScore {
    font-size: 22px;
    line-height: 10px;
  }
}

@media only screen and (max-width: 1400px) {
  .yourIndex__insights__box__desc{
    font-size: 14px ;
  }
}
@media only screen and (max-width: 1350px) {
  .indexScore__progressBar {
    top: 100px;
    width: 36.5%;
    right: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .indexScore__progressBar {
    top: 100px;
    width: 36.5%;
    right: 20px;
  }
}

@media only screen and (max-width: 1100px) {
  .indexScore__progressBar {
    top: 110px;
    width: 35%;
    right: 20px;
  }

  .yourIndex__pro__index_score {
    margin-top: 100px !important;
    margin-left: 40px;
  }

  .score-value {
    font-size: 55px !important;
    line-height: 75px;
  }

  .score-value sup {
    margin-left: -5px;
    font-size: 20px !important;
    margin-top: -3px;
  }

  .yourIndex__pro__index_score__totalScore {
    font-size: 20px;
    line-height: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .indexScore__progressBar {
    top: 120px;
    width: 47%;
    right: 20px;
  }

  .yourIndex__pro__left__box {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .indexScore__progressBar {
    top: auto;
    max-width: 450px;
    width: 100%;
    right: auto;
    position: static;
  }

  .indexScore__progressBar .CircularProgressbar.progressBars {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .yourIndex__pro__left__box {
    padding-right: 0px;
  }
}