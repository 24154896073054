.contactform {
  min-height: 100vh;
  background: linear-gradient(21deg, rgba(178,218,102,1) 0%, rgba(61,80,32,1) 0%, rgba(0,0,0,1) 47%, rgba(13,33,89,1) 100%);
  border-bottom: 1px solid #707070;
  
}
.contactform--button {
  max-width: 250px;
  width: 100%;
}
.contactform__heading {
  color: var(--white);
}
.contactform-border{
  border-top: 1px solid rgba(255, 255, 255, 0.247);
}
.contactform__para {
  color: var(--white);
}
.contactform__para--span {
  color: var(--main);
}
.contact__form__fields .signIn__form--input.form-control::placeholder{
  color: #969696 !important;
}
.contact__form__fields .signIn__form--input.form-control{
  background: transparent !important;
  border: 0.5px solid #96969678 !important;
  border-radius: 4px !important;
}
.contact__form__fields .signIn__form--input.form-control:focus{
  border-color: var(--main) !important;
}

.contact__form__fields .signIn__form__button {
  border-radius: 8px !important;
  border-color: var(--main) !important;
  background: transparent !important;
  color: var(--main) !important;
}

.contact__form__fields .signIn__form__button:hover {
  background: var(--main) !important;
  color: #000 !important;
}

@media only screen and (max-width: 576px) {
  .contactform--button {
    max-width: 100%;
    width: 100%;
  }
}