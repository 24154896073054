.Get {
  min-height: 100vh;
}
.Get__left {
  border-radius: 14px;
  min-height: 1000px;
  max-width: 640px;
}
.Get__left--blue {
  background: var(--pastelBlue);
}
.Get__left--green {
  background: var(--pastelGreen);
}
.Get__left__content__title {
  color: var(--darkBlue);
  font-size: 18px;
  font-weight: 600;
  font-family: var(--fontFamily);
}
.Get__left__content__heading {
  font-size: 40px;
  color: var(--black);
  font-weight: 300;
  font-family: var(--fontFamily);
}
.Get__left__content__para {
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  font-family: var(--fontFamily);
  color: var(--black);
}
.Get__left__bottom {
  border-radius: 14px;
}
.Get__left__bottom--blue {
  background: var(--lightBlue2);
}
.Get__left__bottom--green {
  background: var(--brightGreen);
}
.Get__left__bottom--main {
  background: var(--main);
}
.Get__left__bottom--para {
  color: var(--white);
}
.Get__left__bottom__info__block {
  height: 70px;
  width: 70px;
  background: var(--white);
  border-radius: 14px;
}
.Get__left__bottom__info__content__para {
  font-family: var(--fontFamily);
  color: var(--white);
  font-weight: 400;
  font-size: 16px;
}
.Get__right {
  background: var(--lightGrey2);
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}
.Get__right--width {
  max-width: 610px;
}
.Get__right__para {
  font-family: var(--fontFamily);
}
.Get__right__para__link {
  color: var(--black);
}
.Get__right__para__link__icon {
  font-size: 25px;
}
.Get__right__para__link:hover {
  color: var(--black);
}
.Get__right__upload {
  width: 500px !important;
  border-radius: 8px !important;
  border-color: var(--lightBlue2) !important;
  background: rgba(75, 171, 226, 0.1019607843) !important;
  color: var(--lightBlue2) !important;
  height: 50px !important;
  font-size: 14px !important;
}
.Get__right__upload:hover {
  background: rgba(75, 171, 226, 0.1019607843) !important;
  color: var(--lightBlue2) !important;
  border-color: var(--lightBlue2) !important;
}
.Get__right__upload__small {
  font-family: var(--fontFamily);
  color: var(--inputBorder);
}
.Get__right__label {
  color: var(--inputBorder);
  font-weight: light;
}
.Get__right__label__link {
  font-weight: normal;
  color: var(--darkGrey);
}
.Get__right__label__link:hover {
  color: var(--darkGrey);
}
.Get__right__button {
  outline: none !important;
  border: 0px solid !important;
  color: var(--white) !important;
  width: 200px !important;
  border-radius: 8px !important;
  height: 37px !important;
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
}
.Get__right__button--blue {
  background: var(--lightBlue2) !important;
}
.Get__right__button--green {
  background: var(--brightGreen) !important;
}
.Get__right__button--darkGrey {
  background: var(--darkGrey) !important;
}
.Get__right__button--home {
  max-width: 450px !important;
}

.form-control {
  background: rgba(0, 0, 0, 0) !important;
  font-family: var(--fontFamily);
}
.form-control:focus {
  box-shadow: none !important;
  border-color: var(--main) !important;
}
.form-control::-moz-placeholder {
  color: var(--midGrey) !important;
  font-weight: 200px !important;
  font-size: 16px !important;
}
.form-control:-ms-input-placeholder {
  color: var(--midGrey) !important;
  font-weight: 200px !important;
  font-size: 16px !important;
}
.form-control::placeholder {
  color: var(--midGrey) !important;
  font-weight: 200px !important;
  font-size: 16px !important;
}

.form-check-input {
  border-radius: 100% !important;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer !important;
}


.Get__left__content__heading.Professional__thank__you {
  color: var(--lightBlue2);
}

.thank__you__email__message {
  font-size: 18px;
  color: #969696;
  letter-spacing: -0.48px;
}

.thank__you__not__received__code {
  font-size: 18px;
  margin-top: 80px;
  letter-spacing: -0.48px;
}




@media only screen and (max-width: 1400px) {
  .Get__left {
    min-height: 950px;
    max-width: 550px;
  }
  .Get__right--width {
    max-width: 500px;
  }
  .Get__left__content__title {
    font-size: 16px;
  }
  .Get__left__content__heading {
    font-size: 40px;
  }
  .Get__left__content__para {
    font-size: 16px;
    line-height: 23px;
  }
  .Get__left__bottom__info__content__para {
    font-size: 16px;
    line-height: 23px;
  }
  .Get__left__bottom__info__block {
    height: 60px;
    width: 60px;
  }
  .Get__right__para__link__icon {
    font-size: 22px;
  }
  .Get__right__upload {
    width: 250px !important;
  }
  .Get__right__button {
    max-width: 170px !important;
    height: 37px !important;
  }
  .Get__right__label {
    font-size: 16px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 1200px) {
  .Get__left {
    min-height: 900px;
    max-width: 470px;
    width: 100%;
  }
  .Get__right--width {
    max-width: 440px;
    width: 100%;
  }
  .Get__left__content__title {
    font-size: 16px;
  }
  .Get__left__content__heading {
    font-size: 40px;
  }
  .Get__left__content__para {
    font-size: 16px;
    line-height: 23px;
  }
  .Get__left__bottom__info__content__para {
    font-size: 16px;
    line-height: 21px;
  }
  .Get__left__bottom__info__block {
    height: 50px;
    width: 50px;
  }
  .Get__right__para__link__icon {
    font-size: 20px;
  }
  .Get__right__upload {
    width: 270px !important;
  }
  .Get__right__button {
    max-width: 150px !important;
    height: 37px !important;
  }
  .Get__right__label {
    font-size: 16px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 992px) {
  .Get__left {
    min-height: 600px;
    margin-top: 75px !important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    max-width: 100%;
  }
  .Get__right--width {
    max-width: 100%;
  }
  .Get__right {
    background: var(--white);
  }
}
@media only screen and (max-width: 576px) {
  .Get__left {
    margin-top: 72px;
  }
  .Get__right__upload {
    font-size: 14px !important;
    width: 100% !important;
  }
  .Get__left__content__heading {
    font-size: 30px;
  }
  .Get__left__content__para {
    font-size: 16px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 400px) {
  .Get__left {
    margin-top: 62px;
  }
  .Get__left__content__heading {
    font-size: 25px;
  }
}