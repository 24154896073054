.border-bottom-dotted {
    border-bottom: 2px solid #707070;
    border-bottom-style: dotted;
    padding-bottom: 20px;
}

.filter__btn {
    height: 38px;
}

.filters__overlay .ant-dropdown-menu {
    min-width: 200px !important;
    width: 100% !important;
    box-shadow: 0px 4px 30px #0000000B !important;
    border: 0.5px solid #5F89FF !important;
}

.filters__overlay .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: white !important;
    color: #5F89FF !important;
}

.filters__overlay .ant-dropdown-menu .ant-dropdown-menu-item:hover .ant-dropdown-menu-title-content p {
    color: #5F89FF !important;
    transition: all 0.3s ease-in-out;
}

.location__overlay .ant-dropdown-menu {
    width: 310px !important;

}

.country__name:hover {
    color: #5F89FF !important;
    transition: all 0.3s ease-in-out;
}

.cancel__icon {
    color: #888888;
}

.report__box {
    border-radius: 8px;
    height: 140px;
}

.report__box__main {
    background-color: var(--main);
}

.report__box__white {
    background-color: white;
    border: 1px solid var(--main);
}

.report__box__disabled {
    background-color: #E6E6E6;
    border: 0px solid var(--main);
}


.stat__text {
    font-size: 12px;
    user-select: none;
}

.stat__border-right {
    border-right: 1px solid var(--darkGrey);
}

.stat__dottedline {
    width: 16.66%;
    border-right: 2px solid var(--midGrey);
    border-right-style: dotted;
    height: 100%;
}

.stat__index-bottom0 {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    right: -4px;
    user-select: none;
}

.stat__index-bottom1 {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    right: -7px;
}

.stat__index-bottom {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    right: -11px;
}
.ATS .stat__left{
    min-height: 246px;
}
.stat__left {
    padding: 15px 0;
}

.stat__bars {
    position: absolute;
    left: 0.5px;
    top: 10px;
}
.stats__modal .stat__bars{
    left: 0.5px;
}
.stat__bar {
    height: 17px;
    margin-top: 5px;
    width: auto;
}

.stat__index-right {
    left: 100%;
}

.stat__bar1 {
    background-color: #436C03;
}

.stat__bar2 {
    background-color: #A6DD50;
}

.stat__bar3 {
    background-color: #CFFD5E;
}

.stat__bar4 {
    background-color: #0D2159;
}

.stat__bar5 {
    background-color: #486FD9;
}

.stat__bar6 {
    background-color: #BFD0FF;
}

.stat__bar7 {
    background-color: #2D6980;
}

.stat__bar8 {
    background-color: #10BAC1;
}

.stat__bar9 {
    background-color: #12D3DB;
}

.stat__bar10 {
    background-color: #4A4A49;
}

.ant-modal .ant-modal-close:hover {
    background-color: white !important;
}

.ant-modal-close-x {
    font-size: 22px !important;
    color: var(--darkGrey) !important;
}

.ant-modal-title {
    color: var(--darkGrey) !important;
    font-family: "Open Sans" !important;
}
.bullet{
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
}
.chartslice{
    min-width: 50%;
}
.text-10{
    font-size: 10px;
}
@media only screen and (max-width: 1400px) {
    .bullet{
        width: 15px;
        min-width: 15px;
        height: 15px;
        min-height: 15px;
    }
}
@media only screen and (max-width: 1200px) {
    .stat__bars {
        left: 0.5px;
    }
}

@media only screen and (max-width: 576px) {
    .filter__btn {
        width: 48% !important;
    }

    .report__box {
        border-radius: 8px;
        height: 120px;
    }

    .stat__text {
        font-size: 10px;
        margin-bottom: 7.5px;
    }

    .ant-modal .ant-modal-content {
        padding: 20px 15px !important;
    }
}