.offers__title {
    margin: 0px;
}

.offers__data {
    background: #e6e6e640;
    border-radius: 8px;
    padding-bottom: 20px;
}

.offers__title {
    padding: 10px 0;
}

.offers__list {
    margin: 0px 0px;
    background: #fff;
    border-radius: 8px;
    padding: 10px 0;
    border: 2px solid #70707040;
}

.offers__list:hover {

    background-color: transparent;
}

.offers__company {
    width: 26px;
    height: 26px;
    background: #8741e6;
    border-radius: 50%;
    margin-right: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-top: 5px;
}

.offers__list img {
    max-width: 30px;
}

.invitation-job-detail .postedDate {
    margin-bottom: 20px;
    margin-right: 30px;
}

.job__role___btn {
    height: 46px !important;
}

.interview-btn {
    margin-top: 28px;
}

.interview__btn {
    max-width: 200px;
    width: 100%;
}

.interview__resch {
    border: 0px solid;
    background-color: transparent;
    color: #4a4a497e;
}

.interview__resch:hover {
    color: #4a4a49;
}

.status-Generated {
    background-color: #D3EEA8 !important;
    color: #4E8000 !important;
}

.status__Pending {
    background-color: #FFB7B8 !important;
    color: #CE0002 !important;
}

.btn-red {
    background-color: #F82C2C;
    border: 1px solid #F82C2C;
    color: white;
    padding: 7px 25px;
}

.btn-red:hover {
    background-color: white;
    border: 1px solid #F82C2C;
    color: #F82C2C;
}

.candidates__interview__icon {
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
}
.prep__field{
    height: 37px !important;
}
.prep__field .ant-select-selector{
    height: 22px !important;
    padding: 0 !important;
    border-color: white !important;
    margin-top: 0px !important;
}
.prep__field .ant-select-selection-search-input{
    margin-left: -11px !important;
    
}
.table__bottom__btn1{
    background: rgba(255, 255, 255, 0);
    color: var(--midGrey);
    transition: all 0.3s ease-in-out;
    text-wrap: nowrap;
}
.interview-btn{
    max-width: none !important;
    width: auto !important;
    padding: 2px 20px !important;
}
.table__bottom__btn1:hover{
    color: var(--darkgrey);
}
.table__bottom__btn2{
    background: rgba(255, 255, 255, 0);
    color: var(--darkGrey);
    transition: all 0.3s ease-in-out;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    gap: 2px;
}
.table__bottom__btn2:hover{
    color: var(--darkgrey);
}

.expiring__body_detail_image__rejected {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    background-color: #F82C2C;
    margin-right: 13px;
}
@media only screen and (max-width: 1400px) {
    .interview__btn {
        max-width: none;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .interview-btn {
        margin-top: 18px;
    }
}

@media only screen and (max-width: 576px) {
    .bluegradient__btn-prep {
        max-width: none;
        width: 100%;
    }
}