.eprofile__container {
    max-width: 1300px;
    width: 100%;
}

.eprofile__switch {
    background-color: #EAEAEA;
    border-radius: 4px;
}

.edit__dp {
    height: 150px;
    border-radius: 2px;
}

.rounded-border {
    border: 1px solid #4A4A4941;
    border-radius: 8px;

}

.border-TB {
    border-top: 1px solid #4A4A4941;
    border-bottom: 1px solid #4A4A4941;
}

.eprofile__banner {
    height: 230px;
    background-size: cover;
    background-repeat: no-repeat;
}

.eprofile__dp {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 2px solid white;
    margin-top: -70px;
    object-fit: cover;
}

.eprofile .ant-tabs>.ant-tabs-nav {
    margin-bottom: 0px !important;
}

.eprofile__top {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.eprofile .ant-tabs-nav-wrap {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border: 1px solid #4A4A4941 !important;
    border-top: 0px solid !important;
    padding: 0px 22px !important;
}

.eprofile .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: 0px !important;
}

.eprofile__button {
    background-color: white;
    padding: 10px;
    border-top: 1px solid #4A4A4941;
}

.eprofile__tech {
    background-color: #E6E6E6;
}

.ant-color-picker-trigger {
    height: 100px !important;
    border: 0.5px solid #70707041 !important;
    border-radius: 2px !important;
    width: 100% !important;
    padding: 0px !important;
}

.ant-color-picker-color-block {
    width: 100% !important;
    height: 100% !important;
}

.ant-color-picker-trigger:focus-within {
    box-shadow: none !important;
}

.ant-color-picker-color-block-inner {
    border-radius: 2px !important;
}

.techbox {
    border-radius: 8px;
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    object-fit: cover;
}

.techbox__light {
    background-color: #ffffff33;
}

.eprofile__img,
.eprofile .ant-upload-list-item-thumbnail img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}
.radius-0{
    border-radius: 0 !important;
}
.ant-upload-list-item-thumbnail{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}
.eprofile .ant-upload-list-item {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    overflow: hidden !important;
}

.eprofile .ant-upload {
    background-color: #E6E6E6 !important;
}

.eprofile .ant-upload.ant-upload-select {
    width: 100% !important;
    height: 150px !important;
    overflow: hidden !important;
}

.eprofile .ant-upload-list-item::before {
    width: 100% !important;
    height: 100% !important;
}

.eprofile .ant-upload-list-item-container {
    width: 100% !important;
}

.eprofile .ant-upload-list-item-error {
    max-width: none !important;
}

.ant-radio-button-wrapper {
    padding-inline: 10px !important;
    font-size: 14px !important;
}

@media only screen and (max-width: 1700px) {
    .eprofile__container {
        max-width: 1200px;
    }
}

@media only screen and (max-width: 1600px) {
    .eprofile__container {
        max-width: 1100px;
    }
}

@media only screen and (max-width: 1500px) {
    .eprofile__container {
        max-width: 1000px;
    }
}

@media only screen and (max-width: 1400px) {
    .eprofile__container {
        max-width: 900px;
    }
}

@media only screen and (max-width: 992px) {
    .eprofile__container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .techbox {
        width: 100px;
        height: 100px;
        min-width: 100px;
        min-height: 100px;
    }
}